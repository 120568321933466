import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { colors, rgbaColors } from '@karnott/colors';
import { KIcon, MenuIcon } from '@karnott/icons';
import { Severity, getColorSeverity, msDuration, pixelSize, pixelSpacing, zIndex } from '@karnott/theme';

const MenuButton = styled.button<{
  opened: boolean;
}>`
  all: unset;
  position: relative;
  cursor: default;
  svg {
    opacity: ${({ opened }) => (opened ? 1 : 0.6)};
    transition: opacity ${msDuration('short')} linear;
  }
  :not([disabled]) {
    cursor: pointer;
    :hover svg {
      opacity: 1;
    }
  }
`;

type MenuPosition = 'left' | 'right';

const ItemsContainer = styled.div<{
  $orientation: MenuPosition;
  $x: number;
  $y: number;
  $width: number;
  openOnTop: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ $y }) => $y}px;
  ${({ openOnTop }) =>
    openOnTop
      ? css`
          transform: translateY(calc(-100% + 24px));
        `
      : null}
  ${({ $orientation, $x, $width }) =>
    $orientation === 'right'
      ? css`
          left: ${$x + 24}px;
        `
      : css`
          left: ${$x - $width}px;
        `}
  border-radius: 7px;
  background-color: white;
  box-shadow: 0px 0px 10px ${rgbaColors('black', 600, 0.1)};
  z-index: ${zIndex('ui')};
  overflow: hidden;

  :empty {
    display: none;
  }
`;

const Item = styled.div<{
  $disabled?: boolean;
  $color: string;
}>`
  display: flex;
  align-items: center;
  font-size: ${pixelSize('small')};
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  color: ${({ $color }) => $color};
  cursor: ${({ $disabled = false }) => ($disabled ? 'default' : 'pointer')};
  gap: 2px;
  svg {
    margin-right: 6px;
  }
  > div {
    flex-shrink: 0;
  }
  span {
    white-space: nowrap;
    padding: 2px 0;
  }
  &:hover {
    background-color: ${({ $disabled = false }) => ($disabled ? 'inherit' : colors('grey', 200))};
  }
`;

type Props = {
  /** Items of the action menu */
  items?: {
    label: string;
    icon?: KIcon;
    action?: () => void;
    severity?: Severity;
  }[];
  /** The position where the menu opens */
  position?: MenuPosition;
  /** Whether or not the menu opens on top */
  openOnTop?: boolean;
  /** Whether or not the menu is disabled */
  disabled?: boolean;
  /** Color of the menu icon */
  color?: string;
  /** Called when the menu opens */
  onOpen?: () => void;
  /** Called when the menu closes */
  onClose?: () => void;
};

/** A menu with actions inside. */
export function ActionMenu({
  items = [],
  position = 'left',
  openOnTop = false,
  disabled = false,
  color = colors('black'),
  onOpen = () => {},
  onClose = () => {},
}: Props) {
  const [opened, setOpened] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const buttonBoundingRect = buttonRef.current?.getBoundingClientRect();
  const [size, setSize] = useState(menuRef.current?.getBoundingClientRect());

  const close = useCallback(() => {
    setOpened(false);
    onClose();
  }, [onClose]);

  const open = useCallback(() => {
    setOpened(true);
    onOpen();
  }, [onOpen]);

  /* Get the size of the menu after the DOM has changed */
  useLayoutEffect(() => {
    const currentSize = menuRef.current?.getBoundingClientRect();

    if (opened && (!size || (size && currentSize && JSON.stringify(size) !== JSON.stringify(currentSize)))) {
      setSize(currentSize);
    }
  }, [opened, size]);

  const onClick = useCallback(
    (e: ReactMouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (items.length === 0) return;

      if (opened) {
        close();
      } else {
        open();
      }
    },
    [close, items.length, open, opened],
  );

  const onDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (
        buttonRef.current &&
        e.target instanceof Element &&
        !buttonRef.current.contains(e.target) &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        if (opened) close();
      }
    },
    [close, opened],
  );

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', onDocumentClick, true);
    }
    return () => {
      document.removeEventListener('click', onDocumentClick, true);
    };
  }, [onDocumentClick, opened]);

  /* Close the menu on any scroll in the page */
  const onScroll = useCallback(
    (e: Event) => {
      if (opened && buttonRef.current && e.target instanceof Element && e.target.contains(buttonRef.current)) {
        close();
      }
    },
    [opened, close],
  );

  useEffect(() => {
    if (opened) {
      window.addEventListener('scroll', onScroll, true);
    }
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [onScroll, opened]);

  const onItemClick = useCallback(
    (action?: () => void) => {
      action?.();
      close();
    },
    [close],
  );

  return (
    <>
      <MenuButton onClick={onClick} opened={opened} disabled={disabled} ref={buttonRef} data-testid="action-menu">
        <MenuIcon color={color} />
      </MenuButton>
      {opened
        ? createPortal(
            <ItemsContainer
              ref={menuRef}
              $orientation={position}
              $x={(buttonBoundingRect?.left || 0) + window.scrollX}
              $y={(buttonBoundingRect?.top || 0) + window.scrollY}
              openOnTop={openOnTop}
              $width={size?.width || 0}
              data-testid="action-menu-portal"
            >
              {items.map((item) => {
                const itemColor = colors(
                  !item.severity || item.severity === 'info' ? 'black' : getColorSeverity(item.severity),
                  500,
                );
                return (
                  <Item
                    key={item.label}
                    onClick={(e) => {
                      e.stopPropagation();
                      onItemClick(item.action);
                    }}
                    $disabled={!item.action}
                    $color={itemColor}
                  >
                    {item.icon ? <item.icon size={18} color={itemColor} /> : null} <span>{item.label}</span>
                  </Item>
                );
              })}
            </ItemsContainer>,
            document.body,
          )
        : null}
    </>
  );
}
