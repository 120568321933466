import { MouseEvent, startTransition, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Header, SortDirection, type Row as TableRow, flexRender } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import { ColorName, colors, rgbaColors } from '@karnott/colors';
import { ChevronIcon, KIcon } from '@karnott/icons';
import { fontFamily, msDuration, pixelSize } from '@karnott/theme';
import { useTable, useTotalRow, useVirtualRows } from './hooks';
import { Column, RowId, RowShape } from './types';

const TableContainer = styled.div`
  height: 100%;
  overflow: auto;
  border-radius: 7px;
  background-color: ${colors('white')};
`;

const TableElement = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-family: ${fontFamily('default')};
`;

const Thead = styled.thead<{
  background?: string;
}>`
  background: ${({ background }) =>
    background
      ? background in colors._palette
        ? colors(background as ColorName, 500)
        : background
      : colors('grey', 200)};
  font-family: ${fontFamily('overstatement')};
  color: ${({ background }) => colors(background ? 'white' : 'grey')};
  text-transform: uppercase;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HeaderCell = styled.td<{
  canSort: boolean;
  background?: string;
}>`
  font-weight: normal;
  font-size: ${pixelSize('small')};
  padding: 0 10px;
  min-width: 50px;

  ${({ canSort, background }) =>
    canSort
      ? css`
          :hover {
            color: ${background ? 'inherit' : colors('grey', 600)};
            cursor: pointer;
          }
        `
      : null}

  :has(.cell_no_data) {
    min-width: 0;
  }
`;

const HeaderWithSort = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  :has(.cell_no_data) {
    min-width: 0;
    justify-content: center;
  }

  .cell_no_data label > div:last-of-type {
    display: none;
  }
`;

const HeaderTitle = styled.span<{
  multiline: boolean;
}>`
  user-select: none;
  padding: 14px 0;
  ${({ multiline }) =>
    multiline
      ? null
      : css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}

  :empty {
    display: none;
  }
`;

const SortButtons = styled.div`
  flex-shrink: 0;

  > div:first-of-type {
    rotate: 0.5turn;
    translate: 0 4px;
  }

  > div:last-of-type {
    translate: 0 -4px;
  }
`;

const Tbody = styled.tbody`
  background-color: ${colors('white')};
  color: ${colors('black')};
  font-size: ${pixelSize('regular')};

  tr:last-of-type td {
    border-bottom: none;
  }
`;

const TableRow = styled.tr<{
  highlightOnHover: boolean;
}>`
  transition: background-color ${msDuration('short')} linear;

  .cell_on_hover {
    opacity: 0;
    transition: opacity ${msDuration('short')} linear;
  }

  :hover .cell_on_hover {
    opacity: 1;
  }

  ${({ highlightOnHover }) =>
    highlightOnHover
      ? css`
          :hover {
            background-color: ${colors('green', 200)};
            td {
              border-bottom-color: ${colors('green', 200)};
            }
          }

          :has(+ :hover) td {
            border-bottom-color: ${colors('green', 200)};
          }
        `
      : null}
`;

const BodyCell = styled.td`
  padding: 14px 10px;
  border-bottom: 1px solid ${colors('grey', 100)};
  transition: border-bottom-color ${msDuration('short')} linear;

  .cell_no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    label > div:last-of-type {
      display: none;
    }
  }
`;

const Tfoot = styled.tfoot`
  background: ${colors('black')};
  color: ${colors('white')};
  margin: 0;
  position: sticky;
  bottom: 0;
  font-size: ${pixelSize('regular')};

  th {
    font-weight: normal;
    text-align: left;
  }
`;

const FooterCell = styled.td`
  margin: 0;
  padding: 14px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  * {
    color: ${colors('white')} !important;
    font-family: ${fontFamily()} !important;
    font-weight: normal !important;
  }
`;

type ColumnHeaderProps<Row extends RowShape> = {
  header: Header<Row, unknown>;
  Icon?: KIcon;
  background?: string;
  multiline: boolean;
};

function ColumnHeader<Row extends RowShape>({ header, Icon, background, multiline }: ColumnHeaderProps<Row>) {
  const canSort = header.column.getCanSort();
  const isSorted = header.column.getIsSorted();
  const headerTitle = useRef<HTMLSpanElement>(null);
  const onClick = useCallback(
    (e: MouseEvent<HTMLTableCellElement>) => {
      startTransition(() => header.column.getToggleSortingHandler()?.(e));
    },
    [header.column],
  );

  const iconColorFor = (order: SortDirection) =>
    rgbaColors(background ? 'white' : 'grey', 500, isSorted === order ? 1 : 0.5) || undefined;

  return (
    <HeaderCell
      title={(headerTitle.current?.innerText ? headerTitle.current?.textContent : null) || undefined}
      canSort={canSort}
      onClick={onClick}
      background={background}
    >
      <HeaderWithSort>
        {header.index === 0 && Icon ? <Icon size={16} color="currentColor" /> : null}
        <HeaderTitle ref={headerTitle} multiline={multiline}>
          {flexRender(header.column.columnDef.header, header.getContext())}
        </HeaderTitle>
        {canSort ? (
          <SortButtons>
            <ChevronIcon size={18} color={iconColorFor('asc')} />
            <ChevronIcon size={18} color={iconColorFor('desc')} />
          </SortButtons>
        ) : null}
      </HeaderWithSort>
    </HeaderCell>
  );
}

type VirtualPaddingProps = {
  size: number;
};

function VirtualPadding({ size }: VirtualPaddingProps) {
  return size > 0 ? (
    <tr>
      <td style={{ height: size }} />
    </tr>
  ) : null;
}

type RowProps<Row extends RowShape> = {
  tableRows: TableRow<Row>[];
  virtualRow: VirtualItem;
  measureElement: (node: Element | null) => void;
  highlightOnHover: boolean;
};

function Row<Row extends RowShape>({ tableRows, virtualRow, measureElement, highlightOnHover }: RowProps<Row>) {
  const row = tableRows.at(virtualRow.index);
  return (
    <TableRow data-index={virtualRow.index} highlightOnHover={highlightOnHover} ref={measureElement}>
      {row?.getVisibleCells().map((cell) => (
        <BodyCell key={cell.id} className={cell.column.columnDef.meta?.displayOnHover ? 'cell_on_hover' : ''}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </BodyCell>
      ))}
    </TableRow>
  );
}

type TableProps<Row extends RowShape> = {
  /**
   * The definition of the columns of the table. All columns can have the 3 boolean attributes `total`, `sort` and
   * `displayOnHover`. The columns can be one of three types:
   *
   * - Selection: `{ type: "selection", header?: boolean }` a column to select lines
   * - No data: `{ displayFn: (row: Row) => ReactNode, header?: ReactNode }` a column to display arbitrary data, like
   *   buttons or menus
   * - Values: a column to display values, formatted or not
   *
   *       {
   *         key: "a property of Row",
   *         header?: ReactNode,
   *         displayFn?: ({row, [key]}) => ReactNode,
   *         sortOnKey: "a property of Row[key]",
   *         sortingFnName: string,
   *         sortingFn: (a, b) => number
   *       }
   */
  columns: Column<Row>[];
  /** The data in the table. Rows must have a unique `id` */
  rows: Row[];
  /**
   * Whether or not all columns with sortable data must have a sort button in the header. This behavior is togglable on
   * a per-column basis.
   */
  sortAll?: boolean;
  /** Whether or not to add a line with the total of the numeric columns. */
  displayTotal?: boolean;
  /** A callback when the row selection is updated. */
  onSelectionChange?: (selectedRows: RowId[]) => void;
  /** The table icon, displayed before the first header. */
  Icon?: KIcon;
  /** The background color of the header. */
  headerColor?: ColorName | (string & NonNullable<unknown>);
  /** Whether or not the lines are highlighted on hover. */
  highlightOnHover?: boolean;
  /** Whether or not the header names can collapse in multiple lines. */
  multilineHeaders?: boolean;
};

export function Table<Row extends RowShape>({
  columns = [],
  rows = [],
  sortAll = false,
  displayTotal = false,
  onSelectionChange = () => {},
  Icon,
  headerColor,
  highlightOnHover = true,
  multilineHeaders = false,
}: TableProps<Row>) {
  /* Table initialization */
  const { table, columnDefs } = useTable({ columns, rows, onSelectionChange, sortAll });
  const { rows: tableRows } = table.getRowModel();

  /* Virtualization */
  const { setScrollElement, virtualRows, paddingBottom, paddingTop, measureElement } = useVirtualRows({
    rows: tableRows,
  });

  /* Total footer cells */
  const totalCells = useTotalRow({ rows: tableRows, columnDefs, displayTotal });

  return (
    <TableContainer ref={setScrollElement}>
      <TableElement>
        <Thead background={headerColor}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <ColumnHeader
                  key={header.id}
                  header={header}
                  Icon={Icon}
                  background={headerColor}
                  multiline={multilineHeaders}
                />
              ))}
            </tr>
          ))}
        </Thead>

        <Tbody>
          <VirtualPadding size={paddingTop} />

          {virtualRows.map((virtualRow) => (
            <Row
              key={virtualRow.key}
              tableRows={tableRows}
              virtualRow={virtualRow}
              measureElement={measureElement}
              highlightOnHover={highlightOnHover}
            />
          ))}

          <VirtualPadding size={paddingBottom} />
        </Tbody>

        {displayTotal ? (
          <Tfoot>
            <tr>
              <FooterCell as="th">Total</FooterCell>
              {totalCells.map((cell, i) => {
                const columnDef = table.getAllColumns().at(i + 1)!.columnDef;
                const renderFn = cell
                  ? columnDefs.find((col) =>
                      'accessorKey' in col && 'accessorKey' in columnDef
                        ? col.accessorKey === columnDef.accessorKey
                        : false,
                    )?.cell || null
                  : null;
                return (
                  <FooterCell
                    key={
                      columnDef.id ||
                      ('accessorKey' in columnDef && typeof columnDef.accessorKey === 'string'
                        ? columnDef.accessorKey
                        : undefined)
                    }
                  >
                    {typeof renderFn === 'string' || cell === null
                      ? null
                      : renderFn?.({
                          // @ts-expect-error This is a way to render a custom value using the same formatting as the column
                          getValue: () => cell,
                        })}
                  </FooterCell>
                );
              })}
            </tr>
          </Tfoot>
        ) : null}
      </TableElement>
    </TableContainer>
  );
}

export type { Column };
