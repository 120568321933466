import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, ButtonGroup } from '@karnott/buttons';
import { AreaMeasureIcon, FitDataIcon, MapIcon, MeasureIcon, ZoomInIcon, ZoomOutIcon } from '@karnott/icons';
import { pixelSpacing, spacing } from '@karnott/theme';
import { toggleUseIgnTiles } from '../../../actions/ui';
import { USER_CONSTANTS } from '../../../constants';
import { I18nContext } from '../../../contexts/I18nProvider';
import { PluginsContext } from '../../../contexts/PluginsProvider';
import { zIndex } from '../../../ui/theme';
import { ANALYTICS, sendAnalyticsBtnClickEvent } from '../../../utils/AnalyticsConstants';
import { L as Leaflet } from '../../../utils/LeafletOverrides';
import { MapContext } from '../contexts/map/';
import { MapOverlayContext } from '../contexts/overlay';
import BottomSection from '../drawParcel/BottomSection';
import FullScreenControl from './FullScreenControl';
import GeolocationControl from './GeolocationControl';
import ToggleMarkerClusterControl from './ToggleMarkerClusterControl';
import { useAreaMeasure, useMeasure } from './effects';

function Controls({
  hideFit,
  hideRuler,
  moreControls,
  hideToggleMarkerCluster,
  hideGeolocation,
  hideFullScreen,
  hideTiles,
  onDashboard,
  bottom = spacing() * 3,
  right = spacing(),
}) {
  const { line, map, poly, toggleTiles } = useContext(MapContext);
  const { t } = useContext(I18nContext);
  const { hasOverallIgnTilesPlugin } = useContext(PluginsContext);
  const { fitMap } = useContext(MapOverlayContext);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const scaleControl = useRef(null);
  const unitDistance = useMemo(() => user?.unit_distance, [user]);

  const zoomIn = useCallback(() => {
    map.zoomIn();
  }, [map]);

  const zoomOut = useCallback(() => {
    map.zoomOut();
  }, [map]);

  useEffect(() => {
    if (unitDistance) {
      switch (unitDistance) {
        case USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.IMPERIAL:
          scaleControl.current = Leaflet.control.scale({ metric: false }).addTo(map);
          break;
        case USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI:
        default:
          scaleControl.current = Leaflet.control.scale({ imperial: false }).addTo(map);
          break;
      }
    }
    return () => {
      if (scaleControl.current) {
        scaleControl.current.remove();
      }
    };
  }, [unitDistance, map]);

  const [toggleMeasure, lineEnabled] = useMeasure(map, line, hideRuler);
  const [toggleAreaMeasure, areaEnabled, , drawnArea] = useAreaMeasure(map, poly, hideRuler);
  const toggleMeasureCB = useCallback(() => {
    if (areaEnabled) toggleAreaMeasure();
    sendAnalyticsBtnClickEvent(window)(ANALYTICS.LABEL.MAP.MEASURE_DISTANCE);
    toggleMeasure();
  }, [areaEnabled, toggleAreaMeasure, toggleMeasure]);
  const toggleAreaMeasureCB = useCallback(() => {
    if (lineEnabled) toggleMeasure();
    sendAnalyticsBtnClickEvent(window)(ANALYTICS.LABEL.MAP.MEASURE_AREA);
    toggleAreaMeasure();
  }, [lineEnabled, toggleMeasure, toggleAreaMeasure]);

  const toggleTilesCB = useCallback(() => {
    dispatch(toggleUseIgnTiles());
    toggleTiles();
  }, [dispatch, toggleTiles]);

  const fitMapToContentCallback = useCallback(() => {
    fitMap();
  }, [fitMap]);

  const buttons = []
    .concat(!hideFullScreen && <FullScreenControl key="fullscreen" onDashboard={onDashboard} />)
    .concat(!hideToggleMarkerCluster && ToggleMarkerClusterControl())
    .concat(
      !hideFit && (
        <Button
          Icon={FitDataIcon}
          primary
          onClick={fitMapToContentCallback}
          message={t('Controls.fit_content')}
          outlinedNeutral
          tooltipOrientation="left"
          noWrap
        />
      ),
    )
    .concat(!hideGeolocation && GeolocationControl())
    .concat([
      <Button
        key="1"
        outlinedNeutral
        primary
        Icon={ZoomInIcon}
        onClick={zoomIn}
        message={t('Controls.zoom_in')}
        tooltipOrientation="left"
        noWrap
      />,
      <Button
        key="2"
        outlinedNeutral
        primary
        Icon={ZoomOutIcon}
        onClick={zoomOut}
        message={t('Controls.zoom_out')}
        tooltipOrientation="left"
        noWrap
      />,
    ]);

  const measureButtons = [
    <Button
      key="3"
      Icon={MeasureIcon}
      onClick={toggleMeasureCB}
      primary
      message={t('Controls.measure_distance')}
      outlinedNeutral
      tooltipOrientation="left"
      noWrap
      outlined={lineEnabled}
      badge={lineEnabled}
    />,
    <Button
      key="4"
      Icon={AreaMeasureIcon}
      onClick={toggleAreaMeasureCB}
      primary
      message={t('Controls.measure_area')}
      outlinedNeutral
      tooltipOrientation="left"
      noWrap
      outlined={areaEnabled}
      badge={areaEnabled}
    />,
  ];

  return (
    <>
      <Container bottom={bottom} right={right} data-testid="map-controls">
        {hasOverallIgnTilesPlugin && !hideTiles && (
          <Button
            Icon={MapIcon}
            onClick={toggleTilesCB}
            primary
            message={t('Controls.change_tiles')}
            outlinedNeutral
            tooltipOrientation="left"
            noWrap
          />
        )}
        {moreControls}
        {!hideRuler && <ButtonGroup buttons={measureButtons} />}
        <ButtonGroup buttons={buttons} />
      </Container>
      {drawnArea ? (
        <BottomSection key={'bottom-section'} {...{ clearAction: toggleAreaMeasure, drawnParcel: drawnArea }} />
      ) : null}
    </>
  );
}

const Container = styled.div`
  position: absolute;
  display: flex;
  z-index: ${zIndex('ui', 1)};
  flex-direction: column;
  bottom: ${({ bottom }) => bottom}px;
  right: ${({ right }) => right}px;
  > * {
    margin-bottom: ${pixelSpacing('small')};
  }
`;

export default Controls;
