import { createSelector } from '@reduxjs/toolkit';
import { colors } from '@karnott/colors';
import { createAppSlice } from '.';
import {
  createCrop as createApiCrop,
  deleteCrop as deleteApiCrop,
  getCrops,
  updateCrop as updateApiCrop,
} from '../api/api';
import { I18n } from '../i18n';
import { getUser } from '../selectors';
import { getColorWithHash } from '../utils/colors';
import { buildCropLabel } from '../utils/crops';
import { ItemWithPermissions } from '../utils/permissions';
import { PartialWithRequired } from '../utils/types';
import { ClusterId } from './clusters';
import { ColorItem } from './colors';
import { alerting } from './snacks';

export type CropId = string;

type BasicCrop = ItemWithPermissions & {
  id: CropId;
  name: string;
  crop_id: number;
  color: string;
  code: string;
  locale: string;
  provenance: string;
  parcel_count?: number;
  parcel_area_sum?: number;
  cluster_id: undefined;
};

type CustomCrop = ItemWithPermissions & {
  id: CropId;
  name: string;
  crop_id: number;
  base_crop?: string;
  color: string;
  code: '';
  locale: undefined;
  provenance: undefined;
  parcel_count?: number;
  parcel_area_sum?: number;
  cluster_id: ClusterId;
};

export type Crop = BasicCrop | CustomCrop;

type State = {
  cropByID: Record<CropId, Crop>;
  cropIDs: CropId[];
};

const initialState: State = {
  cropByID: {},
  cropIDs: [],
};

const cropsSlice = createAppSlice({
  name: 'crops',
  initialState,
  reducers: (create) => ({
    fetchCrops: create.asyncThunk(
      async (_, { dispatch }) => {
        const crops = (await alerting(getCrops, { dispatch })) as Crop[];
        return crops;
      },
      {
        fulfilled: (state, { payload }) => {
          for (const crop of payload) {
            state.cropByID[crop.id] = crop;
            state.cropIDs.push(crop.id);
          }
        },
      },
    ),
    createCrop: create.asyncThunk(
      async (payload: CustomCrop, { dispatch }) => {
        const newCrop = (await alerting(() => createApiCrop(payload), { dispatch })) as Crop;
        return newCrop;
      },
      {
        fulfilled: (state, { payload }) => {
          state.cropByID[payload.id] = payload;
          state.cropIDs.push(payload.id);
        },
      },
    ),
    updateCrop: create.asyncThunk(
      async (payload: PartialWithRequired<Crop, 'id'>, { dispatch }) => {
        const updatedCrop = (await alerting(() => updateApiCrop(payload.id, payload), { dispatch })) as Crop;
        return updatedCrop;
      },
      {
        fulfilled: (state, { payload }) => {
          state.cropByID[payload.id] = payload;
        },
      },
    ),
    deleteCrop: create.asyncThunk(
      async (payload: CropId, { dispatch }) => {
        await alerting(() => deleteApiCrop(payload), { dispatch });
        return payload;
      },
      {
        fulfilled: (state, { payload }) => {
          state.cropIDs = state.cropIDs.filter((id) => id !== payload);
          delete state.cropByID[payload];
        },
      },
    ),
  }),
  selectors: {
    selectCropsById: (state) => state.cropByID,
    selectCropIds: (state) => state.cropIDs,
  },
});

export const selectCropsAsArray = createSelector(
  [cropsSlice.selectors.selectCropsById, cropsSlice.selectors.selectCropIds],
  (cropByID, cropIDs) => {
    return (cropIDs || []).map((id) => cropByID[id]).filter((crop) => !!crop);
  },
);

export const selectBasicCrops = createSelector([selectCropsAsArray], (crops) =>
  crops.filter((crop) => crop.cluster_id == undefined),
);

export const selectCustomCrops = createSelector([selectCropsAsArray, selectBasicCrops], (crops, basicCrops) => {
  const customCrops = crops.filter((crop) => crop.cluster_id != undefined);
  return customCrops.map((c): CustomCrop => {
    const baseCrop = basicCrops.find((bc) => c.crop_id === bc.crop_id);
    return { ...c, base_crop: baseCrop?.name };
  });
});

export const selectCropColorItems = createSelector([selectCropsAsArray, getUser], (crops, user) => {
  return crops
    .filter((crop) => !!crop.color && crop.parcel_count)
    .map(
      (crop): ColorItem => ({
        id: String(crop.id),
        name: buildCropLabel(crop, user.unit_area),
        color: getColorWithHash(crop.color),
      }),
    )
    .concat([
      {
        id: '0',
        name: I18n.t('Crop.undefined'),
        color: getColorWithHash(colors('black')),
      },
    ]);
});

export const { fetchCrops, createCrop, updateCrop, deleteCrop } = cropsSlice.actions;
export const { selectCropsById, selectCropIds } = cropsSlice.selectors;

export default cropsSlice.reducer;
