import cloneDeep from 'lodash-es/cloneDeep';
import findIndex from 'lodash-es/findIndex';
import maxBy from 'lodash-es/maxBy';
import { Actions } from '../actions';
import { USER_CONSTANTS } from '../constants';
import { CLUSTER_CONSTANTS } from '../constants/clusterConstants';
import { getEquipmentTypesList } from '../constants/equipmentConstants';
import {
  BEACON_TOGGLE_TYPE,
  KARNOTT_TOGGLE_TYPE,
  UNDEFINED_CROP_ID,
  filterToggleTypes,
  initialEquipmentSessionsFilters,
} from '../constants/filtersConstants';
import { I18n } from '../i18n';
import { fetchClusters } from '../slices/clusters';
import { createCrop, deleteCrop, fetchCrops, updateCrop } from '../slices/crops';
import { buildCropLabel } from '../utils/crops';

const initialState = {
  equipments: {
    toggles: {
      items: [
        { type: filterToggleTypes.IN_USE_EQUIPMENTS, checked: true },
        { type: filterToggleTypes.AVAILABLE_EQUIPMENTS, checked: true },
        { type: filterToggleTypes.ARCHIVED, checked: false },
        { type: filterToggleTypes.OBSERVATIONS, checked: true },
      ],
    },
    select: { selected: [] },
    types: {
      items: getEquipmentTypesList().map((type) => ({ type, checked: false })),
    },
    sliders: {
      items: [{ min: 0, max: 150, minValue: 0, maxValue: 150 }],
    },
  },
  parcels: {
    checkboxes: {
      items: [{ checked: true, label: I18n.t('Crop.undefined'), id: UNDEFINED_CROP_ID }],
    },
    checkboxesClusters: { items: [] },
    sliders: {
      items: [{ min: 0, max: 227, minValue: 0, maxValue: 227 }],
    },
  },
  beacons: {
    toggles: {
      items: [
        { type: BEACON_TOGGLE_TYPE.AFFECTED, checked: true },
        { type: BEACON_TOGGLE_TYPE.AVAILABLE, checked: true },
      ],
    },
    select: { selected: [] },
  },
  driverBeacons: {
    toggles: {
      items: [
        { type: BEACON_TOGGLE_TYPE.AFFECTED, checked: true },
        { type: BEACON_TOGGLE_TYPE.AVAILABLE, checked: true },
      ],
    },
    select: { selected: [] },
  },
  karnott: {
    toggles: {
      items: [
        { type: KARNOTT_TOGGLE_TYPE.NOT_IN_USE, checked: true },
        { type: KARNOTT_TOGGLE_TYPE.IN_USE, checked: true },
        { type: KARNOTT_TOGGLE_TYPE.AFFECTED, checked: true },
        { type: KARNOTT_TOGGLE_TYPE.AVAILABLE, checked: true },
        { type: KARNOTT_TOGGLE_TYPE.LOW_BATTERY, checked: true },
        { type: KARNOTT_TOGGLE_TYPE.BATTERY, checked: true },
      ],
    },
    select: { selected: [] },
  },
  drivers: {
    toggles: {
      items: [
        { type: filterToggleTypes.ACTIVE_DRIVER, checked: true },
        { type: filterToggleTypes.AVAILABLE_DRIVER, checked: true },
        { type: filterToggleTypes.ADMIN_DRIVER, checked: false },
      ],
    },
    select: { selected: [] },
  },
  equipmentSessions: cloneDeep(initialEquipmentSessionsFilters),
  extended: {},
  defaultTagFilters: {},
};

const filters = (state = initialState, action) => {
  const { type, payload } = action;
  let cropItems,
    clusterItems = [];
  if (state.parcels?.checkboxes?.items) {
    cropItems = [...(state.parcels?.checkboxes?.items || [])];
  }
  if (state.parcels?.checkboxesClusters?.items) {
    clusterItems = [...(state.parcels?.checkboxesClusters?.items || [])];
  }

  switch (type) {
    case Actions.USER_INFO_RETRIEVED:
      return {
        ...state,
        unit_area: payload.unit_area,
        unit_distance: payload.unit_distance,
        unit_time: payload.unit_time,
      };
    case Actions.PARCELS_SUCCESS:
      if (payload.entities.parcel) {
        const parcelsArray = Object.values(payload.entities.parcel);
        const maxAreaParcel = maxBy(parcelsArray, (p) => p.area);
        const maxArea = maxAreaParcel.area / 10000;
        parcelsArray.forEach((parcel) => {
          if (parcel.current_crop_id) {
            const indexOfExistingCropItem = findIndex(
              cropItems,
              (item) => item.id && item.id === parcel.current_crop_id,
            );
            if (indexOfExistingCropItem === -1) {
              cropItems.push({
                id: parcel.current_crop_id,
                checked: true,
              });
            } else {
              cropItems[indexOfExistingCropItem] = { ...cropItems[indexOfExistingCropItem], hidden: false };
            }
          }
        });
        return {
          ...state,
          parcels: {
            ...state.parcels,
            sliders: {
              ...state.parcels.sliders,
              items: [
                {
                  ...state.parcels.sliders.items[0],
                  max: Math.ceil(maxArea),
                  maxValue: Math.ceil(maxArea),
                },
              ],
            },
            checkboxes: {
              ...state.parcels.checkboxes,
              items: cropItems,
            },
          },
        };
      } else {
        return state;
      }
    case fetchCrops.fulfilled.type:
    case createCrop.fulfilled.type:
      (Array.isArray(payload) ? payload : [payload]).forEach((crop) => {
        const existingCropItemIndex = findIndex(cropItems, (item) => item.id && item.id === crop.id);
        if (existingCropItemIndex !== -1) {
          cropItems[existingCropItemIndex] = {
            ...cropItems[existingCropItemIndex],
            label: buildCropLabel(crop, state.unit_area || USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI),
          };
        } else {
          cropItems.push({
            checked: true,
            label: buildCropLabel(crop, state.unit_area || USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI),
            id: crop.id,
            hidden: type !== createCrop.fulfilled.type,
          });
        }
      });
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxes: {
            ...state.parcels.checkboxes,
            items: cropItems,
          },
        },
      };
    case updateCrop.fulfilled.type: {
      const updatedCropItemIndex = findIndex(cropItems, (item) => item.id && item.id === payload.id);
      if (updatedCropItemIndex !== -1) {
        cropItems[updatedCropItemIndex] = {
          ...cropItems[updatedCropItemIndex],
          label: buildCropLabel(payload, state.unit_area || USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI),
        };
      }
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxes: {
            ...state.parcels.checkboxes,
            items: cropItems,
          },
        },
      };
    }
    case deleteCrop.fulfilled.type: {
      const deletedCropItemIndex = findIndex(cropItems, (item) => item.id && item.id === payload);
      if (deletedCropItemIndex !== -1) {
        cropItems.splice(deletedCropItemIndex, 1);
      }
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxes: {
            ...state.parcels.checkboxes,
            items: cropItems,
          },
        },
      };
    }
    case Actions.SELECT_ALL_CROPS: {
      const selectedCrops = cropItems.map((i) => ({ ...i, checked: true }));
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxes: {
            ...state.parcels.checkboxes,
            items: selectedCrops,
          },
        },
      };
    }
    case Actions.DESELECT_ALL_CROPS: {
      const deselectedCrops = cropItems.map((i) => ({ ...i, checked: false }));
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxes: {
            ...state.parcels.checkboxes,
            items: deselectedCrops,
          },
        },
      };
    }
    case Actions.SELECT_ALL_CLUSTERS: {
      const selectedCluster = clusterItems.map((i) => ({ ...i, checked: true }));
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxesClusters: {
            ...state.parcels.checkboxesClusters,
            items: selectedCluster,
          },
        },
        equipmentSessions: {
          ...state.equipmentSessions,
          clusters: {
            ...state.equipmentSessions.clusters,
            items: selectedCluster,
          },
        },
      };
    }
    case Actions.DESELECT_ALL_CLUSTERS: {
      const deselectedClusters = clusterItems.map((i) => ({ ...i, checked: false }));
      return {
        ...state,
        parcels: {
          ...state.parcels,
          checkboxesClusters: {
            ...state.parcels.checkboxesClusters,
            items: deselectedClusters,
          },
        },
      };
    }
    case Actions.EQUIPMENTS_SUCCESS:
      if (payload.entities.equipmentInstance) {
        const equipmentsArray = Object.keys(payload.entities.equipmentInstance).map(
          (key) => payload.entities.equipmentInstance[key],
        );
        const maxWidthEquipment = maxBy(equipmentsArray, (e) => e.width);
        const maxWidth = maxWidthEquipment.width;
        return {
          ...state,
          equipments: {
            ...state.equipments,
            sliders: {
              ...state.equipments.sliders,
              items: [
                {
                  ...state.equipments.sliders.items[0],
                  max: Math.ceil(maxWidth),
                  maxValue: Math.ceil(maxWidth),
                },
              ],
            },
          },
        };
      } else {
        return { ...state };
      }
    case fetchClusters.fulfilled.type: {
      const isViti = payload.some(
        (c) => c?.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.viticulture,
      );
      payload.forEach((c) => {
        const existingClusterItemIndex = findIndex(clusterItems, (item) => item.id && item.id === c.id);
        if (existingClusterItemIndex !== -1) {
          clusterItems[existingClusterItemIndex] = { ...clusterItems[existingClusterItemIndex], label: c.name };
        } else {
          clusterItems.push({
            checked: true,
            label: c.name,
            id: c.id,
          });
        }
      });
      if (isViti) {
        return {
          ...state,
          parcels: {
            ...state.parcels,
            checkboxes: {
              ...state.parcels.checkboxes,
              title: 'Filters.grapes_title',
            },
            checkboxesClusters: {
              ...state.parcels.checkboxesClusters,
              items: clusterItems,
            },
          },
        };
      } else {
        return {
          ...state,
          parcels: {
            ...state.parcels,
            checkboxesClusters: {
              ...state.parcels.checkboxesClusters,
              items: clusterItems,
            },
          },
        };
      }
    }
    case Actions.MODIFY_EQUIPMENT_FILTERS:
      return {
        ...state,
        equipments: payload,
      };
    case Actions.MODIFY_PARCELS_FILTERS:
      return {
        ...state,
        parcels: payload,
      };
    case Actions.MODIFY_KARNOTT_FILTERS:
      return {
        ...state,
        karnott: payload,
      };
    case Actions.MODIFY_BEACON_FILTERS:
      return {
        ...state,
        beacons: payload,
      };
    case Actions.MODIFY_DRIVER_BEACON_FILTERS:
      return {
        ...state,
        driverBeacons: payload,
      };
    case Actions.MODIFY_DRIVER_FILTERS:
      return {
        ...state,
        drivers: payload,
      };
    case Actions.CLOSE_FILTERS:
      return {
        ...state,
        extended: {
          parcels: false,
          equipments: false,
          karnott: false,
          drivers: false,
        },
      };
    case Actions.TOGGLE_FILTERS: {
      const entity = payload.entity;
      return {
        ...state,
        extended: {
          ...state.extended,
          [entity]: !state.extended[entity],
        },
      };
    }
    case Actions.REINIT_EQUIPMENTS_FILTERS:
      return {
        ...state,
        equipments: {
          ...initialState.equipments,
          select: {
            ...initialState.equipments.select,
            selected: state.defaultTagFilters.equipment || [],
          },
        },
        extended: {
          ...state.extended,
          equipments: false,
        },
      };
    case Actions.REINIT_PARCELS_FILTERS: {
      const initSelectedCrops = cropItems.map((i) => ({ ...i, checked: true }));
      return {
        ...state,
        parcels: {
          ...initialState.parcels,
          checkboxes: {
            ...state.parcels.checkboxes,
            items: initSelectedCrops,
          },
        },
        extended: {
          ...state.extended,
          parcels: false,
        },
      };
    }
    case Actions.REINIT_KARNOTT_FILTERS:
      return {
        ...state,
        karnott: {
          ...initialState.karnott,
          select: {
            ...initialState.karnott.select,
            selected: state.defaultTagFilters.device || [],
          },
        },
        extended: {
          ...state.extended,
          karnott: false,
        },
      };
    case Actions.REINIT_BEACON_FILTERS:
      return {
        ...state,
        beacons: {
          ...initialState.beacons,
          select: {
            ...initialState.beacons.select,
            selected: state.defaultTagFilters.beacon || [],
          },
        },
        extended: {
          ...state.extended,
          beacons: false,
        },
      };
    case Actions.REINIT_DRIVER_BEACON_FILTERS:
      return {
        ...state,
        driverBeacons: {
          ...initialState.driverBeacons,
          select: {
            ...initialState.driverBeacons.select,
            selected: state.defaultTagFilters.beacon || [],
          },
        },
        extended: {
          ...state.extended,
          driverBeacons: false,
        },
      };
    case Actions.REINIT_DRIVERS_FILTERS:
      return {
        ...state,
        drivers: {
          ...initialState.drivers,
          select: {
            ...initialState.drivers.select,
            selected: state.defaultTagFilters.user || [],
          },
        },
      };
    case Actions.SET_EQUIPMENT_SESSIONS_FILTERS:
      return {
        ...state,
        equipmentSessions: {
          ...state.equipmentSessions,
          ...payload.equipmentSessionFilters,
        },
      };
    case Actions.SET_DEFAULT_TAG_FILTERS: {
      const mapToIds = (array) => array.map((id) => ({ id }));
      return {
        ...state,
        equipments: {
          ...state.equipments,
          select: { ...state.equipments.select, selected: mapToIds(payload.equipment || []) },
        },
        karnott: {
          ...state.karnott,
          select: { ...state.karnott.select, selected: mapToIds(payload.device || []) },
        },
        beacons: {
          ...state.beacons,
          select: { ...state.beacons.select, selected: mapToIds(payload.beacon || []) },
        },
        driverBeacons: {
          ...state.driverBeacons,
          select: { ...state.driverBeacons.select, selected: mapToIds(payload.beacon || []) },
        },
        drivers: {
          ...state.drivers,
          select: { ...state.drivers.select, selected: mapToIds(payload.user || []) },
        },
        defaultTagFilters: payload,
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default filters;
