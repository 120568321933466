import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../actions/user';
import { OAUTH_URL } from '../constants';

export default function LogoutContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
    window.location.replace(OAUTH_URL);
  }, [dispatch]);

  return null;
}
