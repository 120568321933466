import Leaflet from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import hotline from 'leaflet-hotline';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { initSmoothZoom } from '@karnott/map';

const L = hotline(Leaflet);
L.PM.initialize({ optIn: true });
L.Draw.Polyline.prototype._onTouch = L.Util.falseFn;

initSmoothZoom(L);

export { L };

export function setSmoothZoom(enable, map) {
  if (enable) {
    map.options.zoomSnap = 0;
    map.options.zoomDelta = 0.5;
    map.scrollWheelZoom.disable();
  } else {
    map.options.zoomSnap = 1;
    map.options.zoomDelta = 1;
    map.scrollWheelZoom.enable();
  }
}

const stopPropagation = (e) => e.stopPropagation();

export function setScrollZoom(enable, map) {
  const container = map.getContainer();
  if (enable) {
    container.removeEventListener('wheel', stopPropagation, true);
  } else {
    container.addEventListener('wheel', stopPropagation, true);
  }
}
