import { ComponentProps } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { InfoIcon } from '@karnott/icons';
import { Tooltip } from './Tooltip';

type InfoTooltipProps = Pick<ComponentProps<typeof Tooltip>, 'message' | 'noWrap' | 'orientation'> & {
  color?: string;
  infoColor?: string;
};

/** A tooltip with an information icon */
export function InfoTooltip({
  message,
  noWrap,
  orientation,
  color = colors('grey'),
  infoColor = colors('white'),
}: InfoTooltipProps) {
  return message ? (
    <TooltipInfo infoColor={infoColor}>
      {message ? (
        <Tooltip
          content={<InfoIcon size={13} color={color} />}
          message={message}
          orientation={orientation}
          small
          noWrap={noWrap}
        />
      ) : (
        <InfoIcon size={13} color={color} />
      )}
    </TooltipInfo>
  ) : null;
}

const TooltipInfo = styled.div<{
  infoColor: string;
}>`
  margin-top: 1px;
  > div {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    path {
      fill: ${({ infoColor }) => infoColor};
    }
  }
  :hover ellipse {
    fill: ${colors('black')};
  }
  :hover div path {
    fill: ${colors('white')};
  }
`;
