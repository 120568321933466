import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { COLOR_TRACKS_BY } from '../constants/colorConstants';
import { getEquipmentsStoredById } from '../selectors/equipments';
import { colorChoiceSelector } from '../slices/colors';
import { trackHasClusterColor, trackHasEquipmentColor } from '../utils/tracks';
import { useColorChoiceItems } from './ColorEffects';

export function useTrackColoration() {
  const equipmentsById = useSelector(getEquipmentsStoredById);
  const selectedColor = useSelector(colorChoiceSelector.tracks);

  const colorItems = useColorChoiceItems()[selectedColor];

  return useCallback(
    (trackProps) => {
      const equipmentId = trackProps.equipment_id || trackProps.equipment_instance_id;
      const clusterId = trackProps.cluster_id || equipmentsById[equipmentId]?.clusterId;

      for (const item of colorItems || []) {
        if (
          selectedColor === COLOR_TRACKS_BY.CLUSTER
            ? trackHasClusterColor(clusterId, item)
            : selectedColor === COLOR_TRACKS_BY.EQUIPMENT
              ? trackHasEquipmentColor(equipmentId, item)
              : false
        ) {
          return item.color;
        }
      }
    },
    [colorItems, equipmentsById, selectedColor],
  );
}
