import { createContext } from 'react';
import { USER_CONSTANTS, UserTimeSystem, UserUnitSystem } from '../../constants';

const Context = createContext<{
  units: { area: UserUnitSystem; distance: UserUnitSystem; time: UserTimeSystem };
  viticultureAccount: boolean;
}>({
  units: {
    area: USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI,
    distance: USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI,
    time: USER_CONSTANTS.UNITS.TIME.STANDARD,
  },
  viticultureAccount: false,
});

export { Context };
