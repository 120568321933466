import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ColorChoice } from '../constants/colorConstants';
import { selectEquipmentColorItems } from '../selectors/equipments';
import { selectClusterColorItems } from '../slices/clusters';
import { ColorItem } from '../slices/colors';
import { selectCropColorItems } from '../slices/crops';
import { selectTagColorItems } from '../slices/tags';

export function useColorChoiceItems(): Record<ColorChoice, ColorItem[]> {
  const clusterColorItems = useSelector(selectClusterColorItems);
  const cropColorItems = useSelector(selectCropColorItems);
  const equipmentColorItems = useSelector(selectEquipmentColorItems);
  const tagColorItems = useSelector(selectTagColorItems);

  return useMemo(
    () => ({
      default: [],
      clusters: clusterColorItems,
      crops: cropColorItems,
      equipment: equipmentColorItems,
      tags: tagColorItems,
    }),
    [clusterColorItems, cropColorItems, equipmentColorItems, tagColorItems],
  );
}
