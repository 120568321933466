import { createSelector } from '@reduxjs/toolkit';
import { createAppSlice } from '.';
import { createTag as createApiTag, deleteTag as deleteApiTag, getTags, updateTag as updateApiTag } from '../api/api';
import { sortAlphaBeta } from '../utils';
import { getColorWithHash, randomHexColor } from '../utils/colors';
import { ItemWithPermissions } from '../utils/permissions';
import { ClusterId } from './clusters';
import { ColorItem } from './colors';
import { alerting, success } from './snacks';

export type TagId = string;

export type Tag = ItemWithPermissions & {
  id: TagId;
  label: string;
  color: string;
  cluster_id: ClusterId;
  from_application: string;
  beacon_count?: number;
  cluster_count?: number;
  device_count?: number;
  equipment_count?: number;
  parcel_count?: number;
  user_count?: number;
};

type State = {
  tagsByClusterId: Record<ClusterId, Tag[]>;
};

const initialState: State = {
  tagsByClusterId: {},
};

const tagReceived = (state: State, { payload }: { payload: Tag }) => {
  state.tagsByClusterId[payload.cluster_id] = (state.tagsByClusterId[payload.cluster_id] || []).filter(
    (t) => t.id !== payload.id,
  );
  state.tagsByClusterId[payload.cluster_id]!.push(payload);
};

export const tagsSlice = createAppSlice({
  name: 'tags',
  initialState,
  reducers: (create) => ({
    fetchTags: create.asyncThunk(
      async (_, { dispatch }) => {
        const tag = (await alerting(getTags, { dispatch })) as Tag[];
        return tag;
      },
      {
        fulfilled: (state, { payload }) => {
          state.tagsByClusterId = {};
          payload.forEach((tag) => {
            state.tagsByClusterId[tag.cluster_id] ??= [];
            state.tagsByClusterId[tag.cluster_id]!.push(tag);
          });
        },
      },
    ),
    createTag: create.asyncThunk<Tag, { label: string; clusterId: ClusterId; color?: string }>(
      async (newTag, { dispatch }) => {
        const tag = (await alerting(
          () => createApiTag(newTag.label, newTag.color || randomHexColor(), newTag.clusterId),
          { dispatch },
        )) as Tag;
        success('Admin.create_tag_snack', { dispatch });
        return tag;
      },
      { fulfilled: tagReceived },
    ),
    updateTag: create.asyncThunk<Tag, { id: TagId; tag: Partial<Tag> }>(
      async (payload, { dispatch }) => {
        const tag = (await alerting(() => updateApiTag(payload.id, payload.tag), { dispatch })) as Tag;
        success('Admin.edit_tag_snack', { dispatch });
        return tag;
      },
      { fulfilled: tagReceived },
    ),
    deleteTag: create.asyncThunk(
      async (id: TagId, { dispatch }) => {
        await alerting(() => deleteApiTag(id), { dispatch });
        success('Admin.delete_tag_snack', { dispatch });
        return id;
      },
      {
        fulfilled: (state, { payload: id }) => {
          for (const clusterId in state.tagsByClusterId) {
            state.tagsByClusterId[clusterId] = state.tagsByClusterId[clusterId]!.filter((t) => t.id !== id);
          }
        },
      },
    ),
  }),
  selectors: {
    selectTagsByClusterId: (state) => state.tagsByClusterId,
  },
});

export const { fetchTags, createTag, updateTag, deleteTag } = tagsSlice.actions;
export const { selectTagsByClusterId } = tagsSlice.selectors;

export const selectSortedTags = createSelector([selectTagsByClusterId], (tagsByClusterId) =>
  Object.values(tagsByClusterId)
    .flat()
    .sort((a, b) => sortAlphaBeta(a.label, b.label)),
);

export const selectTagColorItems = createSelector([selectSortedTags], (crops) => {
  return crops
    .filter((crop) => !!crop.color)
    .map(
      (crop): ColorItem => ({
        id: String(crop.id),
        name: crop.label,
        color: getColorWithHash(crop.color),
      }),
    );
});

export default tagsSlice.reducer;
