import { Values } from '../utils/types';

export const API_BASE = import.meta.env['VITE_API_BASE'];
export const LAYER_API_BASE = import.meta.env['VITE_LAYER_API_BASE'];
export const OAUTH_URL = import.meta.env['VITE_OAUTH_URL'];
export const DISPLAY_BANNER = import.meta.env['VITE_DISPLAY_BANNER'] === 'true';
export const FIREBASE_PROJECT_ID = import.meta.env['VITE_FIREBASE_PROJECT_ID'];
export const FIREBASE_APP_ID = import.meta.env['VITE_FIREBASE_APP_ID'];
export const FIREBASE_API_KEY = import.meta.env['VITE_FIREBASE_API_KEY'];
export const FIREBASE_MEASUREMENT_ID = import.meta.env['VITE_FIREBASE_MEASUREMENT_ID'];
export const INTERCOM_APP_ID = import.meta.env['VITE_INTERCOM_APP_ID'];
export const MIXPANEL_KEY = import.meta.env['VITE_MIXPANEL_KEY'];
export const DISPLAY_MES_PARCELLES = import.meta.env['VITE_IS_MES_PARCELLES_ACTIVATED'] === 'true';

export const LOCALE_FORMAT = 'L [-] LT';

export const SESSION_TYPE = {
  EQUIPMENT_SESSION: 'es',
  PARCEL_SESSION: 'ps',
} as const;

export const SESSION_ROW_SIZES = {
  S: '56px',
  M: '82px',
  L: '96px',
} as const;

export const USER_CONSTANTS = {
  ROLE: {
    ADMIN: 'ADMIN',
    DRIVER: 'DRIVER',
    SEASONAL_DRIVER: 'SEASONAL_DRIVER',
    IMPLEMENT_MANAGER: 'IMPLEMENT_MANAGER',
  },
  UNITS: {
    DISTANCE_AND_AREA: {
      SI: 'si',
      IMPERIAL: 'imperial',
    },
    TIME: {
      DECIMAL: 'decimal',
      DECIMAL2: 'decimal2',
      STANDARD: 'standard',
    },
  },
  /** A_TO_B : 1 B = A_TO_B A */
  UNIT_RATIO: {
    AC_TO_HA: 2.4710538147,
  },
} as const;

export type UserRole = Values<typeof USER_CONSTANTS.ROLE>;
export type UserUnitSystem = Values<typeof USER_CONSTANTS.UNITS.DISTANCE_AND_AREA>;
export type UserTimeSystem = Values<typeof USER_CONSTANTS.UNITS.TIME>;
