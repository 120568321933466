import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@karnott/colors';
import { COLOR_PARCELS_BY } from '../constants/colorConstants';
import { PARCELS_CONSTANTS } from '../constants/parcelsConstants';
import { PluginsContext } from '../contexts/PluginsProvider';
import { colorChoiceSelector } from '../slices/colors';
import { getUniqueTagFromParcel, parcelHasClusterColor, parcelHasCropColor, parcelHasTagColor } from '../utils/parcels';
import { useColorChoiceItems } from './ColorEffects';

export function useParcelColoration() {
  const { hasOverallCropManagementPlugin } = useContext(PluginsContext);

  const selectedColor = useSelector(colorChoiceSelector.parcels);
  const parcelsById = useSelector((state) => state.parcels.parcelById);

  const colorItems = useColorChoiceItems()[selectedColor];

  return useCallback(
    (parcelId) => {
      const parcel = parcelsById[parcelId];
      if (!parcel) return colors('orange');

      const cropId = parcel.current_crop_id;
      const tagId = getUniqueTagFromParcel(parcel);
      const clusterId = parcel.current_tenant_cluster_id || parcel.cluster_id;

      for (const item of colorItems || []) {
        if (
          selectedColor === COLOR_PARCELS_BY.CROP
            ? parcel.category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE &&
              hasOverallCropManagementPlugin &&
              parcelHasCropColor(cropId, item)
            : selectedColor === COLOR_PARCELS_BY.CLUSTER
              ? parcelHasClusterColor(clusterId, item)
              : parcelHasTagColor(tagId, item)
        ) {
          return item.color;
        }
      }
    },
    [colorItems, hasOverallCropManagementPlugin, parcelsById, selectedColor],
  );
}

export function useParcelColor({ withCurrentColoration = true } = { withCurrentColoration: true }) {
  const parcelsById = useSelector((state) => state.parcels.parcelById);

  const getParcelColorWithColoration = useParcelColoration();
  const getParcelColorWithoutColoration = useCallback(
    (parcelId) => {
      const parcel = parcelsById[parcelId];

      if (!parcel?.cluster_id) {
        return colors('orange');
      }
      if (parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
        return colors('yellow');
      }
      if (
        parcel.status === PARCELS_CONSTANTS.STATUS.RENT_OVER ||
        parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT ||
        parcel.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE
      ) {
        return colors('grey', 200);
      }
      return colors('cyan');
    },
    [parcelsById],
  );

  return useCallback(
    (parcelId) => {
      return (
        (withCurrentColoration && getParcelColorWithColoration(parcelId)) || getParcelColorWithoutColoration(parcelId)
      );
    },
    [getParcelColorWithColoration, getParcelColorWithoutColoration, withCurrentColoration],
  );
}
