import { Values } from '../utils/types';

export const MIXTURE_STATE = {
  COMPLIANT: 'ok',
  NON_COMPLIANT: 'not_ok',
  ARCHIVED: 'archived',
} as const;

export type MixtureState = Values<typeof MIXTURE_STATE>;

export const PRODUCT_STATE = {
  WITHDRAW: 'RETIRE',
  AUTHORIZED: 'AUTORISE',
} as const;

export type ProductState = Values<typeof PRODUCT_STATE>;

export const USAGE_STATE = {
  WITHDRAW: 'Retrait',
  AUTHORIZED: 'Autorisé',
  TEMPORARILY: 'Autorisé (Provisoire)',
} as const;

export type UsageState = Values<typeof USAGE_STATE>;

export const USAGE_ISSUES = {
  PRODUCT_WITHDRAWN: 'product_withdrawn',
  USAGE_WITHDRAWN: 'usage_withdrawn',
  OVERDOSE: 'overdose',
  UNAUTHORIZED_MIX: 'unauthorized_with_other_product',
} as const;

export type UsageIssues = Values<typeof USAGE_ISSUES>;

export const IFT_STATUS = {
  TODO: 'TODO',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  PARTIALLY_DONE: 'PARTIALLY_DONE',
  DONE: 'DONE',
} as const;

export type IftStatus = Values<typeof IFT_STATUS>;
