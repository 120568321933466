import { formatArea } from '.';
import { UserUnitSystem } from '../constants';
import { I18n } from '../i18n';
import { Crop } from '../slices/crops';

export function buildCropLabel(crop: Crop, unit: UserUnitSystem) {
  let label = crop.name;
  label += crop.code ? ` (${crop.code}) ` : ' ';
  if (crop.parcel_count) {
    label += `(${crop.parcel_count} ${crop.parcel_count > 1 ? I18n.t('Filters.parcels') : I18n.t('Filters.parcel')}`;
    label += ' - ';
    label += `${formatArea(crop.parcel_area_sum, unit, true)})`;
  } else {
    label += `(0 ${I18n.t('Filters.parcel')})`;
  }
  return label;
}
