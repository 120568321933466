import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ArchiveIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '55%' : '100%'}
      height={circled ? '55%' : '100%'}
      viewBox="0 0 27.011 21"
    >
      <g transform="translate(1.001 1)">
        <path
          d="M1,22.057V5.3A1.309,1.309,0,0,1,2.324,4h6.4a1.575,1.575,0,0,1,1.1.507l1.4,1.741h10a1.309,1.309,0,0,1,1.324,1.3v2.828"
          transform="translate(-1 -4)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M21.522,23H1.809a.764.764,0,0,1-.735-1.088l3.015-10.37a1.573,1.573,0,0,1,1.4-1.088H25.2a.764.764,0,0,1,.735,1.088L22.92,21.912A1.573,1.573,0,0,1,21.522,23Z"
          transform="translate(-1 -4)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
