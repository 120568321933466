import { memo, useContext, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { UIContext } from '../../contexts/ui';
import { zIndex } from '../../ui/theme';
import { Icons } from '../new_icons';
import Devices from './assets/Devices';
import Drivers from './assets/Drivers';
import Equipments from './assets/Equipments';
import Locations from './assets/Locations';
import Observations from './assets/Observations';
import Parcels from './assets/Parcels';
import Tracks from './assets/Tracks';
import UnitLocations from './assets/UnitLocations';
import Worksites from './assets/Worksites';
import { MapContext } from './contexts/map';
import { useCallSwitch, useFitWithRegionBinding } from './contexts/map/effects';
import { MapOverlayProvider } from './contexts/overlay';
import { useAssetsPoints, useCarrierInContainer, useFitMapOnPoints, useMapOptions } from './effects';
import Overlays from './overlays/Overlays';

const Map = function ({
  // go to the last location stored by persistRegion
  bindRegion,
  devices,
  devicesEvents,
  drivers,
  driverEvents,
  equipments,
  equipmentEvents,
  fitMask,
  hideDriverTooltip,
  hideEquipmentsActiveSession,
  loading,
  locations,
  // prevent the map from fitting on props (parcels, tracks...)
  lockFitUpdates,
  observations,
  observationEvents,
  options,
  overlays,
  overTrackID,
  parcels,
  parcelsCanvasRenderer,
  parcelsEvents,
  parcelOverTracks,
  parcelsNamesDraggable,
  parcelsStyleOverrides,
  // store the last visited location
  persistRegion,
  unitLocations,
  unitLocationEvents,
  trackOverStyle,
  tracks,
  tracksEvents,
  tracksStyleOverrides,
  tracksWithEnd,
  tracksWithEndIcons,
  tracksWithoutFlags,
  withoutLogo = false,
  worksites,
  worksiteEvents,
}) {
  const { carrier, map, regionBinder } = useContext(MapContext);
  const container = useRef(null);
  const { showMarkerClusters, toggleMarkerClusters } = useContext(UIContext);

  const points = useAssetsPoints(fitMask, equipments, parcels, tracks, devices, observations, drivers, locations);
  useCarrierInContainer({ carrier, container, map });
  useMapOptions(map, options);
  const [fitMap] = useFitMapOnPoints(points, map, loading || lockFitUpdates);
  useFitWithRegionBinding(regionBinder.isBinded, regionBinder.region, fitMap);

  useCallSwitch(persistRegion, regionBinder.trackRegion, regionBinder.untrackRegion);
  useCallSwitch(bindRegion, regionBinder.bind, regionBinder.unbind);
  return (
    <MapOverlayProvider {...{ fitMap, toggleMarkerClusters }}>
      <Container ref={container}>
        {unitLocations?.length ? <UnitLocations events={unitLocationEvents} {...{ unitLocations }} /> : null}
        {observations?.length ? <Observations events={observationEvents} {...{ observations }} /> : null}
        {locations ? <Locations locations={locations} /> : null}
        {equipments?.length ? (
          <Equipments
            events={equipmentEvents}
            hideActiveSession={hideEquipmentsActiveSession}
            trackEvents={tracksEvents}
            overStyle={trackOverStyle}
            overTrackID={overTrackID}
            trackStyleOverrides={tracksStyleOverrides}
            {...{
              equipments,
              tracksWithoutFlags,
            }}
          />
        ) : null}
        {worksites?.length ? (
          <Worksites
            events={worksiteEvents}
            trackEvents={tracksEvents}
            overTrackID={overTrackID}
            overStyle={trackOverStyle}
            {...{
              worksites,
              tracksWithoutFlags,
            }}
          />
        ) : null}
        {devices?.length ? (
          <Devices
            events={devicesEvents}
            {...{
              devices,
            }}
          />
        ) : null}
        {parcels?.length ? (
          <Parcels
            parcelsCanvasRenderer={parcelsCanvasRenderer}
            parcels={parcels}
            events={parcelsEvents}
            styleOverrides={parcelsStyleOverrides}
            parcelOverTracks={parcelOverTracks}
            parcelsNamesDraggable={parcelsNamesDraggable}
            forceNoMarkerCluster={!showMarkerClusters}
          />
        ) : null}
        {drivers?.length ? (
          <Drivers
            drivers={drivers}
            events={driverEvents}
            overStyle={trackOverStyle}
            overTrackID={overTrackID}
            trackEvents={tracksEvents}
            {...{
              tracksWithoutFlags,
              hideDriverTooltip,
            }}
          />
        ) : null}
        {tracks?.features?.length ? (
          <Tracks
            tracks={tracks}
            tracksWithoutFlags={tracksWithoutFlags}
            events={tracksEvents}
            overTrackID={overTrackID}
            overStyle={trackOverStyle}
            styleOverrides={tracksStyleOverrides}
            withEnd={tracksWithEnd}
            withEndIcons={tracksWithEndIcons}
          />
        ) : null}
        {overlays?.length ? <Overlays {...{ overlays }} /> : null}
        {withoutLogo ? null : (
          <KarnottLogo>
            <Icons.KarnottWithNameIconColor color={'white'} size={33} />
          </KarnottLogo>
        )}
      </Container>
    </MapOverlayProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`;

const KarnottLogo = styled.div`
  background-color: ${colors('white')};
  border-radius: 5px;
  height: 20px;
  width: 60px;
  pointer-events: none;
  position: absolute;
  left: 5px;
  bottom: 27px;
  z-index: ${zIndex('ui')};
`;

const MapMemo = memo(Map);
export default MapMemo;
