import { ChangeEvent, forwardRef, useId, useImperativeHandle, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors, rgbaColors } from '@karnott/colors';
import { KIcon } from '@karnott/icons';
import { fontFamily, pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';
import { Field } from '../field';
import { Effects } from './effects';

const InputContainer = styled.div<{
  disabled: boolean;
  value: string;
}>`
  display: flex;
  align-items: center;
  line-height: 1.5;
  width: 100%;
  ${({ disabled, value }) =>
    !disabled &&
    !value &&
    css`
      &:hover svg * {
        stroke: ${colors('grey', 600)};
      }
    `};
`;

const IconWrapper = styled.div<{
  large: boolean;
}>`
  pointer-events: none;
  position: absolute;
  margin-left: ${({ large }) => pixelSpacing(large ? 'medium' : 'small')};
`;

const StyledInput = styled.input<{
  error: boolean;
  valid: boolean;
  large: boolean;
  icon: boolean;
  disabled: boolean;
}>`
  font-family: ${fontFamily()};
  outline: solid 1px;
  border: none;
  width: 100%;
  height: 100%;
  line-height: 17px;
  box-sizing: border-box;
  padding: ${({ large }) => (large ? pixelSpacing('medium') : `${pixelSpacing('small')}`)};
  ${({ icon, large }) =>
    icon &&
    css`
      padding-left: ${large ? '36px' : '26px'};
    `}
  border-radius: ${({ large }) => (large ? 5 : spacing('xSmall'))}px;
  color: ${({ error }) => (error ? colors('red') : colors('black'))};
  background-color: ${({ error }) => (error ? colors('red', 300) : colors('white'))};
  outline-color: ${({ valid, error }) => {
    if (error) return colors('red', 500);
    if (valid) return colors('green');
    return colors('grey', 200);
  }};
  transition: all 0.1s linear;
  font-size: ${({ large }) => (large ? pixelSize('large') : pixelSize())};
  &[disabled] {
    color: ${colors('grey', 200)};
    outline-color: ${colors('grey', 200)};
    cursor: not-allowed;
    ::placeholder {
      color: ${colors('grey', 200)};
    }
    :hover {
      box-shadow: none;
      ::placeholder {
        color: currentColor;
      }
    }
  }
  :hover,
  :focus {
    box-shadow: 0px 0px ${({ large }) => (large ? 6 : 3)}px
      ${({ large }) => rgbaColors('black', 600, large ? 0.29 : 0.17)};
    ::placeholder {
      color: ${colors('grey', 600)};
    }
  }
  ${({ error, valid }) =>
    !error &&
    !valid &&
    css`
      :focus {
        outline-color: ${colors('grey', 500)};
      }
    `}
  ::placeholder {
    color: ${colors('grey', 400)};
    transition: all 0.1s linear;
  }
`;

type InputRef = {
  focus: () => void;
  blur: () => void;
};

type Props = {
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Whether the input is in a valid state (green border) */
  valid?: boolean;
  /** The error state of the input. A string shows an error message */
  error?: string | boolean;
  /** Whether the input is large */
  large?: boolean;
  /** The text content of the input */
  value: string;
  /** The label of the input */
  label?: string;
  /** The callback called when the user inputs content */
  onValueChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /** The callback called when the input is blurred (loses focus) */
  onBlur?: () => void;
  /** The callback called when the input gains focus */
  onFocus?: () => void;
  /** The input’s placeholder text */
  placeholder?: string;
  /** An icon to show at the start of the input */
  Icon?: KIcon;
  /** The id of the input */
  id?: string;
};

/**
 * A text input to gather information from the user.
 *
 * You can bind a ref of type `InputRef` to the input to access methods to focus or blur the input.
 */
const Input = forwardRef<InputRef, Props>(function InputComponent(
  {
    disabled = false,
    valid = false,
    error = false,
    large = false,
    value,
    label,
    onValueChange,
    onBlur,
    onFocus,
    placeholder,
    Icon,
    id: providedId,
  }: Props,
  ref,
) {
  const input = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      input.current?.focus();
    },
    blur: () => {
      input.current?.blur();
    },
  }));

  const [focused, focusCB, blurCB] = Effects.useInputFocus({ onFocus, onBlur });
  const generatedId = useId();
  const id = providedId || generatedId;

  return (
    <Field label={label} error={typeof error === 'string' ? error : undefined} htmlFor={id}>
      <InputContainer disabled={disabled} value={value}>
        {Icon && (
          <IconWrapper large={large}>
            <Icon
              size={size(large ? 'large' : 'regular')}
              color={
                error
                  ? colors('red')
                  : disabled
                    ? colors('grey', 200)
                    : value
                      ? colors('black')
                      : focused
                        ? colors('grey', 600)
                        : colors('grey', 400)
              }
            />
          </IconWrapper>
        )}
        <StyledInput
          onChange={onValueChange}
          onFocus={focusCB}
          onBlur={blurCB}
          ref={input}
          placeholder={placeholder}
          icon={!!Icon}
          error={!!error}
          {...{ disabled, valid, value, large }}
          id={id}
        />
      </InputContainer>
    </Field>
  );
});

export { Input, Effects as InputEffects, type InputRef };
