import { ReactNode, createContext, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CLUSTER_CONSTANTS } from '../constants/clusterConstants';
import { Cluster, selectClustersWithRole } from '../slices/clusters';
import { isPluginActivated } from '../utils/clusters';

type ClusterHasPlugin = (c: Cluster) => boolean;

type Context = {
  clusterHasCountUnitPlugin: ClusterHasPlugin;
  clusterHasCropManagementPlugin: ClusterHasPlugin;
  clusterHasObservationPlugin: ClusterHasPlugin;
  clusterHasShareSessionPlugin: ClusterHasPlugin;
  clusterHasSpeedPlugin: ClusterHasPlugin;
  clusterHasUserRoleManagementPlugin: ClusterHasPlugin;
  clusterHasWorksiteReportPlugin: ClusterHasPlugin;
  clusterHasRealTimePlugin: ClusterHasPlugin;
  clusterHasRentPlugin: ClusterHasPlugin;
  clusterHasPhytoPlugin: ClusterHasPlugin;
  clusterHasWorksiteManagementPlugin: ClusterHasPlugin;
  hasOverallCropManagementPlugin: boolean;
  hasOverallObservationPlugin: boolean;
  hasOverallUserRoleManagementPlugin: boolean;
  hasOverallIgnTilesPlugin: boolean;
  hasOverallWorksiteReportPlugin: boolean;
  hasOverallPlansPlugin: boolean;
  hasOverallRealTimePlugin?: boolean;
  hasOverallSmagPlugin: boolean;
  hasOverallRentPlugin: boolean;
  hasOverallPhytoPlugin?: boolean;
  hasOverallWorksiteManagementPlugin?: boolean;
  isContextLoaded: boolean;
};

export const PluginsContext = createContext<Context>({
  clusterHasCountUnitPlugin: () => false,
  clusterHasCropManagementPlugin: () => false,
  clusterHasObservationPlugin: () => false,
  clusterHasShareSessionPlugin: () => false,
  clusterHasSpeedPlugin: () => false,
  clusterHasUserRoleManagementPlugin: () => false,
  clusterHasWorksiteReportPlugin: () => false,
  clusterHasRealTimePlugin: () => false,
  clusterHasRentPlugin: () => false,
  clusterHasPhytoPlugin: () => false,
  clusterHasWorksiteManagementPlugin: () => false,
  hasOverallCropManagementPlugin: false,
  hasOverallObservationPlugin: false,
  hasOverallUserRoleManagementPlugin: false,
  hasOverallIgnTilesPlugin: false,
  hasOverallWorksiteReportPlugin: false,
  hasOverallPlansPlugin: false,
  hasOverallRealTimePlugin: false,
  hasOverallSmagPlugin: false,
  hasOverallRentPlugin: false,
  hasOverallPhytoPlugin: false,
  hasOverallWorksiteManagementPlugin: false,
  isContextLoaded: false,
});

export const PluginsProvider = function ({ children }: { children: ReactNode }) {
  const clustersWithRole = useSelector(selectClustersWithRole);

  const clusterHasRentPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.rent),
    [],
  );

  const hasOverallRentPlugin = useMemo(
    () => clustersWithRole.some(clusterHasRentPlugin),
    [clusterHasRentPlugin, clustersWithRole],
  );

  const clusterHasCropManagementPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.crop_management),
    [],
  );

  const hasOverallCropManagementPlugin = useMemo(
    () => clustersWithRole.some(clusterHasCropManagementPlugin),
    [clusterHasCropManagementPlugin, clustersWithRole],
  );

  const clusterHasObservationPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.observation),
    [],
  );

  const hasOverallObservationPlugin = useMemo(
    () => clustersWithRole.some(clusterHasObservationPlugin),
    [clusterHasObservationPlugin, clustersWithRole],
  );

  const clusterHasCountUnitPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.count_bale),
    [],
  );

  const clusterHasUserRoleManagementPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.user_role_management),
    [],
  );

  const hasOverallUserRoleManagementPlugin = useMemo(
    () => clustersWithRole.some(clusterHasUserRoleManagementPlugin),
    [clusterHasUserRoleManagementPlugin, clustersWithRole],
  );

  const clusterHasShareSessionPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.share),
    [],
  );

  const clusterHasSpeedPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.speed),
    [],
  );

  const hasOverallIgnTilesPlugin = useMemo(
    () => clustersWithRole.some((c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.ign_tiles)),
    [clustersWithRole],
  );

  const clusterHasWorksiteReportPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.worksite_report),
    [],
  );

  const hasOverallWorksiteReportPlugin = useMemo(
    () => clustersWithRole.some(clusterHasWorksiteReportPlugin),
    [clusterHasWorksiteReportPlugin, clustersWithRole],
  );

  const hasOverallPlansPlugin = useMemo(
    () => clustersWithRole.some((c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.printing_maps)),
    [clustersWithRole],
  );

  const clusterHasRealTimePlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.real_time),
    [],
  );

  const hasOverallRealTimePlugin = useMemo(() => {
    if (!clustersWithRole?.length) {
      return undefined;
    } else {
      return clustersWithRole.some(clusterHasRealTimePlugin);
    }
  }, [clusterHasRealTimePlugin, clustersWithRole]);

  const clusterHasPhytoPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.phyto),
    [],
  );

  const hasOverallPhytoPlugin = useMemo(() => {
    if (!clustersWithRole?.length) {
      return undefined;
    } else {
      return clustersWithRole.some(clusterHasPhytoPlugin);
    }
  }, [clusterHasPhytoPlugin, clustersWithRole]);

  const clusterHasWorksiteManagementPlugin = useCallback(
    (c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.worksite_management),
    [],
  );

  const hasOverallWorksiteManagementPlugin = useMemo(() => {
    if (!clustersWithRole?.length) {
      return undefined;
    } else {
      return clustersWithRole.some(clusterHasWorksiteManagementPlugin);
    }
  }, [clusterHasWorksiteManagementPlugin, clustersWithRole]);

  const hasOverallSmagPlugin = useMemo(
    () => clustersWithRole.some((c: Cluster) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.smag_farmer)),
    [clustersWithRole],
  );
  const isContextLoaded = useMemo(() => clustersWithRole && clustersWithRole.length > 0, [clustersWithRole]);

  return (
    <PluginsContext.Provider
      value={{
        clusterHasCountUnitPlugin,
        clusterHasCropManagementPlugin,
        clusterHasObservationPlugin,
        clusterHasShareSessionPlugin,
        clusterHasSpeedPlugin,
        clusterHasUserRoleManagementPlugin,
        clusterHasWorksiteReportPlugin,
        clusterHasRealTimePlugin,
        clusterHasRentPlugin,
        clusterHasPhytoPlugin,
        clusterHasWorksiteManagementPlugin,
        hasOverallCropManagementPlugin,
        hasOverallObservationPlugin,
        hasOverallUserRoleManagementPlugin,
        hasOverallIgnTilesPlugin,
        hasOverallWorksiteReportPlugin,
        hasOverallPlansPlugin,
        hasOverallRealTimePlugin,
        hasOverallSmagPlugin,
        hasOverallRentPlugin,
        hasOverallPhytoPlugin,
        hasOverallWorksiteManagementPlugin,
        isContextLoaded,
      }}
    >
      {children}
    </PluginsContext.Provider>
  );
};
