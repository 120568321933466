import { Values } from '../utils/types';

export const CLUSTER_CONSTANTS = {
  PLUGINS_NAMES: {
    share: 'share_equipment_session',
    speed: 'show_speed',
    count_bale: 'count_bale',
    crop_management: 'crop_management',
    observation: 'observation',
    user_role_management: 'user_role_management',
    ign_tiles: 'ign_tiles',
    worksite_report: 'worksite_report',
    printing_maps: 'printing_maps',
    real_time: 'real_time',
    smag_farmer: 'smag_farmer',
    rent: 'rent_field',
    phyto: 'phyto',
    worksite_management: 'worksite_management',
  },
  ACTIVITIES: {
    culture: 'culture',
    viticulture: 'viticulture',
    maraichage: 'maraichage',
    elevage: 'elevage',
    ostreiculture: 'ostreiculture',
    arboriculture: 'arboriculture',
    deneigement: 'deneigement',
    sylviculture: 'sylviculture',
  },
  PLANS: {
    NONE: 'none',
    AGRI: 'good',
    PRO: 'better',
    PRO_PLUS: 'better_plus',
    PREMIUM: 'best',
    PREMIUM_PLUS: 'best_plus',
    DISCOVER: 'discover',
    LEGACY_SUBSCRIPTION: 'legacy',
  },
} as const;
export type PluginName = Values<typeof CLUSTER_CONSTANTS.PLUGINS_NAMES>;
export type ClusterActivity = Values<typeof CLUSTER_CONSTANTS.ACTIVITIES>;
export type ClusterPlan = Values<typeof CLUSTER_CONSTANTS.PLANS>;

export const CLUSTER_TYPE = {
  ETA: 'ETA',
  CUMA: 'CUMA',
  FARM: 'FARM',
  CONCESSIONNAIRE: 'CONCESSIONNAIRE',
} as const;
export type ClusterType = Values<typeof CLUSTER_TYPE>;
