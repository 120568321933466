import { ChangeEvent, useId } from 'react';
import styled, { css } from 'styled-components';
import { colors, rgbaColors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';
import { Field } from '../field';

const TextAreaContainer = styled.div<{
  $disabled: boolean;
  $value: string;
}>`
  display: flex;
  align-items: center;
  line-height: 1.5;
  width: 100%;
  ${({ $disabled, $value }) =>
    !$disabled &&
    !$value &&
    css`
      &:hover svg * {
        stroke: ${colors('grey', 600)};
      }
    `};
`;

const StyledTextArea = styled.textarea<{
  error: boolean;
  valid: boolean;
  disabled: boolean;
  resizeable: boolean;
}>`
  font-family: ${fontFamily()};
  outline: solid 1px;
  border: none;
  width: 100%;
  height: 100%;
  min-height: 33px;
  line-height: 17px;
  box-sizing: border-box;
  padding: ${`${pixelSpacing('small')}`};
  border-radius: ${pixelSpacing('xSmall')};
  color: ${({ error }) => (error ? colors('red') : colors('black'))};
  background-color: ${({ error }) => (error ? colors('red', 300) : colors('white'))};
  outline-color: ${({ valid, error }) => {
    if (error) return colors('red', 500);
    if (valid) return colors('green');
    return colors('grey', 200);
  }};
  transition: all 0.1s linear;
  font-size: ${pixelSize()};
  resize: ${({ resizeable }) => (resizeable ? 'vertical' : 'none')};

  &[disabled] {
    color: ${colors('grey', 200)};
    outline-color: ${colors('grey', 200)};
    cursor: not-allowed;
    ::placeholder {
      color: ${colors('grey', 200)};
    }
    :hover {
      box-shadow: none;
      ::placeholder {
        color: currentColor;
      }
    }
  }
  :hover,
  :focus {
    box-shadow: 0px 0px 3px ${rgbaColors('black', 600, 0.17)};
    ::placeholder {
      color: ${colors('grey', 600)};
    }
  }
  ${({ error, valid }) =>
    !error &&
    !valid &&
    css`
      :focus {
        outline-color: ${colors('grey', 500)};
      }
    `}
  ::placeholder {
    color: ${colors('grey', 400)};
    transition: all 0.1s linear;
  }
`;

type Props = {
  /** Whether the textarea is disabled */
  disabled?: boolean;
  /** Whether the textarea is in a valid state (green border) */
  valid?: boolean;
  /** The error state of the textarea. A string shows an error message */
  error?: string | boolean;
  /** The text content of the textarea */
  value: string;
  /** The label of the textarea */
  label?: string;
  /** The callback called when the user inputs content */
  onValueChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  /** The textarea’s placeholder text */
  placeholder?: string;
  /** The id of the textarea */
  id?: string;
  /** The initial number of lines */
  lines?: number;
  /** Whether the textarea element is resizeable */
  resizeable?: boolean;
};

/** A text box to gather long-form content from the user. */
function TextArea({
  disabled = false,
  valid = false,
  error = false,
  value,
  label,
  onValueChange,
  placeholder,
  id: providedId,
  resizeable = false,
  lines = 2,
}: Props) {
  const generatedId = useId();
  const id = providedId || generatedId;

  return (
    <Field label={label} error={typeof error === 'string' ? error : undefined} htmlFor={id}>
      <TextAreaContainer $disabled={disabled} $value={value}>
        <StyledTextArea
          onChange={onValueChange}
          placeholder={placeholder}
          error={!!error}
          disabled={disabled}
          valid={valid}
          value={value}
          id={id}
          rows={lines}
          resizeable={resizeable}
        />
      </TextAreaContainer>
    </Field>
  );
}

export { TextArea };
