import { MIXTURE_STATE, PRODUCT_STATE, USAGE_ISSUES, USAGE_STATE } from '../constants/phytosConstants';
import { I18n } from '../i18n';

const t = I18n.t;

export const formatProductFunctions = (product) => {
  let functions = '';
  product.functions?.map((f, index) => {
    return (functions += `${f}${index < product.functions.length - 1 ? ', ' : ''}`);
  });
  return functions;
};

export const formatMixtureFunction = (mixture) => {
  if (mixture.product_usages.length === 1 && mixture.product_usages.at(0).product_state === '') {
    return t('Admin.unlisted_function');
  }
  const functions = mixture.product_usages?.map((pu) => pu.function).filter((f) => !!f);
  const combinedArray = [...new Set(functions.flat(1))];
  const functionLabel = combinedArray.length === 1 ? combinedArray[0] : t('Admin.multiTreatment');
  return functionLabel;
};

/**
 * @param   {string[]} delays An array of reentry delays
 *
 * @returns {string}
 */
export const formatReentryDelays = (delays) => {
  return delays?.join(', ') || '-';
};

const checkUsageAuthorization = (usage) => {
  return (
    usage.toLowerCase() === USAGE_STATE.AUTHORIZED.toLowerCase() ||
    usage.toLowerCase() === USAGE_STATE.TEMPORARILY.toLowerCase()
  );
};

/**
 * @param   {Record<string, any> | string} usage Usage object, or usage property
 *
 * @returns {boolean}                            True if usage is authorized
 */
export const isUsageAuthorized = (usage) => {
  return usage && typeof usage?.state === 'string'
    ? checkUsageAuthorization(usage.state)
    : typeof usage === 'string'
      ? checkUsageAuthorization(usage)
      : false;
};

export const isMixtureCompliant = (mixture) => {
  return mixture?.status === MIXTURE_STATE.COMPLIANT;
};

export const isProductMixAuthorized = (mixture) => {
  return !(mixture?.product_usages || []).reduce(
    (p, u) => p || ('unauthorized_with_other_product_ids' in u && u.unauthorized_with_other_product_ids.length > 0),
    false,
  );
};

export const isCustomProduct = (product) => {
  return !!product?.cluster_id;
};

export function getUsageProblems(usage, internalDose = 0, isEditing = false) {
  if (!usage) return [];

  const problems = [];
  if (usage.product_state === PRODUCT_STATE.WITHDRAW) {
    problems.push(USAGE_ISSUES.PRODUCT_WITHDRAWN);
  }
  if (!isUsageAuthorized(usage.product_usage_state)) {
    problems.push(USAGE_ISSUES.USAGE_WITHDRAWN);
  }
  if (
    // a custom product with no max dose is always good
    // a normal product with no max dose is always in overdose
    (!!usage.product_state || usage.product_usage_dose > 0) &&
    ((usage.dose > usage.product_usage_dose && !isEditing) || internalDose > usage.product_usage_dose)
  ) {
    problems.push(USAGE_ISSUES.OVERDOSE);
  }

  return problems;
}

/**
 * @param   {string[]} problems List of USAGE_PROBLEMS
 *
 * @returns {string}            The label to display for the usage problems
 */
export function getUsageProblemsLabel(problems) {
  const labels = [];
  for (let i = 0; i < problems.length; i++) {
    const problem = problems[i];

    const label = t(`CreateTaskModal.mixture_input_error_${problem}`);
    labels.push(i === 0 ? label : label.toLowerCase());
  }
  return labels.join(', ');
}

export function getProductLabelForList(product) {
  const formattedFunctions = formatProductFunctions(product);
  return (
    product?.name &&
    `${product.name.toLocaleUpperCase()}${
      isCustomProduct(product)
        ? ` (${t('Admin.unlisted_product')})`
        : formattedFunctions
          ? ` (${formattedFunctions})`
          : ''
    }`
  );
}
