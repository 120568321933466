import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors } from '@karnott/colors';
import { fontFamily, pixelSpacing, spacing, zIndex } from '@karnott/theme';
import { useRefWidthHeight } from '../effects';

const Container = styled.div<{
  full: boolean;
}>`
  display: flex;
  position: relative;
  flex: ${({ full }) => (full ? 1 : 'none')};
`;

const slideTopKF = keyframes`
  0% {
      opacity:0;
      transform: translateY(-20px);
  }
  100% {
      opacity:1;
      transform: translateY(0px);
  }
`;

const Menu = styled.div<{
  hide: boolean;
  top?: number;
  bottom?: number;
  left: number;
}>`
  font-family: ${fontFamily('default')};
  background-color: ${colors('white')};
  z-index: ${zIndex('sky')};
  color: ${colors('black')};
  position: absolute;
  animation: ${slideTopKF} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.31);
  border-radius: ${pixelSpacing('xSmall')};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  left: ${({ left }) => left}px;
  ${({ top }) =>
    top
      ? css`
          top: ${top}px;
        `
      : null}
  ${({ bottom }) =>
    bottom
      ? css`
          bottom: ${bottom}px;
        `
      : null}
`;

const MenuContent = styled.div`
  display: flex;
`;

type Props = {
  /** Container of the popover */
  children: ReactNode;
  /** Content of the popover */
  content: ReactNode;
  /** Should the popover take the full width of the container */
  full?: boolean;
  /** Is the popover opened */
  opened: boolean;
  /** Should the popover be placed above the container */
  top?: boolean;
  /** Should the popover be placed to the right of the container */
  right?: boolean;
  /** Should the popover be placed below the container */
  bottom?: boolean;
  /** Should the popover be placed to the left of the container */
  left?: boolean;
  /** Function to close the popover */
  onRequestClose: () => void;
};

/** A controlled popover menu that can contain anything */
export function PopoverMenu({
  full = false,
  children,
  content,
  opened,
  top,
  right,
  bottom,
  left,
  onRequestClose,
}: Props) {
  const [containerRef, width, height] = useRefWidthHeight<HTMLDivElement>(opened, content);
  const [menuRef, menuWidth, menuHeight] = useRefWidthHeight<HTMLDivElement>(opened, content);

  const { leftPosition, verticalPosition } = useMemo(() => {
    if (!containerRef.current) return { leftPosition: 0, verticalPosition: 0 };
    const leftPosition = left ? -menuWidth - spacing('small') : right ? width + spacing('small') : 0;
    const verticalPosition = bottom
      ? height + spacing('small')
      : top
        ? height + spacing('small')
        : -menuHeight + height;

    const { x: buttonX } = containerRef.current.getBoundingClientRect();

    const winWidth = window.innerWidth;
    const menuOriginX = left ? buttonX - menuWidth : right ? buttonX + width : buttonX;
    const menuEndX = menuOriginX + menuWidth;

    let widthCorrection = 0;

    if (menuEndX > winWidth) {
      widthCorrection = winWidth - menuEndX - spacing('small');
    }

    return { leftPosition: leftPosition + widthCorrection, verticalPosition };
  }, [menuWidth, menuHeight, width, height, left, bottom, top, right, containerRef]);

  const onContainerClick = useCallback(() => {
    opened && onRequestClose();
  }, [opened, onRequestClose]);

  const onDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (e.target instanceof Element && !containerRef.current?.contains(e.target)) {
        onRequestClose();
      }
    },
    [containerRef, onRequestClose],
  );

  useEffect(() => {
    document.addEventListener('click', onDocumentClick, true);
    return () => {
      document.removeEventListener('click', onDocumentClick, true);
    };
  }, [onDocumentClick]);

  return (
    <Container full={full} ref={containerRef} onClick={onContainerClick}>
      {children}
      <Menu
        ref={menuRef}
        hide={!opened}
        left={leftPosition}
        top={bottom ? verticalPosition : undefined}
        bottom={bottom ? undefined : verticalPosition}
      >
        <MenuContent>{content}</MenuContent>
      </Menu>
    </Container>
  );
}
