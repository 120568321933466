import { API_BASE, LAYER_API_BASE } from '../constants';
import { TASK_TYPE } from '../constants/taskConstants';
import { RemoteConfig } from '../utils/RemoteConfig';

const logout = () => {
  window.location.replace('/logout');
};

/** Removes empty or `null` params from the URLSearchParams input, without mutating it */
const removeEmptyParams = (params) => {
  const cleanParams = new URLSearchParams();
  for (const entry of params.entries()) {
    const values = entry[1].split(',');
    if (entry[1] !== '' && entry[1] !== 'null' && entry[1] !== 'undefined') {
      values.forEach((value) => cleanParams.append(entry[0], value));
    }
  }
  return cleanParams;
};

const handleResponse = (response) => {
  if (response.status === 401) {
    logout();
  }
  if (response.status === 503) {
    RemoteConfig.getInstance().fetchAndActivate(true);
  }
  if (!response.ok) {
    return response.json().then(Promise.reject.bind(Promise));
  }
  if (response.status === 204) {
    return Promise.resolve([]);
  }
  if (response.redirected || response.headers.get('Content-Type') === 'application/pdf') {
    if (response.headers.get('Content-Type') !== 'application/json') {
      return response.blob().then((myBlob) => URL.createObjectURL(myBlob));
    } else {
      return response.url;
    }
  }
  return response.json();
};

const handleFormat = (format, response) => {
  if (response.status === 401) {
    logout();
  }

  if (!response.ok) {
    return response.text().then(Promise.reject.bind(Promise));
  }

  if (response.status === 204) {
    return [];
  }

  if (format === 'csv') {
    return response.text().then((data) => data);
  }
  if (format === 'xlsx') {
    return response.arrayBuffer();
  }
};

const getToken = () => {
  try {
    let { user } = JSON.parse(localStorage.getItem('state'));
    return user?.token;
  } catch {
    return undefined;
  }
};

const headers = (input) => {
  const token = getToken();

  if (token) {
    return {
      ...input,
      headers: {
        ...input.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return input;
};

const get = (uri, signal, catchAndLogError = true) => {
  const httpCall = fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'GET',
      headers: {},
      signal,
    }),
  ).then(handleResponse);
  if (catchAndLogError) {
    return httpCall.catch((e) => {
      if (e.name !== 'AbortError') {
        console.error(e);
        return Promise.reject(e);
      }
    });
  }
  return httpCall;
};

async function getAndOpen(uri, signal, catchAndLogError = true) {
  const newTab = window.open('/loading', '_blank');
  try {
    const url = await get(uri, signal, catchAndLogError);
    newTab.location.href = url;
  } catch (e) {
    newTab.close();
    throw e;
  }
}

const getCsv = (uri) =>
  fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'GET',
      headers: {},
    }),
  )
    .then((response) => handleFormat('csv', response))
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

const getXlsx = (uri) =>
  fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'GET',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    }),
  )
    .then((response) => handleFormat('xlsx', response))
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

const post = (uri, payload = {}, catchAndLogError = true, signal = null) => {
  const httpCall = fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(payload),
      signal,
    }),
  ).then(handleResponse);
  if (catchAndLogError) {
    return httpCall.catch((e) => {
      if (e.name !== 'AbortError') {
        console.error(e);
        return Promise.reject(e);
      }
    });
  }
  return httpCall;
};

const postFile = (uri, payload = {}) =>
  fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'POST',
      body: payload,
    }),
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

const put = (uri, payload = {}) =>
  fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(payload),
    }),
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

const patch = (uri, payload = {}) =>
  fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(payload),
    }),
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

const destroy = (uri, payload = {}) =>
  fetch(
    `${API_BASE}${uri}`,
    headers({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(payload),
    }),
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

// User
export const retrieveMe = () => get('/users/me');
export const getUsers = () => get('/users');
export const updateProfile = (id, payload) => put(`/users/${id}`, payload);
export const patchUserClusterInfos = (userID, clusterID, role, certiphyto_number) =>
  patch(`/clusters/${clusterID}/users/${userID}`, { role, certiphyto_number });
export const updateOptin = (id, payload) => patch(`/users/${id}/optins`, payload);
export const updatePassword = (id, payload) => put(`/users/${id}/update_password`, payload);
export const getUsersOfCluster = (cluster_id) => get(`/clusters/${cluster_id}/users`);
export const getInvoices = () => get(`/invoices`);
export const linkUserToClusterByMail = ({
  email,
  clusterID,
  role = undefined,
  firstname = undefined,
  lastname = undefined,
  phonenumber = undefined,
  certiphyto_number = undefined,
}) =>
  post(`/clusters/${clusterID}/users`, {
    mail: email,
    role,
    firstname,
    lastname,
    phonenumber,
    certiphyto_number,
  });

// Parcels
export const getParcels = () => get('/parcels');
export const createParcel = (payload) => post('/parcels', payload);
export const getParcel = (id) => get(`/parcels/${id}`);
export const editParcel = (id, payload) => put(`/parcels/${id}`, payload);
export const patchParcel = (id, payload) => patch(`/parcels/${id}`, payload);
export const deleteParcel = (id) => destroy(`/parcels/${id}`);
export const deleteMultiParcels = (ids) => destroy(`/parcels?${ids.map((id) => `parcel_id=${id}`).join('&')}`);
export const importParcels = (cluster_id, file, format, projectionName, projectionID, fromDate, type) => {
  let url = `/clusters/${cluster_id}/import_parcels?format=${format}`;
  if (projectionName !== '' && projectionName !== null) {
    url += `&projection_name=${projectionName}`;
  }
  if (projectionID !== '' && projectionID !== null) {
    url += `&projection_id=${projectionID}`;
  }
  if (fromDate && fromDate !== '') {
    url += `&from_date=${encodeURIComponent(fromDate)}`;
  }
  if (type && type !== '') {
    url += `&type=${type}`;
  }
  return postFile(url, file);
};
export const postGfrParcels = (gfrParcelIds, gfrParcelNames, clusterId, fromDate) =>
  post('/parcels/gfr', {
    gfr_parcel_ids: gfrParcelIds,
    gfr_parcel_names: gfrParcelNames,
    cluster_id: clusterId,
    from_date: fromDate,
  });
export const getParcelsInIntersectionWithEquipmentSession = (equipmentSessionId) =>
  get(`/equipment_sessions/${equipmentSessionId}/intersected_parcels`);
export const getParcelHistory = (id) => get(`/parcels/${id}/history`);
export const createParcelRent = (id, payload) => post(`/parcels/${id}/create_rent`, payload);
export const createMultiParcelRent = (ids, payload) =>
  post(`/parcels/create_rent?${ids.map((id) => `parcel_id=${id}`).join('&')}`, payload);
export const updateClusterParcel = (id, payload) => put(`/cluster_parcels/${id}`, payload);
export const deleteClusterParcel = (id) => destroy(`/cluster_parcels/${id}`);
export const closeCurrentRentParcel = (id, closingDate) => {
  const params = new URLSearchParams();
  if (closingDate !== null) {
    params.append('closing_date', closingDate);
  }
  return destroy(`/parcels/${id}/stop_current_rent?${params.toString()}`);
};
export const closeManyCurrentRentParcel = (parcelIds, closingDate) => {
  const params = new URLSearchParams();
  parcelIds && parcelIds.forEach((id) => params.append('parcel_id', id));
  if (closingDate !== null) {
    params.append('closing_date', closingDate);
  }
  return destroy(`/parcels/stop_current_rent?${params.toString()}`);
};
export const getIntersectedParcels = (clusterId, payload, signal = null) =>
  post(`/intersected_parcels?cluster_id=${clusterId}`, { ...payload }, true, signal);
export const getParcelLayers = (bounds) => {
  const url = new URL(`${LAYER_API_BASE}/datasets`);
  url.searchParams.set('min_latitude', bounds.minLatitude ?? 0);
  url.searchParams.set('max_latitude', bounds.maxLatitude ?? 0);
  url.searchParams.set('min_longitude', bounds.minLongitude ?? 0);
  url.searchParams.set('max_longitude', bounds.maxLongitude ?? 0);

  return fetch(url.toString()).then((d) => d.json());
};
export const getAnonymousParcelsByIds = (parcelIds, signal) => {
  if (!Array.isArray(parcelIds) || parcelIds.length === 0) {
    return Promise.resolve([]);
  }
  const params = new URLSearchParams();
  parcelIds.forEach((id) => params.append('id', id));
  return get(`/anonymous_parcels?${params.toString()}`, signal);
};

// crops
export const getCrops = () => get('/crops');
export const createCrop = (payload) => post('/crops', payload);
export const updateCrop = (id, payload) => put(`/crops/${id}`, payload);
export const deleteCrop = (id) => destroy(`/crops/${id}`);
export const linkParcelWithCrop = (id, payload) => post(`/parcels/${id}/crop_history`, payload);
export const linkMultiParcelWithCrop = (ids, payload) => {
  const params = new URLSearchParams();
  for (const id of ids) {
    params.append('parcel_id', id);
  }
  return post(`/parcels/crop_history?${params.toString()}`, payload);
};
export const getParcelCropHistory = (id) => get(`/parcels/${id}/crop_history`);
export const deleteParcelCropHistory = (parcelID, parcelCropRangeID) =>
  destroy(`/parcels/${parcelID}/crop_history/${parcelCropRangeID}`);
export const updateParcelCropHistory = (parcelID, parcelCropRangeID, payload) =>
  put(`/parcels/${parcelID}/crop_history/${parcelCropRangeID}`, payload);

// clusters
export const getClusters = () => get('/clusters');
export const getClusterByID = (clusterID) => get(`/clusters/${clusterID}`);
export const createCluster = (cluster) => post('/clusters', cluster);
export const updateCluster = (cluster) => put(`/clusters/${cluster.id}`, cluster);
export const linkCluster = (parentId, childId) => post(`/clusters/${parentId}/hierarchy/${childId}`, {});
export const unlinkCluster = (parentId, childId) => destroy(`/clusters/${parentId}/hierarchy/${childId}`);
export const unlinkUser = (clusterID, userID) => destroy(`/clusters/${clusterID}/users/${userID}`);
export const getClusterHierarchy = (cluster_id) => get(`/clusters/${cluster_id}/hierarchy`);
export const getClustersPartners = () => get('/clusters/partners');
export const getClusterImage = (clusterId) =>
  fetch(
    `${API_BASE}/clusters/${clusterId}/image`,
    headers({
      method: 'GET',
      headers: {},
    }),
  )
    .then((response) => {
      if (response.status > 199 && response.status < 308) {
        return response.blob();
      }
      if (response.status === 404) {
        return Promise.resolve(response);
      }
      return Promise.resolve(null);
    })
    .then((blob) => {
      if (!blob) return Promise.resolve([]);
      if (blob.status === 404) return Promise.resolve([]);
      return new Promise((res) => {
        const reader = new FileReader();
        reader.onload = function () {
          const file = new File([blob], `${clusterId}_image`);
          res([{ src: this.result, file }]);
        };
        reader.readAsDataURL(blob);
      });
    });

export const updateClusterImage = (clusterId, image) => {
  if (!image) return Promise.reject('no file provided');
  const data = new FormData();
  data.append('image', image.file);
  data.append('title', `${clusterId} logo`);
  return fetch(
    `${API_BASE}/clusters/${clusterId}/image`,
    headers({
      method: 'put',
      body: data,
    }),
  );
};

export const deleteClusterImage = (clusterId) => destroy(`/clusters/${clusterId}/image`);

function determineFetcher(format) {
  switch (format) {
    case 'xlsx':
      return getXlsx;
    case 'csv':
      return getCsv;
    default:
      return get;
  }
}
export const getClusterChildren = (cluster_id, format) => {
  const fetcher = determineFetcher(format);
  return fetcher(`/clusters/${cluster_id}/members${format ? `?format=${format}` : ''}`);
};
export const getClusterUsers = (cluster_id, format) => {
  const fetcher = determineFetcher(format);
  return fetcher(`/clusters/${cluster_id}/users${format ? `?format=${format}` : ''}`);
};

function getReport(entity, signal) {
  let url = `/reports/${entity}s?${entity}ID=`;
  return (id) => (start) => (end) => (params) => {
    url += `${id}&fromDate=${encodeURIComponent(start)}&toDate=${encodeURIComponent(end)}${params || ''}`;
    return (fetcher) => fetcher(url, signal);
  };
}

function getReportFetcher(detailed, format, withRelated = false) {
  let additionalParams = `&withRelated=${withRelated}&withDetails=${detailed}&timeZone=${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
  let fetcher;

  if (format === 'xlsx') {
    additionalParams += '&format=xlsx';
    fetcher = getXlsx;
  } else if (format === 'csv') {
    additionalParams += '&format=csv';
    fetcher = getCsv;
  } else {
    fetcher = get;
  }

  return (url, signal) => fetcher(`${url}${additionalParams}`, signal);
}

// Equipments
export const getEquipments = () => get('/equipment');
export const getEquipment = (id) => get(`/equipment/${id}`);
export const createEquipment = (payload) => post('/equipment', payload);
export const deleteEquipment = (id) => destroy(`/equipment/${id}`);
export const editEquipment = (id, payload) => put(`/equipment/${id}`, payload);
export const getEquipmentSessions = ({ id, start, end, format, params, signal }) =>
  getReport('equipment', signal)(id)(start)(end)(params)(getReportFetcher(true, format, true));
export const getConsolidatedEquipmentSessions = ({ id, start, end, format, params, signal }) =>
  getReport('equipment', signal)(id)(start)(end)(params)(getReportFetcher(false, format, true));
export const getEquipmentModels = () => get('/equipment_models');

// Sessions
export const getConsolidatedParcelSessions = ({ id, start, end, format, params, signal }) =>
  getReport('parcel', signal)(id)(start)(end)(params)(getReportFetcher(false, format));
export const updateClusterIdForSession = (sessionId, clusterId) =>
  patch(`/equipment-session/${sessionId}`, { cluster_id: clusterId });
export const unfreezeSession = (sessionId) => patch(`/reports/equipments/${sessionId}/unfreeze`);
export const freezeSession = (sessionId, status = null) =>
  patch(`/reports/equipments/${sessionId}/freeze`, status ? { processing_status: status } : null);
export const freezeSessions = (sessionIds, status) =>
  patch('/equipment_sessions/multi', { processing_status: status, equipment_session_ids: sessionIds });
export const getEquipmentSessionWorkedArea = (sessionId) => get(`/reports/equipments/${sessionId}/worked_area`);
export const getParcelSessionWorkedArea = (sessionId) => get(`/reports/parcels/${sessionId}/worked_area`);

// Parcel worksites
export const getParcelWorksitesReportsExport = (format, params) => {
  const getter = format === 'csv' ? getCsv : getXlsx;
  return getter(
    `/parcel_worksites?${params}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&format=${format}`,
  );
};
export const getParcelsWorksites = (params, signal) =>
  get(`/parcel_worksites?${params.toString()}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, signal);
export const getParcelsWorksitesTracks = (params, signal) =>
  get(`/parcel_worksites?${params}&format=metadata_geojson`, signal);
export const getParcelWorksites = ({ id, start, end, format, params, signal }) => {
  let fetcher = get;
  let formatParam = '';
  if (format === 'xlsx') {
    fetcher = getXlsx;
    formatParam = '&format=xlsx';
  }
  if (format === 'csv') {
    fetcher = getCsv;
    formatParam = '&format=csv';
  }
  return fetcher(
    `/parcels/${id}/worksites?from_date=${encodeURIComponent(start)}&to_date=${encodeURIComponent(end)}${
      params || ''
    }&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}${formatParam}`,
    signal,
  );
};

export const getParcelWorksitesTracks = ({ id, start, end, params, signal }) =>
  get(
    `/parcels/${id}/worksites?from_date=${encodeURIComponent(start)}&to_date=${encodeURIComponent(end)}${
      params || ''
    }&format=metadata_geojson`,
    signal,
  );

// worksites
export const getWorksites = (params, signal) => get(`/worksites?${params}`, signal);
export const getWorksitesReportsExport = (format, params) => {
  const getter = format === 'csv' ? getCsv : getXlsx;
  return getter(`/worksites?${params}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&format=${format}`);
};

export const shareEquipmentSession = (sessionId, endDate, completeMode) =>
  post(`/reports/equipments/${sessionId}/share`, {
    share_expiration: endDate,
    share_full: completeMode,
  });
export const shareEquipmentSessionByMail = (id, emails) =>
  post(`/reports/equipments/${id}/share/invite`, {
    emails,
  });
export const getSharedSession = (uuid) => get(`/share?id=${uuid}`);
export const unShareEquipmentSession = (sessionId) => destroy(`/reports/equipments/${sessionId}/share`);

export const getEquipmentSession = (id) => get(`/reports/equipments/${id}?withRelated=true`);
export const getEquipmentSessionPoints = (id) => get(`/reports/equipments/${id}/points`);
export const getParcelSession = (id) => get(`/reports/parcels/${id}`);

// Devices
export const getDeviceHistory = (id) => get(`/devices/${id}/equipment_history`);
export const getDevices = () => get('/devices?withoutTracks=true');
export const getDevice = (id) => get(`/devices/${id}`);
export const getDeviceSessionPoints = (id) => get(`/device_sessions/${id}/event_points`);
export const attachDeviceEquipment = (deviceId, payload) => post(`/devices/${deviceId}/link_equipment`, payload);
export const detachDeviceEquipment = (deviceId) => destroy(`/devices/${deviceId}/unlink`);
export const editDevice = (id, payload) => put(`/devices/${id}`, payload);
export const updateEquipmentHasDevice = (id, payload) => put(`/equipment_has_devices/${id}`, payload);
export const deleteEquipmentHasDevice = (id) => destroy(`/equipment_has_devices/${id}`);

// Beacons
export const getBeacons = () => get('/beacons');
export const getBeacon = (id) => get(`/beacons/${id}`);
export const getBeaconEquipmentHistory = (id) => get(`/beacons/${id}/equipment_history`);
export const getBeaconDriverHistory = (id) => get(`/beacons/${id}/beacon_users`);
export const linkBeaconToEquipment = (beaconID, payload) => post(`/beacons/${beaconID}/link_equipment`, payload);
export const linkBeaconToDriver = (beaconID, payload) => post(`/beacons/${beaconID}/link_user`, payload);
export const unlinkDriverBeacon = (id) => destroy(`/beacons/${id}/unlink_user`);
export const updateDriverBeaconEntry = (id, payload) => put(`/beacon_users/${id}`, payload);
export const deleteDriverBeaconEntry = (id) => destroy(`/beacon_users/${id}`);

// Reports
export const getEquipmentsReports = (params, signal) => get(`/reports/equipments?${params}`, signal);
export const getParcelsReports = (params, signal) => get(`/reports/parcels?${params}`, signal);
export const getDriversReports = (params, signal) => get(`/reports/users?${params}`, signal);
export const getEquipmentsTracks = (params = '', signal) =>
  get(`/reports/equipments?${params || ''}&format=metadata_geojson`, signal);
export const getParcelsTracks = (params = '', signal) =>
  get(`/reports/parcels?${params || ''}&format=metadata_geojson`, signal);

export const getEquipmentsReportsExport = (format, params) => {
  const getter = format === 'csv' ? getCsv : getXlsx;
  return getter(
    `/reports/equipments?${params}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&format=${format}`,
  );
};
export const getParcelsReportsExport = (format, params) => {
  const getter = format === 'csv' ? getCsv : getXlsx;
  return getter(
    `/reports/parcels?${params}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&format=${format}`,
  );
};
export const getDriversReportsExport = (format, params) => {
  const getter = format === 'csv' ? getCsv : getXlsx;
  return getter(
    `/reports/users?${params}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&format=${format}`,
  );
};
export const getEquipmentsReportsExportDetailed = (format, params) => {
  const getter = format === 'csv' ? getCsv : getXlsx;
  return getter(
    `/reports/equipments?${params}&format=${format}&withDetails=true&withRelated=true&timeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
  );
};

// Observations
export const getObservations = (params = '') => get(`/observations${params}&withDetails=true`);
export const addObservation = (payload) => post('/observations', payload);
export const updateObservation = (id, payload) => patch(`/observations/${id}`, payload);
export const deleteObservation = (observation) => destroy(`/observations/${observation.id}`);
export const getEquipmentObservations = (equipmentId, fromDate, toDate) =>
  get(
    `/equipment/${equipmentId}/observations?from_date=${encodeURIComponent(fromDate)}&to_date=${encodeURIComponent(
      toDate,
    )}&status=AVAILABLE&withDetails=true`,
  );
export const getEquipmentSessionObservations = (equipmentSessionId) =>
  get(`/equipment_sessions/${equipmentSessionId}/observations?status=AVAILABLE&withDetails=true`);
export const getParcelObservations = (parcelId, startDate, endDate) => {
  const params = removeEmptyParams(
    new URLSearchParams({
      status: 'AVAILABLE',
      withDetails: true,
      from_date: startDate,
      to_date: endDate,
    }),
  );
  return get(`/parcels/${parcelId}/observations?${params.toString()}`);
};
export const getObservationsWithParams = (params, signal) => get(`/observations?${params}&withDetails=true&`, signal);
export const getObservationsExport = (format, params) =>
  getXlsx(
    `/observations?${params}&withDetails=true&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&format=xlsx`,
  );
export const getObservationCategories = () => get(`/observation_categories`);
export const createObservationCategory = (obsCat) => post(`/observation_categories`, obsCat);
export const updateObservationCategory = (id, payload) => put(`/observation_categories/${id}`, payload);
export const deleteObservationCategory = (id) => destroy(`/observation_categories/${id}`);
export const archiveObservation = (obsID) => put(`/observations/archive/${obsID}`);
export const restoreObservation = (obsID) => put(`/observations/restore/${obsID}`);
export const restoreObservations = (obsIDs) =>
  put(`/observations/restore`, {
    observation_ids: obsIDs,
  });
export const archiveObservations = (obsIDs) =>
  put(`/observations/archive`, {
    observation_ids: obsIDs,
  });
export const deleteObservations = (obsIDs) =>
  destroy(`/observations/delete`, {
    observation_ids: obsIDs,
  });

// Binaries
export const postFilesToObservation = (observationId, files) => {
  if (!files) return Promise.reject('no file provided');
  const data = new FormData();
  files.forEach((f, index) => {
    data.append('files', f);
    data.append('titles', f.name || `${observationId} image ${index}`);
  });
  return fetch(
    `${API_BASE}/observations/${observationId}/binaries`,
    headers({
      method: 'post',
      body: data,
    }),
  ).then(handleResponse);
};

export const getBinary = (binaryId, params) =>
  fetch(
    `${API_BASE}${`/binaries/${binaryId}/image?${params.w ? `w=${params.w}&` : ''}${params.h ? `h=${params.h}` : ''}`}`,
    headers({
      method: 'GET',
      headers: {},
    }),
  );

export const removeBinary = (binaryId) =>
  fetch(
    `${API_BASE}/binaries/${binaryId}`,
    headers({
      method: 'DELETE',
      headers: {},
    }),
  );

// Unit locations
export const getParcelSessionUnitLocations = (parcelSessionId) =>
  get(`/reports/parcels/${parcelSessionId}/unit_locations`);
export const getEquipmentSessionUnitLocations = (equipmentSessionId) =>
  get(`/reports/equipments/${equipmentSessionId}/unit_locations`);

// PARTNER
export const getLoginURL = (partnerName) => {
  if (partnerName === 'smag') {
    return get('/smag/login_url');
  } else {
    return Promise.resolve({ login_url: '/settings/mesparcelles/login' });
  }
};
export const hasPartnerToken = (partnerName = 'smag') => get(`/${partnerName}/has_token`, null, false);
export const postPartnerToken = (partnerName = 'smag', payload) => post(`/${partnerName}/token`, payload);
export const partnerFarmsSync = (partnerName = 'smag', crop_year = null) =>
  post(`/${partnerName}/farms/sync?${crop_year ? 'year=' + crop_year : ''}`);
export const getSyncedPartnerFarms = (partnerName = 'smag') => get(`/${partnerName}/farms`);
export const updatePartnerFarm = (partnerName = 'smag', { id, karnottClusterID }) =>
  patch(`/${partnerName}/farms/${id}`, { karnott_cluster_id: karnottClusterID });
export const partnerFieldsSync = (partnerName = 'smag', crop_year = null) =>
  post(`/${partnerName}/fields/sync?${crop_year ? 'year=' + crop_year : ''}`);
export const updatePartnerField = (partnerName = 'smag', { id, karnottParcelID }) =>
  patch(`/${partnerName}/fields/${id}`, { karnott_parcel_id: karnottParcelID });
export const getPartnerFields = (partnerName = 'smag', crop_year = null) => {
  return get(`/${partnerName}/fields?${crop_year ? 'year=' + crop_year : ''}`);
};
export const createPartnerWorkRecord = ({ equipmentSessionId, year }) =>
  post(`/smag/equipment_sessions/${equipmentSessionId}/create_work_record?crop_year=${year}`, null, false);

// device session
export const getForcedOrIgnoredParcelsOfDeviceSession = (id) => get(`/device_sessions/${id}/forced_ignored_fields`);
export const addParcelsToDeviceSession = (id, parcelIDs, disableResetSession) =>
  post(`/device_sessions/${id}/force_fields?${disableResetSession ? 'disable_reset_session=true' : ''}`, parcelIDs);
export const ignoreParcelsToDeviceSession = (id, parcelIDs, disableResetSession) =>
  post(`/device_sessions/${id}/ignore_fields?${disableResetSession ? 'disable_reset_session=true' : ''}`, parcelIDs);

// tracking
export const karnottTrackingEvent = (event) => post('/tracking', event);

// Phyto
export const getPhytoBasicProducts = (params, signal) => get(`/phyto/products?name=${params}`, signal);
export const getPhytoFavoriteProducts = (clusterID, signal) =>
  get(`/phyto/products?favorite_cluster_id=${clusterID}`, signal);
export const createPhytoFavoriteProduct = (id, clusterId) => post(`/phyto/products/${id}/clusters/${clusterId}`);
export const deletePhytoFavoriteProduct = (id, clusterId) => destroy(`/phyto/products/${id}/clusters/${clusterId}`);

export const createCustomPhytoProduct = (payload) => post(`/phyto/products`, payload);
export const updateCustomPhytoProduct = (productId, payload) => patch(`/phyto/products/${productId}`, payload);
export const deleteCustomPhytoProduct = (productId) => destroy(`/phyto/products/${productId}`);
export const getCustomProductUnits = () => get('/phyto/product_units');

export const getPhytoMixtures = (clusterId, signal) =>
  get(`/phyto/mixtures${clusterId ? `?cluster_id=${clusterId}` : ''}`, signal);
export const createPhytoMixture = (payload) => post('/phyto/mixtures', payload);
export const updatePhytoMixture = (id, payload) => patch(`/phyto/mixtures/${id}`, payload);
export const updatePhytoMixtureProduct = (mixtureId, productUsageId, payload) =>
  put(`/phyto/mixtures/${mixtureId}/usages/${productUsageId}`, payload);
export const deletePhytoMixtureProduct = (mixtureId, productUsageId) =>
  destroy(`/phyto/mixtures/${mixtureId}/usages/${productUsageId}`);
export const addPhytoMixtureProduct = (mixtureId, payload) => post(`/phyto/mixtures/${mixtureId}/usages`, payload);
export const validatePhytoMixture = (payload) => post('/phyto/mixtures/validation', payload);
export const getPhytoReport = (params, signal) => get(`/phyto_register?${params.toString()}`, signal);
export const getPhytoReportFile = (params, signal) =>
  getAndOpen(`/phyto_register?force_generation=true&format=pdf&${params.toString()}`, signal);

export const getIftReports = (params) => get(`/ift_bilans${params ? `?${params.toString()}` : ''}`);
export const requestIftReport = (clusterId, params) =>
  post(`/clusters/${clusterId}/ift_bilan_request?${params.toString()}`);
export const getIftReportDownload = (iftId) => getAndOpen(`/ift_bilans/${iftId}.pdf`);

// Tasks
export const getTasks = (params, signal) => {
  const cleanParams = removeEmptyParams(new URLSearchParams(params));
  return get(`/tasks?${cleanParams.toString()}`, signal);
};
export const getTask = (id) => get(`/tasks/${id}`);
export const createTask = (payload) => post('/tasks', payload);
export const patchTask = (id, payload) => put(`/tasks/${id}`, payload);
export const deleteTask = (id) => destroy(`/tasks/${id}`);

export const addParcelToTask = (taskId, parcelId, payload) => post(`/tasks/${taskId}/parcels/${parcelId}`, payload);
export const patchParcelInTask = (taskId, parcelId, payload) => put(`/tasks/${taskId}/parcels/${parcelId}`, payload);
export const removeParcelFromTask = (taskId, parcelId) => destroy(`/tasks/${taskId}/parcels/${parcelId}`);

export const postProductUsageInTask = (taskId, productUsageId, payload) =>
  post(`/tasks/${taskId}/product_usages/${productUsageId}`, payload);

export const simulateTaskUpdate = (payload) => post(`/tasks/simulate`, payload);

export const addTaskMetadataResponses = (taskId, responses) => post(`/tasks/${taskId}/responses`, responses);

export const getTaskFile = (id) =>
  getAndOpen(
    `/tasks/${id}/pdf?${new URLSearchParams({
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      force_generation: true,
    }).toString()}`,
  );
export const sendPDFTaskFile = (id) =>
  post(
    `/tasks/${id}/send_pdf?${new URLSearchParams({
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).toString()}`,
  );

/** @param {import('../constants/taskConstants').TaskType} type */
export const getTaskMetadata = (type = TASK_TYPE.PHYTO) => {
  const params = new URLSearchParams({ task_type: type });
  return get(`/task_metadata?${params.toString()}`);
};

// Tags
export const getTags = () => get('/tags');
export const createTag = (label, color, clusterId) => post('/tags', { label, color, cluster_id: clusterId });
export const updateTag = (id, payload) => patch(`/tags/${id}`, payload);
export const deleteTag = (id) => destroy(`/tags/${id}`);

export const linkTagToParcel = (parcelId, tagId) => post(`/parcels/${parcelId}/tags`, { tag_id: tagId });
export const unlinkTagFromParcel = (parcelId, tagId) => destroy(`/parcels/${parcelId}/tags/${tagId}`);
export const linkManyTagsToParcels = (parcelsAndTags) => post('/parcels/tags/multi', parcelsAndTags);
export const unlinkManyTagsFromParcels = (parcelsAndTags) => destroy('/parcels/tags/multi', parcelsAndTags);

export const linkTagToEquipment = (equipmentId, tagId) => post(`/equipments/${equipmentId}/tags`, { tag_id: tagId });
export const unlinkTagFromEquipment = (equipmentId, tagId) => destroy(`/equipments/${equipmentId}/tags/${tagId}`);

export const linkTagToUser = (userId, tagId) => post(`/users/${userId}/tags`, { tag_id: tagId });
export const unlinkTagFromUser = (userId, tagId) => destroy(`/users/${userId}/tags/${tagId}`);

export const linkTagToDevice = (deviceId, tagId) => post(`/devices/${deviceId}/tags`, { tag_id: tagId });
export const unlinkTagFromDevice = (deviceId, tagId) => destroy(`/devices/${deviceId}/tags/${tagId}`);

export const linkTagToBeacon = (beaconId, tagId) => post(`/beacons/${beaconId}/tags`, { tag_id: tagId });
export const unlinkTagFromBeacon = (beaconId, tagId) => destroy(`/beacons/${beaconId}/tags/${tagId}`);

// Hubspot
export const askPhytoUpsell = () => post('/hubspot/create_phyto_opportunity');

// Articles
export const getArticles = () => get('/articles');
export const addArticle = (article) => post('/articles', article);
export const updateArticle = (article) => patch(`/articles/${article.id}`, article);
export const deleteArticle = (articleId) => destroy(`/articles/${articleId}`);
export const addEquipmentsToArticle = (articleId, equipmentIds) =>
  post(`/articles/${articleId}/add-equipment`, equipmentIds);
export const removeEquipmentsFromArticle = (articleId, equipmentIds) =>
  destroy(`/articles/${articleId}/remove-equipment`, equipmentIds);
