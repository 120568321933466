export const OBSERVATION_CONSTANTS = {
  TYPE: {
    EQUIPMENT: 'equipment_instance',
    PARCEL: 'parcel',
    SESSION: 'equipment_session',
  },
  STATUS: {
    AVAILABLE: 'AVAILABLE',
    ARCHIVED: 'ARCHIVED',
  },
} as const;
