import { ComponentProps, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';
import { InfoTooltip, Tooltip } from '@karnott/tooltip';

const Container = styled.div<{
  error?: string;
}>`
  position: relative;
  width: 100%;
  flex: 1;

  .karnott-datepicker button,
  .karnott-datepicker {
    max-width: unset;
  }

  ${({ error }) =>
    error
      ? css`
          ::after {
            content: '${error}';
            position: absolute;
            margin: 0;
            margin-top: 2px;
            color: ${colors('red')};
            font-size: ${pixelSize('small')};
            font-family: ${fontFamily()};
            line-height: 1;
          }
        `
      : null}
`;

const Label = styled.label<{
  withMargin: boolean;
}>`
  border: none;
  background: none;
  font-family: inherit;
  padding: 0;
  margin: 0;
  margin-bottom: ${({ withMargin }) => pixelSpacing(withMargin ? 'small' : 'xSmall')};
  color: ${colors('black', 400)};
  font-size: ${pixelSize('regular')};
  display: flex;
  gap: ${pixelSpacing('xSmall')};
  align-items: center;
`;

const Asterisk = styled.span`
  color: ${colors('red')};
`;

type FieldProps = {
  /** The form field to use */
  children: ReactNode;
  /** The label of the field */
  label?: string;
  /** The id of the wrapped field, for the label */
  htmlFor?: string;
  /** The id of the container, for testing */
  id?: string;
  /** An error message to display */
  error?: string;
  /** Whether the field is required to submit the form (displays a red asterisk after the label) */
  required?: boolean;
  /**
   * A tooltip message or an object describing the `message`, the `orientation` and the wrapping (`noWrap`) of an
   * informative tooltip after the label
   */
  tooltip?: string | Pick<ComponentProps<typeof Tooltip>, 'message' | 'noWrap' | 'orientation'>;
  /** Whether to add spacing below the label */
  addLabelSpacing?: boolean;
};

/** A form field wrapper to create consistent form interfaces */
export function Field({
  children,
  label,
  required = false,
  tooltip,
  error,
  htmlFor,
  id,
  addLabelSpacing = false,
}: FieldProps) {
  return (
    <Container id={id} error={error}>
      {label ? (
        <Label htmlFor={htmlFor} withMargin={addLabelSpacing}>
          <span>
            {label}
            {required ? <Asterisk>*</Asterisk> : null}
          </span>
          {tooltip ? (
            <InfoTooltip
              message={typeof tooltip === 'string' ? tooltip : undefined}
              {...(typeof tooltip !== 'string' ? tooltip : undefined)}
            />
          ) : null}
        </Label>
      ) : null}

      {children}
    </Container>
  );
}
