import { useContext, useEffect, useRef } from 'react';
import round from 'lodash-es/round';
import removeAccents from 'remove-accents';
import { FormHooks } from '@karnott/form';
import { areIDsEquals } from '.';
import { useDebounce } from '../components/map/effects';
import { USER_CONSTANTS } from '../constants';
import { DEVICE_CONSTANTS } from '../constants/deviceConstants';
import {
  BEACON_TOGGLE_TYPE,
  KARNOTT_TOGGLE_TYPE,
  PARCELS_TOGGLE_TYPE,
  UNDEFINED_CROP_ID,
  filterToggleTypes,
} from '../constants/filtersConstants';
import { PARCELS_CONSTANTS } from '../constants/parcelsConstants';
import { UNDEFINED_TAG_ID } from '../constants/tagConstants';
import { I18nContext } from '../contexts/I18nProvider';
import { getBatteryLevel } from './devices';
import { isEquipmentArchived } from './equipments';

function getIsMatchingTags(entityTags, filterTags) {
  return filterTags?.length
    ? !!(entityTags?.length
        ? filterTags.find((tagFilter) => entityTags.find((tag) => tag.id === tagFilter.id))
        : filterTags.find((tag) => tag.id === UNDEFINED_TAG_ID))
    : true;
}

export const filterEquipments = (entities, filters) => {
  const typesFilters =
    (filters.types &&
      filters.types.items &&
      filters.types.items.filter((type) => type.checked).map((checkedType) => checkedType.type)) ||
    [];
  const sliderForWidth = filters.sliders && filters.sliders.items && filters.sliders.items[0];

  const toggleItems = filters?.toggles?.items || [];
  const toggleInUseEquipments = toggleItems.find((item) => item?.type === filterToggleTypes.IN_USE_EQUIPMENTS);
  const toggleAvailableEquipments = toggleItems.find((item) => item?.type === filterToggleTypes.AVAILABLE_EQUIPMENTS);
  const toggleArchivedEquipments = toggleItems.find((item) => item?.type === filterToggleTypes.ARCHIVED);

  const selectedTags = filters.select?.selected;

  return entities.filter((entity) => {
    // todo remove this quickfix when equipment models are dry
    const theType = entity && entity.equipmentModel && entity.equipmentModel.type;
    const isMatchingTypes = !typesFilters.length || typesFilters.indexOf(theType) !== -1;
    const isMatchingSliders = entity.width >= sliderForWidth.minValue && entity.width <= sliderForWidth.maxValue;
    const isMatchingTags = getIsMatchingTags(entity.tags, selectedTags);
    const isStatusValidated =
      (entity.status === DEVICE_CONSTANTS.STATUS.AVAILABLE && toggleAvailableEquipments.checked) ||
      (entity.status === DEVICE_CONSTANTS.STATUS.IN_USE && toggleInUseEquipments.checked) ||
      (isEquipmentArchived(entity) && toggleArchivedEquipments.checked);

    return isMatchingTypes && isMatchingSliders && isMatchingTags && isStatusValidated && !entity.hidden;
  });
};

export const filterParcels = (entities, filters) => {
  const sliderForArea = filters?.sliders?.items[0];

  const toggleItems = filters?.toggles?.items || [];
  const toggleFieldParcels = toggleItems.find((item) => item?.type === PARCELS_TOGGLE_TYPE.FIELD);
  const toggleBeingRentParcels = toggleItems.find((item) => item?.type === PARCELS_TOGGLE_TYPE.RENT);
  const toggleRentOverParcels = toggleItems.find((item) => item?.type === PARCELS_TOGGLE_TYPE.RENT_OVER);
  const toggleInactiveParcels = toggleItems.find((item) => item?.type === PARCELS_TOGGLE_TYPE.INACTIVE);
  const toggleFarmhouseParcels = toggleItems.find((item) => item?.type === PARCELS_TOGGLE_TYPE.FARMHOUSE);

  const crops = filters?.checkboxes?.items || [];
  const clusters = filters?.checkboxesClusters?.items || [];
  const checkedCrops = (filters?.checkboxes?.items || []).filter((i) => i.checked).map((i) => i.id);
  const checkedClusters = (filters?.checkboxesClusters?.items || []).filter((i) => i.checked).map((i) => i.id);

  return entities.filter((entity) => {
    let isOK = false;

    const isActive =
      entity.category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE &&
      (entity.status === PARCELS_CONSTANTS.STATUS.ACTIVE ||
        entity.status === PARCELS_CONSTANTS.STATUS.BEING_RENT_ACTIVE);
    const isRent =
      entity.status === PARCELS_CONSTANTS.STATUS.BEING_RENT ||
      entity.status === PARCELS_CONSTANTS.STATUS.BEING_RENT_ACTIVE;
    const isRentOver = entity.status === PARCELS_CONSTANTS.STATUS.RENT_OVER;
    const isInactive = entity.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE;

    const conditionMap = [];
    if (toggleFarmhouseParcels?.checked)
      conditionMap.push([toggleFarmhouseParcels.checked, entity.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE]);
    if (toggleBeingRentParcels?.checked) conditionMap.push([toggleBeingRentParcels.checked, isRent]);
    if (toggleFieldParcels?.checked) conditionMap.push([toggleFieldParcels.checked, isActive]);
    if (toggleRentOverParcels?.checked) conditionMap.push([toggleRentOverParcels.checked, isRentOver]);
    if (toggleInactiveParcels?.checked) conditionMap.push([toggleInactiveParcels.checked, isInactive]);
    if (!conditionMap?.length) isOK = true;
    for (let i in conditionMap) {
      const [checked, condition] = conditionMap[i];
      isOK = isOK || (checked && condition);
    }

    let cropOK = crops?.length === checkedCrops?.length;
    if (!cropOK) {
      cropOK = entity.current_crop_id
        ? checkedCrops.indexOf(entity.current_crop_id) !== -1
        : checkedCrops.indexOf(UNDEFINED_CROP_ID) !== -1;
    }
    let clusterOK = clusters?.length === checkedClusters?.length;
    if (!clusterOK) {
      clusterOK = checkedClusters.indexOf(entity.current_tenant_cluster_id) !== -1;
    }

    const sliderMinExpression = !sliderForArea || round(entity.area / 10000, 2) >= sliderForArea.minValue;
    const sliderMaxExpression = !sliderForArea || round(entity.area / 10000, 2) <= sliderForArea.maxValue;

    return sliderMinExpression && sliderMaxExpression && isOK && !entity.hidden && cropOK && clusterOK;
  });
};

export const filterObservations = (entities, filters) => {
  const toggleObservations = (filters?.toggles?.items || []).find(
    (item) => item?.type === filterToggleTypes.OBSERVATIONS,
  );
  if (toggleObservations.checked) {
    return entities;
  } else {
    return [];
  }
};

export const filterKarnott = (entities, filters) => {
  const toggleItems = filters?.toggles?.items || [];
  const toggleKarnottNotInUse = toggleItems.find((item) => item?.type === KARNOTT_TOGGLE_TYPE.NOT_IN_USE);
  const toggleKarnottInUse = toggleItems.find((item) => item?.type === KARNOTT_TOGGLE_TYPE.IN_USE);
  const toggleAffectedKarnott = toggleItems.find((item) => item?.type === KARNOTT_TOGGLE_TYPE.AFFECTED);
  const toggleAvailableKarnott = toggleItems.find((item) => item?.type === KARNOTT_TOGGLE_TYPE.AVAILABLE);
  const toggleLowBattery = toggleItems.find((item) => item?.type === KARNOTT_TOGGLE_TYPE.LOW_BATTERY);
  const toggleHighBattery = toggleItems.find((item) => item?.type === KARNOTT_TOGGLE_TYPE.BATTERY);

  const selectedTags = filters.select?.selected;

  const badHealthKarnotts = entities.filter((k) => k.health.status !== 'UP');

  return entities
    .filter((entity) => {
      // (IF karnott not in use AND not in use checked THEN true) OR (IF karnott in use AND in use checked THEN true)
      // same principle for the following conditions
      const isInUseValidated =
        (entity.status !== DEVICE_CONSTANTS.STATUS.IN_USE && toggleKarnottNotInUse.checked) ||
        (entity.status === DEVICE_CONSTANTS.STATUS.IN_USE && toggleKarnottInUse.checked);
      const isAvailableValidated =
        (!!entity.currentEquipmentInstanceId && toggleAffectedKarnott.checked) ||
        (!entity.currentEquipmentInstanceId && toggleAvailableKarnott.checked);
      const isBatteryValidated =
        (getBatteryLevel(entity) <= 20 && toggleLowBattery.checked) ||
        (getBatteryLevel(entity) > 20 && toggleHighBattery.checked);

      return (
        isInUseValidated &&
        isAvailableValidated &&
        isBatteryValidated &&
        getIsMatchingTags(entity.tags, selectedTags) &&
        !entity.hidden
      );
    })
    .sort((k1, k2) => k1.name - k2.name)
    .sort((k1, k2) => {
      if (k1.status === DEVICE_CONSTANTS.STATUS.IN_USE && k2.status !== DEVICE_CONSTANTS.STATUS.IN_USE) {
        return -1;
      }
      if (k2.status === DEVICE_CONSTANTS.STATUS.IN_USE && k1.status !== DEVICE_CONSTANTS.STATUS.IN_USE) {
        return 1;
      }
      return 0;
    })
    .sort((k1, k2) => {
      if (badHealthKarnotts.find((bhk) => bhk === k1)) {
        return -1;
      }
      if (badHealthKarnotts.find((bhk) => bhk === k2)) {
        return 1;
      }
      return 0;
    });
};

export const filterBeacons = (entities, type, filters) => {
  const toggleItems = filters?.toggles?.items || [];
  const toggleAffectedBeacons = toggleItems.find((item) => item?.type === BEACON_TOGGLE_TYPE.AFFECTED);
  const toggleAvailableBeacons = toggleItems.find((item) => item?.type === BEACON_TOGGLE_TYPE.AVAILABLE);

  const selectedTags = filters.select?.selected;

  return entities
    .filter((entity) => !entity.hidden && entity.type === type)
    .filter((entity) => {
      if (type === DEVICE_CONSTANTS.BEACON_TYPE.DRIVER) {
        return (
          ((entity.currentUserId && toggleAffectedBeacons.checked) ||
            (!entity.currentUserId && toggleAvailableBeacons.checked)) &&
          getIsMatchingTags(entity.tags, selectedTags)
        );
      }
      return (
        ((entity.currentEquipmentInstanceId && toggleAffectedBeacons.checked) ||
          (!entity.currentEquipmentInstanceId && toggleAvailableBeacons.checked)) &&
        getIsMatchingTags(entity.tags, selectedTags)
      );
    })
    .sort((k1, k2) => {
      if (k1.status === DEVICE_CONSTANTS.STATUS.IN_USE && k2.status !== DEVICE_CONSTANTS.STATUS.IN_USE) {
        return -1;
      }
      if (k2.status === DEVICE_CONSTANTS.STATUS.IN_USE && k1.status !== DEVICE_CONSTANTS.STATUS.IN_USE) {
        return 1;
      }
      return 0;
    });
};

export const filterDrivers = (entities, filters) => {
  if (!filters || filters.length === 0) {
    return entities;
  }
  const toggleItems = filters?.toggles?.items || [];
  const toggleActiveDriver = toggleItems.find((item) => item?.type === filterToggleTypes.ACTIVE_DRIVER);
  const toggleAvailableDriver = toggleItems.find((item) => item?.type === filterToggleTypes.AVAILABLE_DRIVER);
  const toggleAdmins = toggleItems.find((item) => item?.type === filterToggleTypes.ADMIN_DRIVER);

  const selectedTags = filters.select?.selected;

  return entities.filter((entity) => {
    const isEntityOnlyDriver = entity.cluster_roles.every(
      (clusterRole) =>
        clusterRole.role !== USER_CONSTANTS.ROLE.ADMIN && clusterRole.role !== USER_CONSTANTS.ROLE.IMPLEMENT_MANAGER,
    );
    return (
      !entity.hidden &&
      (toggleAdmins.checked || isEntityOnlyDriver) &&
      ((entity.status === DEVICE_CONSTANTS.STATUS.IN_USE && toggleActiveDriver.checked) ||
        (entity.status === DEVICE_CONSTANTS.STATUS.AVAILABLE && toggleAvailableDriver.checked)) &&
      getIsMatchingTags(entity.tags, selectedTags)
    );
  });
};

/**
 * @param   {Record<string, string | string[] | number | number[]>} filters Filters object
 *
 * @returns {string}                                                        Stable stringified filters object, to use as
 *   a key in a cache
 */
export function stringifyFilters(filters) {
  return Object.entries(filters)
    .map(([k, v]) => k + ':' + (Array.isArray(v) ? v.sort().join(',') : v))
    .sort()
    .join(';');
}

/**
 * @param   {Record<string, any>[]} list
 * @param   {string}                search
 * @param   {string}                key    Default is `"name"`
 *
 * @returns {Record<string, any>[]}        `list`, filtered by `search` on the `key` key
 */
export const searchFilter = (list, search, key = 'name') => {
  return list.filter((item) => removeAccents(item[key].toLowerCase()).includes(removeAccents(search.toLowerCase())));
};

/**
 * Hook to manage the list of a filter, and the inner state of the selection
 *
 * @param   {{
 *   setFilterList: (selectedFilters: { id: any }[]) => void;
 *   initialItemList: any[];
 *   itemList: any[];
 *   listLabel: string;
 * }}                         props
 *
 *
 * @returns {{
 *   selection: any[];
 *   toggleItemSelection: (item: any) => void;
 *   clearSelection: () => void;
 *   selectAll: () => void;
 *   getListLabel: () => string;
 * }}
 */
export function useFilterData({ setFilterList, initialItemList, itemList, listLabel }) {
  const { t } = useContext(I18nContext);

  const onSelectionChange = useDebounce((s) => setFilterList(s.map((c) => c.id)), 500);

  const { selection, setSelection, toggleItemSelection, clearSelection, selectAll } = FormHooks.useListSelection(
    true,
    onSelectionChange,
    initialItemList,
    itemList,
    areIDsEquals,
  );

  // sync initialItemList with selection when it is slow to load
  const prevIds = useRef(null);
  useEffect(() => {
    const newIds = initialItemList
      .map((i) => i.id)
      .sort()
      .join('');
    if (
      newIds !== prevIds.current &&
      newIds !==
        selection
          .map((i) => i.id)
          .sort()
          .join('')
    ) {
      setSelection(initialItemList);
    }
    prevIds.current = newIds;
  }, [initialItemList, selection, setSelection]);

  const getListLabel = () => (selection.length ? `${t(listLabel)} (${selection.length})` : t(listLabel));

  return { selection, toggleItemSelection, clearSelection, selectAll, getListLabel };
}
