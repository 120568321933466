import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { UIHooks } from '@karnott/hooks';
import { fetchBeacons } from '../actions/beacons';
import { fetchDevices } from '../actions/devices';
import { fetchEquipmentModels, fetchEquipments } from '../actions/equipments';
import { setDefaultTagFilters } from '../actions/filters';
import { fetchObservationCategories, fetchObservations } from '../actions/observations';
import { fetchParcels } from '../actions/parcels';
import { setInitialTags } from '../actions/ui';
import { fetchUsers, logoutUser, retrieveMe } from '../actions/user';
import GoToMobileApp from '../components/GoToMobileApp';
import Header from '../components/Header';
import HelpDemoModal from '../components/HelpDemoModal';
import LeaveIE from '../components/LeaveIE';
import { PluginsContext } from '../contexts/PluginsProvider';
import { UIContext } from '../contexts/ui';
import Routes from '../routes';
import { fetchClusters } from '../slices/clusters';
import { fetchCrops } from '../slices/crops';
import { fetchAllMixtures } from '../slices/phyto';
import { fetchTags } from '../slices/tags';
import { color } from '../ui/theme';
import { isMobile } from '../utils';

const Wrapper = styled.div`
  padding-top: 55px;
  background-color: ${color('snow', 'light')};
`;

function AppContainer() {
  const { hasOverallCropManagementPlugin, hasOverallObservationPlugin } = useContext(PluginsContext);
  const { demoAccount } = useContext(UIContext);
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const title = useSelector((state) => state.ui.title);

  const leaveIE = Boolean(
    navigator.appName === 'Microsoft Internet Explorer' ||
      navigator.userAgent.match(/Trident/) ||
      navigator.userAgent.match(/rv:11/) ||
      navigator.userAgent.match(/MSIE/g),
  );
  const mobile = isMobile();
  const [isMobileOpen, , closeMobile] = UIHooks.useOpenCloseState(mobile);

  const redirectToOAuth = useCallback(() => {
    localStorage.setItem('fromURL', location.pathname || '/');
    window.location.replace('/logout');
  }, [location]);

  const hasValidToken = useMemo(() => {
    if (user?.token) {
      if (user?.expireDate) {
        const now = new Date();
        const almostExpired = new Date(user.expireDate);
        almostExpired.setSeconds(almostExpired.getSeconds() - 10);
        return now <= almostExpired;
      }
      return true;
    }
    return false;
  }, [user]);

  const loginSafety = useCallback(() => {
    if (!hasValidToken) {
      redirectToOAuth();
    }
  }, [hasValidToken, redirectToOAuth]);

  useEffect(() => {
    loginSafety();
  }, [loginSafety]);

  const withToken = useCallback(
    (call) => {
      if (hasValidToken) {
        call();
      }
    },
    [hasValidToken],
  );

  useEffect(() => {
    withToken(() => {
      dispatch(fetchParcels());
      dispatch(fetchEquipmentModels());
      dispatch(fetchEquipments());
      dispatch(fetchClusters());
      dispatch(fetchDevices());
      dispatch(fetchBeacons());
      dispatch(fetchUsers());
      dispatch(fetchTags());
      dispatch(fetchAllMixtures());
    });
  }, [dispatch, withToken]);

  useEffect(() => {
    if (hasOverallCropManagementPlugin) {
      withToken(() => dispatch(fetchCrops()));
    }
  }, [dispatch, hasOverallCropManagementPlugin, withToken]);

  useEffect(() => {
    if (hasOverallObservationPlugin) {
      withToken(() => dispatch(fetchObservations('?status=AVAILABLE')));
      withToken(() => dispatch(fetchObservationCategories()));
    }
  }, [dispatch, hasOverallObservationPlugin, withToken]);

  useEffect(() => {
    if (hasValidToken) {
      dispatch(retrieveMe()).then((retrievedUser) => {
        if (retrievedUser?.application_role !== 'USER') {
          alert(`pro.karnott.fr n'est accessible qu'aux comptes utilisateurs`);
          dispatch(logoutUser());
        } else if (retrievedUser?.tag_ids_preference) {
          const tags = retrievedUser.tag_ids_preference;
          dispatch(setDefaultTagFilters(tags));
          dispatch(setInitialTags(tags?.parcel || []));
        }
      });
    }
  }, [dispatch, hasValidToken]);

  if (!hasValidToken) {
    return null;
  } else {
    return (
      <Wrapper id="app-element">
        <Helmet {...{ title }} />
        <Header />
        <Routes />
        <GoToMobileApp isOpen={isMobileOpen} onRequestClose={closeMobile} />
        {demoAccount ? <HelpDemoModal /> : null}
        <LeaveIE isOpen={leaveIE} />
      </Wrapper>
    );
  }
}

export default memo(AppContainer);
