import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const ParcelNameIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '60%' : '100%'}
      height={circled ? '60%' : '100%'}
      viewBox="0 0 44 44"
    >
      <rect
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        x="2"
        y="12"
        width="40"
        height="20"
        rx="2"
        ry="2"
      />
      <g>
        <line
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          x1="23"
          y1="22"
          x2="10"
          y2="22"
        />
        <line
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          x1="34"
          y1="22"
          x2="27"
          y2="22"
        />
      </g>
    </svg>
  </CircledIcon>
);
