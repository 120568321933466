import { ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { pixelSpacing } from '@karnott/theme';
import { TooltipMessage, TooltipMessageProps } from './TooltipMessage';

type TooltipProps = TooltipMessageProps & {
  /** Content that opens the tooltip on hover */
  content?: ReactNode;
};

/** Display helpful information on hover */
export function Tooltip({
  content,
  message,
  orientation = 'top',
  noWrap = false,
  small = false,
  unstyledContent = false,
}: TooltipProps) {
  const [ref, hovered] = UIHooks.useHover<HTMLDivElement>();
  const defaultContent: ReactNode = useMemo(() => (content ? content : '?'), [content]);
  return (
    <TooltipContainer ref={ref} $content={defaultContent} unstyled={unstyledContent}>
      {defaultContent}
      {message && hovered ? (
        <TooltipMessage orientation={orientation} message={message} noWrap={noWrap} small={small} />
      ) : null}
    </TooltipContainer>
  );
}

const TooltipContainer = styled.div<{
  $content: ReactNode;
  unstyled: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ unstyled, $content }) =>
    unstyled
      ? null
      : css`
          color: ${colors('white')};
          background-color: ${colors('black')};
          font-size: 10px;
          border-radius: ${typeof $content === 'string' && $content?.length > 1 ? '5px' : '50%'};
          padding: ${typeof $content === 'string' && $content?.length > 1
            ? pixelSpacing('xSmall')
            : `${pixelSpacing('xSmall')} ${pixelSpacing('small')}`};
        `}
`;
