import { sortAlphaBeta } from '.';
import {
  COLOR_PARCELS_BY,
  COLOR_TRACKS_BY,
  ColorChoice,
  ParcelColorChoice,
  TrackColorChoice,
} from '../constants/colorConstants';

export function sortColorChoices(a: ColorChoice, b: ColorChoice) {
  return a === 'default' ? -1 : b === 'default' ? 1 : sortAlphaBeta(a, b);
}

export function colorHasLeadingHash(color: string): color is `#${string}` {
  return color.startsWith('#');
}

export function getColorWithHash(color: string): `#${string}` {
  return colorHasLeadingHash(color) ? color : `#${color}`;
}

export function isColorChoice(choice: unknown): choice is ColorChoice {
  return (
    typeof choice === 'string' &&
    (Object.values(COLOR_PARCELS_BY).includes(choice) || Object.values(COLOR_TRACKS_BY).includes(choice))
  );
}

export function isParcelColorChoice(choice: ColorChoice): choice is ParcelColorChoice {
  return Object.values(COLOR_PARCELS_BY).includes(choice);
}

export function isTrackColorChoice(choice: ColorChoice): choice is TrackColorChoice {
  return Object.values(COLOR_TRACKS_BY).includes(choice);
}

export function getStoredColorChoice(localStorageKey: string): ColorChoice {
  const stored = localStorage.getItem(localStorageKey);
  return isColorChoice(stored) ? stored : 'default';
}

export function randomHexColor(): `#${string}` {
  return `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')}`;
}
