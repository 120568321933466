import { sortColorChoices } from '../utils/colors';
import { Values } from '../utils/types';

export const COLOR_PARCELS_BY = {
  DEFAULT: 'default',
  CROP: 'crops',
  TAG: 'tags',
  CLUSTER: 'clusters',
} as const;
export const COLOR_TRACKS_BY = {
  DEFAULT: 'default',
  CLUSTER: 'clusters',
  EQUIPMENT: 'equipment',
} as const;

export type ParcelColorChoice = Values<typeof COLOR_PARCELS_BY>;
export type TrackColorChoice = Values<typeof COLOR_TRACKS_BY>;
export type ColorChoice = ParcelColorChoice | TrackColorChoice;

export const COLOR_CATEGORIES = {
  PARCELS: 'parcels',
  TRACKS: 'tracks',
} as const;

export type ColorCategory = Values<typeof COLOR_CATEGORIES>;

export const COLOR_CHOICES_BY_CATEGORY = {
  [COLOR_CATEGORIES.PARCELS]: Object.values(COLOR_PARCELS_BY).sort(sortColorChoices),
  [COLOR_CATEGORIES.TRACKS]: Object.values(COLOR_TRACKS_BY).sort(sortColorChoices),
} as const;
