import moment from 'moment-timezone';
import { history } from '../reducers/store';

export const handleStatusURLParams = (filters) => {
  const nbOfStatus = filters?.status?.items?.length;
  const { state } = history.location;
  const url = new URL(window.location.href);
  const statusFilters =
    (filters?.status?.items || []).filter((status) => status.checked).map((checkedType) => checkedType.value) || [];
  if (nbOfStatus === 0 || nbOfStatus !== statusFilters?.length) {
    const searchParams = new URLSearchParams(url.search);
    if (filters.status?.items?.find((status) => !status.checked)) {
      searchParams.delete('processing_status');
    }
    filters.status?.items
      ?.filter((status) => status.checked && searchParams.getAll('processing_status').indexOf(status.value) === -1)
      .forEach((s) => searchParams.append('processing_status', encodeURIComponent(s.value)));

    history.replace({
      state,
      search: searchParams.toString(),
    });
  } else {
    const searchParams = new URLSearchParams(url.search);
    searchParams.delete('processing_status');

    history.replace({
      state,
      search: searchParams.toString(),
    });
  }
};
export const handleClusterIDURLParams = (filters) => {
  const nbOfClusters = filters?.clusters?.items?.length;
  const { state } = history.location;
  const clustersFilters =
    (filters?.clusters?.items || []).filter((cluster) => cluster.checked).map((checkedType) => checkedType.id) || [];
  const url = new URL(window.location.href);
  if (nbOfClusters === 0 || nbOfClusters !== clustersFilters.length) {
    const searchParams = new URLSearchParams(url.search);
    if (filters.clusters && filters.clusters.items?.find((cluster) => !cluster.checked)) {
      searchParams.delete('clusterId');
    }
    filters.clusters &&
      filters.clusters.items
        ?.filter((cluster) => cluster.checked && searchParams.getAll('clusterId').indexOf(`${cluster.id}`) === -1)
        .forEach((c) => searchParams.append('clusterId', encodeURIComponent(c.id)));

    history.replace({
      state,
      search: searchParams.toString(),
    });
  } else {
    const searchParams = new URLSearchParams(url.search);
    searchParams.delete('clusterId');

    history.replace({
      state,
      search: searchParams.toString(),
    });
  }
};

export const handleDateURLParams = (fromDate, toDate) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  searchParams.set('fromDate', moment(fromDate).format());
  searchParams.set('toDate', moment(toDate).format());

  const { state } = history.location;

  history.push({
    state,
    search: searchParams.toString(),
  });
};

export const initDatesFromURL = () => {
  let initialFromDate = null,
    initialToDate = null;
  const url = new URL(window.location.href);
  const fromDateParam = url.searchParams.get('fromDate');
  const toDateParam = url.searchParams.get('toDate');
  if (fromDateParam && moment(decodeURIComponent(fromDateParam)).isValid()) {
    initialFromDate = moment(decodeURIComponent(fromDateParam)).startOf('day');
  }
  if (toDateParam && moment(decodeURIComponent(toDateParam)).isValid()) {
    initialToDate = moment(decodeURIComponent(toDateParam)).endOf('day');
  }
  return {
    fromDate: initialFromDate,
    toDate: initialToDate,
  };
};

/**
 * Add new query params to the current query params
 *
 * @param   {Record<string, string>} currentQueryParams The current query params object
 * @param   {Record<string, string>} newQueryParams     The new query params to add
 *
 * @returns {string}                                    The new query params, without the `?`
 */
export function addURLParams(currentQueryParams, newQueryParams) {
  // decode the current query params
  const decodedCurrentParams = {};
  Object.entries(currentQueryParams).forEach(([k, v]) => (decodedCurrentParams[k] = decodeURIComponent(v)));

  const currentParams = new URLSearchParams(decodedCurrentParams);
  for (const key in newQueryParams) {
    const value = newQueryParams[key];
    currentParams.set(key, value);
  }
  return currentParams.toString();
}
