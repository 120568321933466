import { Values } from '../utils/types';

export const ARTICLE_UNITS = {
  /** Ha or ac */
  AREA: 'area',
  /** Hours (decimal) */
  TIME: 'time',
  /** Km or miles */
  DISTANCE: 'distance',
  UNIT: 'unit',
  PACKAGE: 'package',
  /** Tons */
  MASS: 'mass',
  OTHER: 'other',
} as const;
export type ArticleUnit = Values<typeof ARTICLE_UNITS>;

export const ARTICLE_STATUS = {
  AVAILABLE: 'available',
  ARCHIVED: 'archived',
} as const;
export type ArticleStatus = Values<typeof ARTICLE_STATUS>;
