import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import round from 'lodash-es/round';
import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { fontFamily, msDuration, pixelSize, pixelSpacing } from '@karnott/theme';
import { color, size, spacing, zIndex } from './theme';

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const InlineList = styled(List)`
  > li {
    display: inline-block;
  }
`;

export const Link = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  display: inline-block;
`;

export const EntityLink = styled(NavLink)`
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const EntityLinkSub = styled.div`
  color: inherit;
  font-weight: 300;
  text-decoration: none;
`;

export const ClickableEntity = styled.div`
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const Container = styled.div`
  padding-left: ${(props) => (props.bare ? 0 : spacing)}px;
  padding-right: ${(props) => (props.bare ? 0 : spacing)}px;
  margin-left: ${(props) => (props.withMargin ? pixelSpacing('large') : 0)};
  margin-right: auto;
  max-width: 100%;
  width: ${(props) => (props.withMargin ? 'unset' : '100%')};
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tr = styled.tr`
  vertical-align: middle;
  color: ${color('smoke', 'dark')};
`;

export const Td = styled.td`
  padding: ${spacing / 2}px;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const VerticalSplitContainer = styled.div`
  display: flex;
  position: absolute;
  top: ${({ isDemo }) => (isDemo ? '96px' : '55px')};
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors('grey', 100)};

  @media (max-width: 1180px) {
    flex-direction: column;
    bottom: initial;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  min-width: 0;
`;

export const RightContainerScrollableContent = styled.div`
  height: ${({ isDemo }) => (isDemo ? 'calc(100vh - 96px)' : 'calc(100vh - 55px)')};
  max-height: ${({ isDemo }) => (isDemo ? 'calc(100vh - 96px)' : 'calc(100vh - 55px)')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
`;

export const RightContainerDiv = styled.div``;

export const Cell = styled.div`
  display: inline-block;
  ${(props) =>
    props.width &&
    css`
      flex: 0 0 ${round(100 * props.width)}%;
    `};
  min-width: 0;
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    flex: 0 0 100%;
  }
`;

export const DetailsMapContainer = styled.div`
  display: flex;
  flex: 1;
  z-index: ${({ fullScreenMap }) => (fullScreenMap ? zIndex('ui_date_picker', 2) : zIndex('modal_map'))};
  position: ${({ fullScreenMap }) => (fullScreenMap ? 'fixed' : 'relative')};
  height: ${({ isDemo }) => (isDemo ? 'calc(100vh - 96px)' : 'calc(100vh - 55px)')};
  width: ${({ fullScreenMap }) => (fullScreenMap ? '100vw' : 'auto')};

  @media (max-width: 1180px) {
    flex: auto;
    height: 400px;
    margin-right: 0;
  }
`;

export const EntityDetailsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  @media (orientation: landscape) and (max-width: 700px), (max-width: 700px) {
    height: auto;
  }
`;

export const GoOnLargerScreen = styled.div`
  display: none;
  visibility: hidden;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-left: 0 !important;
  padding: ${spacing / 2}px 0;

  @media (orientation: landscape) and (max-width: 700px), (max-width: 700px) {
    display: flex;
    visibility: visible;
  }
`;

export const ArrowPopover = styled.div`
  position: absolute;
  bottom: ${(props) => (props.popoverBottom !== undefined ? `${props.popoverBottom}px` : 'auto')};
  top: ${(props) => (props.popoverTop !== undefined ? `${props.popoverTop}px` : 'auto')};
  background: ${color('snow')};
  border: 2px solid ${color('smoke', 'dark')};
  color: ${colors('grey', 'light')};
  border-radius: 4px;
  padding: ${(props) => (props.padding ? `${props.padding}px` : '4px')};
  display: inline-block;
  width: ${(props) => (props.popoverWidth ? `${props.popoverWidth}px` : 'auto')};
  left: ${(props) => (props.popoverLeft !== undefined ? `${props.popoverLeft}px` : 'auto')};
  right: ${(props) => (props.popoverRight ? `${props.popoverRight}px` : 'auto')};
  box-shadow: rgba(0, 0, 0, 0.1) 0 6px 6px;
  white-space: ${(props) => {
    if (props.noWhiteSpace) {
      return 'normal';
    }
    if (props.left || props.right) {
      return 'nowrap';
    }
    return 'normal';
  }};
  z-index: ${zIndex('modal', 3)};
  &:after,
  &:before {
    top: ${(props) => {
      if (props.left || props.right) {
        return '50%';
      }
      return '100%';
    }};
    left: ${(props) => {
      if (props.left) {
        return 'auto';
      }
      if (props.right) {
        return '100%';
      }
      return '50%';
    }};
    right: ${(props) => {
      if (props.left) {
        return '100%';
      }
      return 'auto';
    }};
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(242, 240, 237, 0);
    border-top-color: ${(props) => (props.left || props.right ? 'none' : color('snow'))};
    border-right-color: ${(props) => (props.left ? color('snow') : 'none')};
    border-left-color: ${(props) => (props.right ? color('snow') : 'none')};
    border-width: 6px;
    margin-left: ${(props) => (props.left || props.right ? '0' : '-6px')};
    margin-top: ${(props) => (props.left || props.right ? '-6px' : '0')};
  }
  &:before {
    border-color: rgba(203, 198, 191, 0);
    border-top-color: ${(props) => (props.left || props.right ? 'none' : color('smoke', 'dark'))};
    border-right-color: ${(props) => (props.left ? color('smoke', 'dark') : 'none')};
    border-left-color: ${(props) => (props.right ? color('smoke', 'dark') : 'none')};
    border-width: 9px;
    margin-left: ${(props) => (props.left || props.right ? '0' : '-9px')};
    margin-top: ${(props) => (props.left || props.right ? '-9px' : '0')};
  }
`;

export const CustomLink = styled.a`
  font-family: ${fontFamily()};
  text-decoration: none;
  color: ${(props) => (props.textColor ? props.textColor : 'white')};
  &:visited {
    text-decoration: none;
    color: ${(props) => (props.textColor ? props.textColor : 'white')};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const CustomImageLink = styled.a`
  background: #c2324f;
  border-radius: 50%;
  img {
    width: 23px;
    height: 23px;
  }
`;

export const ScrollableContent = styled.div`
  height: calc(100vh - 55px);
  ${(props) =>
    props.isDemo &&
    css`
      top: 95px;
      width: 100%;
      position: absolute;
      height: calc(100vh - 95px);
    `}
`;

const BreadcrumbWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing}px 0 ${spacing / 2}px 0;
  color: ${colors('grey', 'light')};
  font-size: ${size('xs')};
`;

const BreadcrumbLink = styled(NavLink)`
  color: ${colors('grey', 'light')};
  &:visited,
  &:active,
  &:hover {
    color: ${colors('grey', 'light')};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(52, 49, 44, 0.64); //#34312C
  z-index: ${zIndex('modal_modal', -2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  position: relative;
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  margin-top: ${spacing / 2}px;
  margin-bottom: 0;
  flex-shrink: 0;
  gap: ${pixelSpacing('small')} ${pixelSpacing('regular')};
`;

export const ActionButtons = styled(Buttons)`
  margin-top: 0;
  justify-content: flex-end;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.2s;
`;

export const OverlayText = styled.p`
  opacity: 1;
  text-align: center;
  color: white;
  font-size: 1.5em;
  max-width: 100%;
  padding: 0 48px;
`;

export const EntityDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: ${pixelSpacing()};
  padding-top: ${pixelSpacing('small')};
`;

export const EntitySessionsWrapper = styled(EntityDataWrapper)`
  position: relative;
  padding: ${pixelSpacing()};
  > * {
    margin: 0 0 ${pixelSpacing('small')} 0;
  }
`;

export const FAQLink = styled.a`
  font-size: ${pixelSize()};
  color: ${colors('orange')};
  cursor: pointer;
  font-family: ${fontFamily()};
  width: fit-content;
  transition: color ${msDuration('short')} ease-in-out;
  ${({ inline }) =>
    inline
      ? null
      : css`
          margin-left: ${pixelSpacing('large')};
          margin-bottom: ${pixelSpacing('small')};
        `}
  :hover {
    color: ${colors('orange', 600)};
  }
`;

export const SessionsButtonWrapper = styled.div`
  margin: ${pixelSpacing()} 0;
  display: flex;
  justify-content: flex-end;
`;

export const InputWithExistingItems = styled.div`
  flex: ${({ flex }) => (flex ? 1 : undefined)};
  position: relative;
  text-transform: uppercase;
  input {
    height: 34px;
  }
`;

export const ChosenExistingItem = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  background-color: ${colors('grey', 100)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
  border-radius: 4px;
  border: 1px solid ${colors('grey', 'light')};
  height: 35px;
  padding: ${pixelSpacing('small')};
  font-size: ${pixelSize('')};
  > div {
    cursor: pointer;
  }
`;

export const RelatedCustomItems = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  display: flex;
  text-transform: none;
  flex-direction: column;
  background-color: white;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid ${colors('grey', 'light')};
  > div {
    font-size: ${pixelSize()};
    padding: ${pixelSpacing('small')};
    height: 35px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: ${colors('grey', 100)};
    }
  }
`;

export const Breadcrumb = function ({ current, items }) {
  return (
    <BreadcrumbWrapper>
      {items.map((item) => (
        <Fragment key={item.label}>
          <BreadcrumbLink to={item.to}>{item.label}</BreadcrumbLink>
          &nbsp;
          {'>'}
          &nbsp;
        </Fragment>
      ))}
      <div>{current}</div>
    </BreadcrumbWrapper>
  );
};

export const PartnerWarning = styled.div`
  background-color: ${colors('red', 400)};
  color: white;
  border-radius: 4px;
  padding: ${pixelSpacing('small')};
  margin-top: ${pixelSpacing()};
  text-align: center;
  > a {
    color: white;
  }
`;
