import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const InfoIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 13 13"
    >
      <defs>
        <clipPath>
          <rect width="3.164" height="8.297" transform="translate(0 0)" fill="#fff" />
        </clipPath>
      </defs>
      <ellipse cx="6.5" cy="6.5" rx="6.5" ry="6.5" fill={color} />
      <g transform="translate(4.741 2.174)">
        <g clipPath="url(#clipPath)">
          <path
            d="M2.151,1.917A1.041,1.041,0,0,0,3.164.856.781.781,0,0,0,2.355,0a1.056,1.056,0,0,0-1.06,1.048.817.817,0,0,0,.856.869"
            fill="#fff"
          />
          <path
            d="M2.649,6.345a.12.12,0,0,0-.107.037,2.465,2.465,0,0,1-.639.509,1.368,1.368,0,0,1,.058-.3c.094-.392.212-.862.329-1.329.105-.42.21-.837.3-1.191.191-.812.2-1.253.023-1.475a.467.467,0,0,0-.386-.165A3.828,3.828,0,0,0,.038,3.645a.126.126,0,0,0-.027.142l.18.384a.125.125,0,0,0,.087.07.128.128,0,0,0,.108-.028A1.9,1.9,0,0,1,.93,3.855a1.537,1.537,0,0,1-.052.3C.775,4.612.64,5.146.5,5.7c-.1.412-.21.835-.308,1.247A1.375,1.375,0,0,0,.281,8.092a.559.559,0,0,0,.455.2A3.327,3.327,0,0,0,2.931,6.933a.128.128,0,0,0,.014-.139l-.2-.384a.127.127,0,0,0-.093-.065"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
