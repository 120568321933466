import { UserRole } from '.';
import { Values } from '../utils/types';

export const PERMISSIONS_KEYS_BY_ACTION = {
  update: 'can_update',
  delete: 'can_delete',
  create_parcel: 'can_create_parcel',
  create_equipment: 'can_create_equipment',
  read_report: 'can_read_report',
  export: 'can_export',
  create_user: 'can_create_user',
  associate_device: 'can_associate_device',
  associate_beacon: 'can_associate_beacon',
  associate_parcel: 'can_associate_parcel',
  create_cluster_member: 'can_create_cluster_member',
  rent: 'can_rent',
  create_observation: 'can_create_observation',
  create_observation_category: 'can_create_observation_category',
  create_crop: 'can_create_crop',
  create_phyto_product: 'can_create_phyto_product',
  create_phyto_mixture: 'can_create_phyto_mixture',
  send_email: 'can_send_email',
  create_tag: 'can_create_tag',
  share: 'can_share',
  show_worked_area: 'can_show_worked_area',
  unlink: 'can_unlink',
  create_article: 'can_create_article',
  archive: 'can_archive',
} as const;

export type Permission = Values<typeof PERMISSIONS_KEYS_BY_ACTION>;

export type Permissions = Partial<Record<Permission, boolean | UserRole[]>>;
