import removeAccents from 'remove-accents';
import { FarmhouseIcon, ParcelRentIcon, PlotsIcon } from '@karnott/icons';
import { PARCELS_CONSTANTS } from '../constants/parcelsConstants';
import { color } from '../ui/theme';

export const parcelContains = (parcel = {}, filter = '') =>
  removeAccents(parcel.name || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const errorToDisplay = (errorCode) => {
  switch (errorCode) {
    case PARCELS_CONSTANTS.ERROR_CODES.DATA_NOT_VALID.code:
      return PARCELS_CONSTANTS.ERROR_CODES.DATA_NOT_VALID.title;
    case PARCELS_CONSTANTS.ERROR_CODES.POLYGON_NOT_VALID.code:
      return PARCELS_CONSTANTS.ERROR_CODES.POLYGON_NOT_VALID.title;
    case PARCELS_CONSTANTS.ERROR_CODES.INTERSECTION.code:
      return PARCELS_CONSTANTS.ERROR_CODES.INTERSECTION.title;
    case PARCELS_CONSTANTS.ERROR_CODES.SAVE_ERROR.code:
      return PARCELS_CONSTANTS.ERROR_CODES.SAVE_ERROR.title;
    case PARCELS_CONSTANTS.ERROR_CODES.AREA_TOO_LARGE.code:
      return PARCELS_CONSTANTS.ERROR_CODES.AREA_TOO_LARGE.title;
    default:
      return PARCELS_CONSTANTS.ERROR_CODES.SAVE_ERROR.title;
  }
};

export const getParcelIcon = (parcel, props = {}) => {
  if (!parcel) {
    return null;
  }
  if (parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
    return <FarmhouseIcon circled size={props.size} color="white" backgroundColor={color('yellow')} />;
  }

  if (parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT) {
    return <ParcelRentIcon size={props.size} color="white" backgroundColor={color('grey', 'light')} circled />;
  }

  let backgroundColor = 'cyan';
  let backgroundVariation = 'default';
  if (parcel.status === PARCELS_CONSTANTS.STATUS.RENT_OVER) {
    backgroundColor = 'grey';
  }
  if (parcel.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
    backgroundColor = 'sepia';
    backgroundVariation = 'light';
  }
  if (parcel.cluster_id === 0) {
    backgroundColor = 'orange';
  }
  return (
    <PlotsIcon circled size={props.size} color="white" backgroundColor={color(backgroundColor, backgroundVariation)} />
  );
};

export const parcelHasCropColor = (parcelCropId, colorItem) => {
  return String(colorItem?.id) === '0' ? !parcelCropId : String(parcelCropId) === String(colorItem?.id);
};

export const parcelHasTagColor = (tagId, colorItem) => {
  return String(tagId) === String(colorItem?.id);
};

export const parcelHasClusterColor = (clusterId, colorItem) => {
  return String(clusterId) === String(colorItem?.id);
};

export const getUniqueTagFromParcel = (parcel) =>
  Array.isArray(parcel.tags) && parcel.tags.length === 1 && parcel.tags.at(0).id;

export function determineFormat(fileExtension) {
  switch (fileExtension) {
    case 'zip':
    case 'shp':
      return 'shapefile';
    case 'xml':
      return 'telepac';
    case 'kml':
      return 'kml';
    default:
      return 'telepac';
  }
}

export function buildImportedParcels(importedParcels) {
  const importedParcelsSuccess = importedParcels?.success || [];
  let importedParcelsError = [];
  if (importedParcels?.errors) {
    importedParcels.errors.forEach((importedParcelError) => {
      if (importedParcelError.parcel && importedParcelError.parcel.geometry_area) {
        importedParcelError.parcel.error = true;
        importedParcelsError.push(importedParcelError.parcel);
      }
    });
  }
  return importedParcelsSuccess.concat(importedParcelsError);
}

/** Group parcel intersections from the API by the parcels to create */
export function formatParcelIntersections(intersections, parcelsToCreate, parcelsById) {
  if (!parcelsToCreate?.length) return [];

  const richIntersections = [];
  for (const intersection of intersections) {
    const toAddParcel = parcelsToCreate.at(intersection.index_error);
    const existingParcel = parcelsById[intersection.parcel_id];
    const existingRichIntersection = richIntersections.find((ri) => ri.toAddParcel.id === toAddParcel.id);
    if (!existingParcel) continue;
    if (existingRichIntersection) {
      existingRichIntersection.existingParcels ??= [];
      existingRichIntersection.existingParcels.push(existingParcel);
    } else {
      richIntersections.push({
        toAddParcel,
        existingParcels: [existingParcel],
      });
    }
  }
  return richIntersections;
}

/** Check whether all the intersected parcels are archived (inactive) */
export function isEveryIntersectingParcelInactive(intersections) {
  return intersections.every((intersection) =>
    intersection.existingParcels.every((parcel) => parcel?.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE),
  );
}
