import { Values } from '../utils/types';

export const TASK_TYPE = {
  PHYTO: 'phyto',
  SEMENCE: 'phyto_seed',
} as const;
export type TaskType = Values<typeof TASK_TYPE>;

export const TASK_STATUS = {
  TO_DO: 'todo',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
  TO_CREATE: 'to_link',
} as const;
export type TaskStatus = Values<typeof TASK_STATUS>;

export const VISIBLE_TASK_STATUS = {
  DONE: TASK_STATUS.DONE,
  TO_DO: TASK_STATUS.TO_DO,
  TO_CREATE: TASK_STATUS.TO_CREATE,
} as const;

export const DEFAULT_TASK_STATUS = TASK_STATUS.DONE;

const TASK_METADATA_GROUP = {
  CUSTOMER: 'customer',
  CACHET: 'cachet',
  DETAILS: 'details',
  CHECK_BEFORE_WORKSITE: 'check_before_worksite',
  CHECK_AFTER_WORKSITE: 'check_after_worksite',
  WORKSITE_VALIDATION: 'worksite_validation',
} as const;

export const VISIBLE_TASK_METADATA_GROUPS = [
  TASK_METADATA_GROUP.DETAILS,
  TASK_METADATA_GROUP.CHECK_BEFORE_WORKSITE,
  TASK_METADATA_GROUP.CHECK_AFTER_WORKSITE,
] as const;

export const TASK_METADATA_QUESTION_TYPE = {
  SINGLE_CLOSED: 'single_closed',
  OPEN: 'open',
} as const;
export type TaskMetadataQuestionType = Values<typeof TASK_METADATA_QUESTION_TYPE>;

export const TASK_EDIT_MODAL_MODE = {
  EDIT: 'edit',
  COMPLETE: 'complete',
} as const;
