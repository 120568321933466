import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LatLngBounds, Map } from 'leaflet';

export function useRegionBinder(map: Map) {
  const [isTrackingRegion, setIsTrackingRegion] = useState(false);
  const [isBinded, setIsBinded] = useState(false);
  const [region, setRegion] = useState<LatLngBounds | null>(null);

  const bind = useCallback(() => {
    setIsBinded(true);
  }, []);
  const unbind = useCallback(() => {
    setIsBinded(false);
  }, []);

  const trackRegion = useCallback(() => {
    setIsTrackingRegion(true);
  }, []);
  const untrackRegion = useCallback(() => {
    setIsTrackingRegion(false);
  }, []);

  const listener = useCallback(() => {
    const mapRegion = map.getBounds();
    const northEastLat = mapRegion.getNorthEast().lat;
    const northEastLng = mapRegion.getNorthEast().lng;
    const southWestLat = mapRegion.getSouthWest().lat;
    const southWestLng = mapRegion.getSouthWest().lng;
    if (northEastLat && northEastLat === southWestLat && northEastLng && northEastLng === southWestLng) {
      return;
    }
    setRegion(mapRegion);
  }, [map]);

  useEffect(() => {
    if (!map) return () => {};
    if (isTrackingRegion) {
      map.on('moveend', listener);
      return () => {
        map.off('moveend', listener);
      };
    }
    return () => {};
  }, [isTrackingRegion, listener, map, setRegion]);

  return {
    isTrackingRegion,
    isBinded,
    bind,
    unbind,
    trackRegion,
    untrackRegion,
    region,
  };
}

export function useCallSwitch(value: boolean, ifOn: () => void, ifOff: () => void) {
  useEffect(() => {
    if (value) {
      ifOn && ifOn();
      return ifOff;
    }
    return () => {};
  }, [value, ifOn, ifOff]);
}

function signRegion(r: LatLngBounds | null) {
  return r ? `${r.getSouthWest().lat}-${r.getSouthWest().lng}-${r.getNorthEast().lat}-${r.getNorthEast().lng}` : '';
}

export function useFitWithRegionBinding(
  fit: boolean,
  region: LatLngBounds | null,
  fitMap?: (soft: boolean, target: number[][]) => void,
) {
  const fitted = useRef<string | null>(null);
  const points = useMemo(() => {
    if (!fit || !region) return null;
    return [
      [region.getSouthWest().lat, region.getSouthWest().lng],
      [region.getNorthEast().lat, region.getNorthEast().lng],
    ];
  }, [fit, region]);

  useEffect(() => {
    if (fit && points) {
      const signature = signRegion(region);
      if (fitted.current !== signature) {
        fitted.current = signature;
        fitMap && fitMap(false, points);
      }
    }
  }, [fit, fitMap, points, region]);
}
