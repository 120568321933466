import moment from 'moment-timezone';
import { Locale } from '.';

export const loadMomentLocale = async (locale: Locale) => {
  switch (locale) {
    case 'fr':
      // @ts-expect-error TS cannot find the locale modules
      await import('moment/dist/locale/fr');
      moment.locale('fr');
      break;
    case 'de':
      // @ts-expect-error TS cannot find the locale modules
      await import('moment/dist/locale/de');
      moment.locale('de');
      break;
    case 'nl':
      // @ts-expect-error TS cannot find the locale modules
      await import('moment/dist/locale/nl');
      moment.locale('nl');
      break;
    case 'en':
      moment.locale('en');
      break;
    default: {
      const unhandledLang: never = locale;
      console.warn(`unhandled locale ${unhandledLang}`);
    }
  }
};
