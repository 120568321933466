import { ClusterId } from '../slices/clusters';

export const trackHasClusterColor = (clusterId: ClusterId, colorItem?: { id?: unknown }) => {
  return String(clusterId) === String(colorItem?.id);
};

export const trackHasEquipmentColor = (equipmentId: string, colorItem?: { id?: unknown }) => {
  return String(equipmentId) === String(colorItem?.id);
};

export const defaultTrackColor = '#B5E936';
