import { createAppSlice } from '.';
import { COLOR_CATEGORIES, ColorChoice, ParcelColorChoice, TrackColorChoice } from '../constants/colorConstants';
import { getStoredColorChoice } from '../utils/colors';
import { localStorageKeys } from '../utils/storage';

type State = {
  parcelColorationBy: ColorChoice;
  trackColorationBy: ColorChoice;
};

const initialState: State = {
  parcelColorationBy: getStoredColorChoice(localStorageKeys.colorizeFieldsBy),
  trackColorationBy: getStoredColorChoice(localStorageKeys.colorizeTracksBy),
};

const colorsSlice = createAppSlice({
  name: 'colors',
  initialState,
  reducers: (create) => ({
    parcelColorChanged: create.reducer<ParcelColorChoice>((state, { payload }) => {
      state.parcelColorationBy = payload;
      localStorage.setItem(localStorageKeys.colorizeFieldsBy, payload);
    }),
    trackColorChanged: create.reducer<TrackColorChoice>((state, { payload }) => {
      state.trackColorationBy = payload;
      localStorage.setItem(localStorageKeys.colorizeTracksBy, payload);
    }),
  }),
  selectors: {
    selectParcelColorChoice: (state) => state.parcelColorationBy,
    selectTrackColorChoice: (state) => state.trackColorationBy,
  },
});

export const colorChoiceSelector = {
  [COLOR_CATEGORIES.PARCELS]: colorsSlice.selectors.selectParcelColorChoice,
  [COLOR_CATEGORIES.TRACKS]: colorsSlice.selectors.selectTrackColorChoice,
} as const;

export type ColorItem = { id: string; name: string; color: `#${string}` };

export const { parcelColorChanged, trackColorChanged } = colorsSlice.actions;

export default colorsSlice.reducer;
