import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const RadiosContainer = styled.div<{
  vertical?: boolean;
  space?: number;
}>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  gap: ${({ vertical, space }) => (space ? `${space}px` : pixelSpacing(vertical ? 'small' : 'regular'))};
`;

const RadioContainer = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  display: flex;
  font-size: ${pixelSize()};
  align-items: center;
  overflow: visible;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  gap: ${pixelSpacing('small')};
  min-width: 0;
  flex-shrink: 0;
  &:hover {
    > span {
      color: ${({ disabled }) => (disabled ? colors('grey', 400) : colors('black'))};
    }
    .radio-center {
      background-color: ${({ checked }) => (checked ? colors('green') : colors('grey', 300))};
      width: ${({ disabled }) => (disabled ? 0 : '10px')};
      height: ${({ disabled }) => (disabled ? 0 : '10px')};
    }
  }
`;

const RadioBorder = styled.div<{
  checked: boolean;
}>`
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pixelSpacing('regular')};
  height: ${pixelSpacing('regular')};
  background-color: ${colors('white')};
  border-radius: 50%;
  border: 1px solid ${({ checked }) => (checked ? colors('green') : colors('grey', 300))};
  transition: border-color 180ms ease-in-out 0s;
`;

const RadioCenter = styled.div<{
  checked: boolean;
}>`
  box-sizing: border-box;
  width: ${({ checked }) => (checked ? '10px' : 0)};
  height: ${({ checked }) => (checked ? '10px' : 0)};
  border-radius: ${pixelSpacing('small')};
  transition:
    width 180ms ease-in-out 0s,
    height 180ms ease-in-out 0s,
    background-color 180ms ease-in-out 0s;
  outline: none;
  background-color: ${({ checked }) => (checked ? colors('green') : colors('grey', 300))};
`;

const Label = styled.span<{
  checked: boolean;
}>`
  color: ${({ checked }) => (checked ? colors('black') : colors('grey', 400))};
  font-family: ${fontFamily()};
  transition: color 180ms ease-in-out 0s;
`;

type RadioProps = {
  /** Whether the option is disabled */
  disabled?: boolean;
  /** Whether the option is selected */
  checked: boolean;
  /** The label of the option */
  label?: string;
  /** Callback called when the radio button or the label is clicked */
  onClick: (label?: string) => void;
};

/** A radio button to select a single choice in a list of options */
function Radio({ disabled, checked, label, onClick }: RadioProps) {
  return (
    <RadioContainer
      onClick={() => !disabled && onClick(label)}
      checked={checked}
      disabled={disabled}
      data-checked={checked ? 'true' : 'false'}
    >
      <RadioBorder checked={checked}>
        <RadioCenter className="radio-center" checked={checked} />
      </RadioBorder>
      {label ? <Label checked={checked}>{label}</Label> : null}
    </RadioContainer>
  );
}

type RadiosProps = {
  /** The definition of the options */
  items: { label: string; checked?: boolean; disabled?: boolean }[];
  /** Whether to show the options in a vertical or horizontal list */
  vertical?: boolean;
  /** The callback called when an item is clicked. The index of the item in the list is given as parameter */
  checkItem: (index: number) => void;
  /** The space between each option */
  space?: number;
};

/** A group of radio buttons, to select one option only */
function Radios({ items, vertical = false, checkItem, space }: RadiosProps) {
  return (
    <RadiosContainer {...{ vertical, space }}>
      {items.map((item, index) => (
        <Radio
          key={`${item.label}-${index}`}
          checked={item.checked || false}
          label={item.label}
          disabled={item.disabled}
          onClick={() => checkItem(index)}
        />
      ))}
    </RadiosContainer>
  );
}

export { Radio, Radios };
