import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useHistory } from 'react-router-dom';
import findIndex from 'lodash-es/findIndex';
import turfArea from '@turf/area';
import intersect from '@turf/intersect';
import { colors } from '@karnott/colors';
import { PARCELS_CONSTANTS } from '../../../constants/parcelsConstants';
import { I18nContext } from '../../../contexts/I18nProvider';
import { UIContext } from '../../../contexts/ui';
import { color } from '../../../ui/theme';
import { formatArea } from '../../../utils';
import { L as Leaflet } from '../../../utils/LeafletOverrides';
import { parcelPropertiesDataExtractor, useDrawVectorLayerOnMap, useParcelsCoords } from '../assets/Parcels/effects';
import { buildParcelStyle } from '../assets/Parcels/style';
import { MapContext } from '../contexts/map';
import { useTranslatedInstructionsToDraw } from '../effects';
import './leaflet-custom.css';

function useControlToggle(initialValue) {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue(!value);
  }, [value, setValue]);
  return [value, toggle, setValue];
}

function useDrawPolygons(map, parcelCreationErrors, onDrawPolygon = null) {
  const { t } = useContext(I18nContext);

  const parcelErrors = useMemo(() => {
    return (parcelCreationErrors || []).map((pce, index) => {
      const { error, error_code, parcel } = pce;
      const geo = JSON.parse(parcel.geometry_area);
      return {
        area: parcel.area,
        farmhouse: parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE,
        geoJSON: {
          type: 'Feature',
          properties: {
            index,
          },
          geometry: { ...geo, properties: { ...geo.properties, error: true } },
        },
        error,
        error_code,
        index,
        name: parcel.name,
      };
    });
  }, [parcelCreationErrors]);

  useTranslatedInstructionsToDraw({
    key: 'polygon',
    start: 'DrawParcel.tooltip_start',
    cont: 'DrawParcel.tooltip_cont',
    end: 'DrawParcel.tooltip_end',
  });

  const [isDrawing, toggleDrawing, setDrawing] = useControlToggle(false);
  const [drawnParcels, setDrawnParcels] = useState([]);
  // Initialise the FeatureGroup to store editable layers
  const drawnItems = useRef(new Leaflet.FeatureGroup());

  useEffect(() => {
    if (isDrawing) {
      map.addLayer(drawnItems.current);
      if (parcelErrors && parcelErrors.length) {
        const errorsLayer = Leaflet.geoJSON(
          parcelErrors.map((p) => ({ ...p.geoJSON })),
          {
            snapIgnore: true,
            pmIgnore: false,
            type: 'parcel',
            style: (f) => buildParcelStyle(f.geometry.properties),
            onEachFeature: (f, l) => {
              setDrawnParcels((d) => {
                const { index } = f.properties;
                d[index] = { ...parcelErrors[index] };
                d[index].layer = l;
                d[index].layer.index = index;
                return [...d];
              });
            },
          },
        );
        drawnItems.current.addLayer(errorsLayer);
      }
    } else {
      drawnItems.current.removeFrom(map);
    }
  }, [map, drawnItems, isDrawing, parcelErrors]);

  const polygon = useMemo(() => {
    return new Leaflet.Draw.Polygon(map, {
      number: drawnParcels.length,
      showArea: true,
      shapeOptions: {
        color: color('cyan'),
        fillColor: color('cyan'),
        opacity: 0.8,
        weight: 4,
      },
      allowIntersection: false,
      touchIcon: new Leaflet.DivIcon({
        iconSize: new Leaflet.Point(6, 6),
        className: 'leaflet-div-icon leaflet-editing-icon',
      }),
      icon: new Leaflet.DivIcon({
        iconSize: new Leaflet.Point(6, 6),
        className: 'leaflet-div-icon leaflet-editing-icon',
      }),
      drawError: {
        message: t('DrawParcel.intersects_unallocated'),
      },
    });
  }, [map, t, drawnParcels.length]);

  useEffect(() => {
    if (!isDrawing) {
      polygon.disable();
    } else {
      polygon.enable();
    }
  }, [polygon, isDrawing]);

  const onCreated = useCallback(
    (e) => {
      const poly = e && e.layer;
      if (poly && e.layerType === 'polygon' && isDrawing) {
        drawnItems.current.addLayer(poly);
        setDrawnParcels((d) => {
          poly.index = d.length;
          let newParcel = {
            index: d.length,
            geoJSON: poly.toGeoJSON(),
            area: Leaflet.GeometryUtil.geodesicArea(poly.getLatLngs()[0]),
            layer: poly,
            name: `${d.length}`,
            farmhouse: false,
          };
          onDrawPolygon && onDrawPolygon(newParcel);
          d.push(newParcel);
          return [...d];
        });
      }
    },
    [isDrawing, onDrawPolygon],
  );

  const isDrawnParcelInIntersection = useCallback(
    (index, newGeoJSON) => {
      const drawnParcel = drawnParcels[index];
      if (drawnParcel.layer && drawnParcel.layer._map && drawnParcel.layer.intersects()) return true;
      let isIntersected = false;
      drawnParcels.forEach((p) => {
        if (p.index !== index) {
          const geoJSONtoEvaluate = newGeoJSON || drawnParcel.geoJSON;
          const inter = intersect(p.geoJSON, geoJSONtoEvaluate);
          if (inter) {
            const areaOfIntersection = turfArea(inter);
            const areaOfEdited = turfArea(p.geoJSON);
            const areaOfCurrentField = turfArea(geoJSONtoEvaluate);
            if (areaOfIntersection > areaOfEdited / 10 || areaOfIntersection > areaOfCurrentField / 10) {
              isIntersected = true;
            }
          }
        }
      });
      return isIntersected;
    },
    [drawnParcels],
  );

  const adjustDrawnParcelsStyle = useCallback(() => {
    drawnParcels.forEach((d, currentIndex) => {
      let colorValue = d.farmhouse ? 'yellow' : 'cyan';
      if (d.error || isDrawnParcelInIntersection(currentIndex)) {
        colorValue = 'red';
      }
      d.layer.setStyle({
        strokeColor: color(colorValue),
        color: color(colorValue),
        fillColor: color(colorValue),
        fillOpacity: 0.4,
        weight: 4,
      });
    });
  }, [drawnParcels, isDrawnParcelInIntersection]);

  const onEdit = useCallback(
    (e) => {
      if (e && e.type === 'draw:editvertex' && isDrawing) {
        const { poly } = e;
        const { index } = poly;
        setDrawnParcels((d) => {
          const inError = isDrawnParcelInIntersection(index, poly.toGeoJSON());
          d[index] = {
            ...d[index],
            error_code: null,
            error: inError,
            geoJSON: poly.toGeoJSON(),
            area: Leaflet.GeometryUtil.geodesicArea(poly.getLatLngs()[0]),
          };
          onDrawPolygon && onDrawPolygon(d[index]);
          return [...d];
        });
      }
    },
    [isDrawing, isDrawnParcelInIntersection, onDrawPolygon],
  );

  useEffect(() => {
    adjustDrawnParcelsStyle();
  }, [adjustDrawnParcelsStyle, drawnParcels]);

  const removeDraw = useCallback(
    (i) => {
      setDrawnParcels((d) => {
        d[i].layer.removeFrom(map);
        drawnItems.current.removeLayer(d[i].layer);
        d.splice(i, 1);
        d.forEach((dp, j) => {
          dp.index = j;
        });
        return [...d];
      });
    },
    [setDrawnParcels, map],
  );

  const clearDraws = useCallback(() => {
    drawnItems.current.clearLayers();
    setDrawnParcels([]);
  }, [drawnItems]);

  useEffect(() => {
    if (isDrawing) {
      map.on('draw:created', onCreated);
      map.on('draw:editvertex', onEdit);
    }
    return () => {
      map.off('draw:created', onCreated);
      map.off('draw:editvertex', onEdit);
    };
  }, [map, onCreated, onEdit, isDrawing]);

  useEffect(() => {
    return () => {
      polygon && polygon.disable();
    };
  }, [polygon]);

  useEffect(() => {
    return () => {
      clearDraws();
    };
  }, [clearDraws]);

  const editDraw = useCallback(
    (i) => {
      const { layer } = drawnParcels[i];
      if (layer.editing._enabled) {
        layer.editing.disable();
        polygon.enable();
      } else {
        polygon.disable();
        layer.editing.enable();
      }
    },
    [drawnParcels, polygon],
  );

  const editDrawProperties = useCallback(
    (index, values) => {
      setDrawnParcels((d) => {
        d[index] = {
          ...d[index],
          ...values,
        };
        return [...d];
      });
    },
    [setDrawnParcels],
  );
  const editDrawName = useCallback(
    (index, value) => {
      setDrawnParcels((d) => {
        d[index] = {
          ...d[index],
          name: value,
        };
        return [...d];
      });
    },
    [setDrawnParcels],
  );

  const focusDrawnParcel = useCallback(
    (index) => {
      if (index !== undefined) {
        let colorValue = drawnParcels[index].farmhouse ? 'yellow' : 'cyan';
        if (drawnParcels[index].error || isDrawnParcelInIntersection(index)) {
          colorValue = 'red';
        }
        drawnParcels[index].layer.setStyle({
          strokeColor: color(colorValue, 'light'),
          color: color(colorValue, 'light'),
          fillColor: color(colorValue, 'light'),
          fillOpacity: 0.6,
          weight: 4,
        });
      } else {
        adjustDrawnParcelsStyle();
      }
    },
    [adjustDrawnParcelsStyle, drawnParcels, isDrawnParcelInIntersection],
  );

  const toggleFarmhouse = useCallback((index) => {
    setDrawnParcels((d) => {
      if (d[index].farmhouse) {
        d[index].layer.setStyle({
          strokeColor: color('yellow'),
          color: color('yellow'),
          fillColor: color('yellow'),
        });
      } else {
        d[index].layer.setStyle({
          strokeColor: color('cyan'),
          color: color('cyan'),
          fillColor: color('cyan'),
        });
      }
      d[index] = {
        ...d[index],
        farmhouse: !d[index].farmhouse,
      };
      return [...d];
    });
  }, []);

  return [
    toggleDrawing,
    isDrawing,
    drawnParcels,
    setDrawing,
    editDraw,
    editDrawName,
    removeDraw,
    clearDraws,
    focusDrawnParcel,
    toggleFarmhouse,
    editDrawProperties,
  ];
}

export function useParcelSelection(initialParcels = []) {
  const [selection, toggle] = useReducer((state, payload) => {
    if (payload === 'clear') return initialParcels;
    const parcel = payload;
    const index = state.findIndex((p) => p.id === parcel.id);
    if (index === -1) {
      return state.concat([parcel]);
    }
    return state.filter((p) => p.id !== parcel.id);
  }, initialParcels);
  const ids = useMemo(() => selection.map((p) => p.id?.toString()), [selection]);
  const clear = useCallback(() => {
    toggle('clear');
  }, [toggle]);
  return [selection, ids, toggle, clear];
}

function useAnonSelection(
  map,
  activated,
  anonLayer,
  anonLayerMetadata,
  focusedParcelId,
  parcelCreationErrors,
  onAnonParcelSelect = () => {},
) {
  const [selection, setSelection] = useState([]);
  const selectionIds = useMemo(() => selection.map((p) => p.id.toString()), [selection]);
  const [parcelErrorsAdded, setParcelErrorsAdded] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [bannedParcelIds, setBannedParcels] = useState([]);

  const onParcelClick = useCallback(
    (parcel) => {
      onAnonParcelSelect(parcel);
      setSelection((prev) => [...prev, parcel]);
    },
    [onAnonParcelSelect],
  );

  const dataset = useMemo(
    () => (!activated ? null : anonLayer?.length === 1 ? anonLayer[0] : null),
    [activated, anonLayer],
  );

  const vectorLayer = useDrawVectorLayerOnMap(map, dataset, onParcelClick, anonLayerMetadata);
  const geoJSONParcels = useRef([]);

  const parcelErrors = useMemo(() => {
    if (!activated) return [];
    return (parcelCreationErrors || []).map((pce, index) => {
      const { error, error_code, parcel } = pce;
      const geometry = JSON.parse(parcel.geometry_area);
      return {
        ...parcel,
        id: index,
        farmhouse: parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE,
        type: 'Feature',
        geometry,
        properties: { ...geometry.properties, error: true },
        error,
        error_code,
      };
    });
  }, [parcelCreationErrors, activated]);

  useEffect(() => {
    // if there is parcel errors, selection cleared, and toggle on false
    if (parcelErrors.length && !selection.length && !parcelErrorsAdded) {
      setSelection((s) => [...parcelErrors, ...s]);
      setParcelErrorsAdded(true);
    }
  }, [selection, parcelErrors, parcelErrorsAdded]);

  useEffect(() => {
    // this effect is here to, when we receive new parcel errors, toggle the fact
    // that we need to add them to selection
    if (parcelErrors.length) {
      setParcelErrorsAdded(false);
    }
  }, [parcelErrors]);

  const editAnonName = useCallback(
    (id, name) => {
      const indexOfParcel = findIndex(selection, (p) => p.id === id);
      if (indexOfParcel !== -1) {
        setSelection((s) => {
          s[indexOfParcel] = {
            ...s[indexOfParcel],
            name,
          };
          return [...s];
        });
      }
    },
    [selection],
  );

  const editAnonProperties = useCallback((id, values) => {
    setSelection((s) => {
      const indexOfParcel = findIndex(s, (p) => p.id === id);
      if (indexOfParcel !== -1) {
        s[indexOfParcel] = {
          ...s[indexOfParcel],
          ...values,
        };
        return [...s];
      }
      return s;
    });
  }, []);

  const toggleAnonFarmhouse = useCallback(
    (id) => {
      const indexOfParcel = findIndex(selection, (p) => p.id === id);
      if (indexOfParcel !== -1) {
        setSelection((s) => {
          s[indexOfParcel] = {
            ...s[indexOfParcel],
            farmhouse: !s[indexOfParcel]?.farmhouse,
          };
          return [...s];
        });
      }
    },
    [selection],
  );

  const clearSelection = useCallback(() => {
    setBannedParcels([]);
    setEditingId(null);
    setSelection([]);
  }, []);

  const geoJSONStyles = useCallback((options) => {
    const colorValue = options && options.hasError ? 'red' : 'green';
    return {
      selected: {
        strokeColor: colors(colorValue, 'light'),
        color: colors(colorValue),
        fillColor: colors(colorValue),
        fillOpacity: 0.2,
        weight: 2,
      },
      focused: {
        strokeColor: colors(colorValue, 'light'),
        color: colors(colorValue, 'light'),
        fillColor: colors(colorValue, 'light'),
        fillOpacity: 0.6,
        weight: 2,
      },
    };
  }, []);

  const getGeoJSONStyle = useCallback(
    (options) => {
      if (!options) return geoJSONStyles().selected;
      const { isFocused, hasError } = options;
      if (isFocused) return geoJSONStyles({ hasError }).focused;
      return geoJSONStyles({ hasError }).selected;
    },
    [geoJSONStyles],
  );

  const addGeoJSONLayerOnMap = useCallback(
    (parcel) => {
      if (map && parcel) {
        let geoJSONLayer = Leaflet.geoJSON(parcel, {
          style: getGeoJSONStyle(),
          id: parcel.id,
        }).addTo(map);

        geoJSONLayer.on('click', () => setSelection((prev) => prev.filter((p) => p.id !== parcel.id)));
        geoJSONParcels.current = [...geoJSONParcels.current, geoJSONLayer];
      }
    },
    [map, getGeoJSONStyle],
  );

  // Focused styling
  const previouslyFocusedParcelId = useRef();
  useEffect(() => {
    const focusedGeoJSON = geoJSONParcels.current.find((s) => s.options.id === focusedParcelId);
    const previouslyFocusedGeoJSON = geoJSONParcels.current.find(
      (parcel) => previouslyFocusedParcelId.current && parcel.options.id === previouslyFocusedParcelId.current,
    );

    if (focusedGeoJSON) {
      focusedGeoJSON.setStyle(getGeoJSONStyle({ hasError: focusedGeoJSON.options.error, isFocused: true }));
    }
    if (previouslyFocusedGeoJSON) {
      previouslyFocusedGeoJSON.setStyle(getGeoJSONStyle({ hasError: previouslyFocusedGeoJSON.options.error }));
    }

    previouslyFocusedParcelId.current = focusedParcelId;
  }, [focusedParcelId, getGeoJSONStyle]);

  // Change tiles style on selection/deselection
  const previousSelectionIds = useRef([]);
  useEffect(() => {
    if (previousSelectionIds.current.length > selection.length) {
      const newlyUnselectedParcelIds = previousSelectionIds.current.filter((id) => !selection.find((s) => s.id === id));

      newlyUnselectedParcelIds.forEach((id) => {
        vectorLayer && vectorLayer.resetFeatureStyle(id);
        geoJSONParcels.current.find((p) => p.options.id === id)?.removeFrom(map);
        geoJSONParcels.current = geoJSONParcels.current.filter((p) => p.options.id !== id);
      });
    } else if (previousSelectionIds.current.length < selection.length) {
      const newlySelectedParcels = selection.filter((p) => !previousSelectionIds.current.find((id) => id === p.id));

      newlySelectedParcels.forEach((parcel) => {
        vectorLayer &&
          vectorLayer.setFeatureStyle(parcel.id, {
            stroke: 0,
            fill: false,
          });

        addGeoJSONLayerOnMap(parcel);
      });
    }

    previousSelectionIds.current = selection.map((p) => p.id);
  }, [map, vectorLayer, selection, addGeoJSONLayerOnMap]);

  // Cleanup selected parcels
  useEffect(() => {
    return () => {
      geoJSONParcels.current.forEach((parcel) => parcel.removeFrom(map));
      geoJSONParcels.current = [];
    };
  }, [map]);

  // Update style while editing
  useEffect(() => {
    geoJSONParcels.current.forEach((geoJSONParcel) => {
      const vectorParcel = selection.find((p) => p.id === geoJSONParcel.options.id);
      geoJSONParcel.setStyle(
        getGeoJSONStyle({
          hasError: geoJSONParcel.options.error || vectorParcel.error,
          isFocused: focusedParcelId === geoJSONParcel.options.id,
        }),
      );
    });
  }, [selection, getGeoJSONStyle, focusedParcelId]);

  const editAnon = useCallback((id) => {
    geoJSONParcels.current.forEach((parcel) => {
      if (parcel.options.id === id) {
        parcel.eachLayer((layer) => {
          if (layer.pm.enabled()) {
            setEditingId(null);
            layer.pm.disable();
          } else {
            setEditingId(id);
            layer.pm.enable({ snappable: false });
          }
        });
      } else {
        parcel.eachLayer((layer) => {
          layer.pm.disable();
        });
      }
    });
  }, []);

  const getLatLngOfGeomanEvent = useCallback((e) => {
    return e.markerEvent?.latlng || e.marker?.getLatLng();
  }, []);

  const onAnonChange = useCallback(
    (e) => {
      const { layer } = e;
      const { id } = layer.feature;
      const isSelfIntersecting = layer.pm.hasSelfIntersection();

      setSelection((s) => {
        const indexOfParcel = findIndex(s, (p) => p.id === id);
        if (indexOfParcel === -1) {
          return s;
        }

        const geoJSONLayer = geoJSONParcels.current.find((p) => p.options.id === id);
        geoJSONLayer && (geoJSONLayer.options.error = isSelfIntersecting);
        const parcel = s[indexOfParcel];
        const { geometry } = parcel;

        // compute new geometry
        const latLng = getLatLngOfGeomanEvent(e);
        if (!latLng) return s;
        const updatedCoordinates = [latLng.lng, latLng.lat];
        let coordinates = layer.feature.geometry.coordinates;

        if (e.type === 'pm:markerdrag') {
          // move coordinates of dragged marker
          for (const index of e.indexPath) {
            coordinates = coordinates[index];
          }
          if (coordinates && coordinates.length === 2) {
            coordinates[0] = updatedCoordinates[0];
            coordinates[1] = updatedCoordinates[1];
          }
        } else if (e.type === 'pm:vertexadded') {
          // add coordinates of new vertex to feature
          const path = e.indexPath.slice(0, -1);
          for (const index of path) {
            coordinates = coordinates[index];
          }
          coordinates.splice(e.indexPath.at(-1), 0, updatedCoordinates);
        } else if (e.type === 'pm:vertexremoved') {
          // remove coordinates of removed vertex from feature
          const path = e.indexPath.slice(0, -1);
          for (const index of path) {
            coordinates = coordinates[index];
          }
          coordinates.splice(e.indexPath.at(-1), 1);
        }

        s[indexOfParcel] = {
          ...parcel,
          geometry: {
            ...geometry,
            coordinates: layer.feature.geometry.coordinates,
          },
          error: isSelfIntersecting,
          area: turfArea(layer.feature.geometry),
        };
        return [...s];
      });
    },
    [getLatLngOfGeomanEvent],
  );

  const onMarkerDrag = useCallback(
    (e) => {
      if (activated && e) {
        onAnonChange(e);
      }
    },
    [activated, onAnonChange],
  );

  const onMarkerDragEnd = useCallback(
    (e) => {
      if (activated && e && e.type === 'pm:markerdragend') {
        const parcel = selection.find((p) => p.id === e.layer?.feature?.id);
        onAnonParcelSelect(parcel);
      }
    },
    [activated, onAnonParcelSelect, selection],
  );

  const onVertexChange = useCallback(
    (e) => {
      if (activated && e) {
        flushSync(() => {
          // we need selection to be updated to get the parcel on the next line
          onAnonChange(e);
        });
        const parcel = selection.find((p) => p.id === e.layer?.feature?.id);
        onAnonParcelSelect(parcel);
      }
    },
    [activated, onAnonChange, onAnonParcelSelect, selection],
  );

  useEffect(() => {
    const geoJSONParcel = geoJSONParcels.current.find((p) => p.options.id === editingId);

    if (activated && geoJSONParcel) {
      geoJSONParcel.on('pm:markerdrag', onMarkerDrag);
      geoJSONParcel.on('pm:markerdragend', onMarkerDragEnd);
      geoJSONParcel.on('pm:vertexadded', onVertexChange);
      geoJSONParcel.on('pm:vertexremoved', onVertexChange);
    }

    return () => {
      if (geoJSONParcel) {
        geoJSONParcel.off('pm:markerdrag', onMarkerDrag);
        geoJSONParcel.off('pm:markerdragend', onMarkerDragEnd);
        geoJSONParcel.off('pm:vertexadded', onVertexChange);
        geoJSONParcel.off('pm:vertexremoved', onVertexChange);
      }
    };
  }, [editingId, onMarkerDrag, onMarkerDragEnd, activated, onVertexChange]);

  useEffect(() => {
    bannedParcelIds.forEach((id) => {
      vectorLayer &&
        vectorLayer.setFeatureStyle(id, {
          stroke: 0,
          fill: false,
        });
    });
  }, [bannedParcelIds, vectorLayer]);

  const removeSomeAnonParcels = useCallback((parcelIds) => {
    setBannedParcels((b) => [...b, ...parcelIds]);
  }, []);

  const removeAnon = useCallback(
    (parcelId) => {
      if (parcelId === editingId) {
        setEditingId(null);
      }

      setSelection((s) => s.filter((p) => p.id !== parcelId));
    },
    [editingId],
  );

  return {
    selection,
    selectionIds,
    clearSelection,
    editAnonName,
    removeSomeAnonParcels,
    removeAnon,
    editAnon,
    toggleAnonFarmhouse,
    editAnonProperties,
  };
}

export function useParcelDraw({
  focusedParcel,
  anonLayer,
  anonLayerMetadata,
  parcelCreationErrors,
  onDrawPolygon,
  onAnonParcelSelect,
}) {
  const { map } = useContext(MapContext);
  const [
    ,
    isDrawing,
    drawnParcels,
    setDrawing,
    editDraw,
    editDrawName,
    removeDraw,
    clearDraws,
    focusDrawnParcel,
    toggleDrawnFarmhouse,
    editDrawProperties,
  ] = useDrawPolygons(map, parcelCreationErrors, onDrawPolygon);
  const {
    selection: anonSelection,
    selectionIds: anonSelectionIds,
    clearSelection: clearAnonSelection,
    editAnonName,
    removeSomeAnonParcels,
    removeAnon,
    editAnon,
    toggleAnonFarmhouse,
    editAnonProperties,
  } = useAnonSelection(
    map,
    isDrawing !== null && !isDrawing, // if isDrawing is null, none of the two states is selected (anon parcels can be fetch after cluster selection)
    anonLayer,
    anonLayerMetadata,
    focusedParcel,
    parcelCreationErrors,
    onAnonParcelSelect,
  );

  const onPlacePicked = useCallback(
    ({ bounds }) => {
      map.fitBounds(bounds);
    },
    [map],
  );

  return {
    anonSelection,
    anonSelectionIds,
    clearAnonSelection,
    clearDraws,
    drawnParcels,
    editAnon,
    editAnonName,
    editAnonProperties,
    editDraw,
    editDrawName,
    editDrawProperties,
    focusDrawnParcel,
    isDrawing,
    onPlacePicked,
    removeAnon,
    removeDraw,
    removeSomeAnonParcels,
    setDrawing,
    toggleAnonFarmhouse,
    toggleDrawnFarmhouse,
  };
}

function useDrawEditPolygon(map, parcelToEdit) {
  const { units } = useContext(UIContext);
  const parcelToEditPoints = useParcelsCoords(parcelToEdit ? [parcelToEdit] : undefined);
  const [layer, setLayer] = useState(null);
  const [drawnGeoJSON, setDrawnGeoJSON] = useState(null);
  const history = useHistory();
  // fit
  useEffect(() => {
    if (parcelToEditPoints && parcelToEditPoints.length && drawnGeoJSON === null) {
      map.fitBounds(parcelToEditPoints);
    }
  }, [map, parcelToEditPoints, drawnGeoJSON]);
  // initial parcel
  const enrichedParcel = useMemo(() => {
    if (parcelToEdit) {
      // 1 - draw parcel
      const geo =
        typeof parcelToEdit.geometry_area === 'string'
          ? JSON.parse(parcelToEdit.geometry_area)
          : parcelToEdit.geometry_area;
      return {
        ...geo,
        properties: {
          ...geo.properties,
          ...parcelPropertiesDataExtractor(parcelToEdit, history),
        },
      };
    }
  }, [parcelToEdit, history]);

  const drawnItems = useRef(new Leaflet.FeatureGroup());

  // method to build editable initial parcel and add to map
  const buildEditableParcelOnMap = useCallback((enrichedParcel, map) => {
    const geoJSON = enrichedParcel ? [enrichedParcel] : [];

    const layer = Leaflet.geoJSON(geoJSON, {
      snapIgnore: true,
      pmIgnore: false,
      type: 'parcel',
      style: (f) => buildParcelStyle(f.geometry.properties),
    });
    drawnItems.current.addLayer(layer);

    layer.addTo(map);
    layer.pm.enable({
      markerStyle: {
        opacity: 0,
      },
      allowSelfIntersection: true,
      snappable: false,
    });

    return layer;
  }, []);

  useEffect(() => {
    if (!layer && enrichedParcel) {
      setLayer(buildEditableParcelOnMap(enrichedParcel, map));
    }
  }, [enrichedParcel, buildEditableParcelOnMap, map, setLayer, layer]);

  const onEdit = useCallback(
    (e) => {
      if (e && e.layer && e.layer.feature && (e.type === 'pm:edit' || e.type === 'pm:markerdrag')) {
        const poly = e.sourceTarget.toGeoJSON();
        const area = turfArea(poly);
        const toobig = area > 500 * 10000;
        let valid = !toobig;
        if (e.layer.pm.hasSelfIntersection()) {
          e.sourceTarget.setStyle({
            strokeColor: color('red', 'dark'),
            color: color('red'),
            fillColor: color('red'),
          });
          valid = false;
        } else {
          e.sourceTarget.setStyle(buildParcelStyle(e.layer.feature.geometry.properties));
        }
        setDrawnGeoJSON({
          geoJSON: poly,
          area: formatArea(area, units.area, true),
          valid,
          toobig,
        });
      }
    },
    [units],
  );

  useEffect(() => {
    if (!layer) return () => {};
    layer.on('pm:edit', onEdit);
    layer.on('pm:markerdrag', onEdit);
    const copyOfDrawnItems = drawnItems.current;
    return () => {
      layer.off('pm:edit', onEdit);
      layer.off('pm:markerdrag', onEdit);
      if (layer) {
        layer.removeFrom(map);
        layer.removeFrom(copyOfDrawnItems);
        map.removeLayer(layer);
      }
    };
  }, [drawnItems, layer, map, onEdit]);

  const resetDraw = useCallback(() => {
    layer.removeFrom(map);
    layer.removeFrom(drawnItems.current);
    map.removeLayer(layer);
    setLayer(null);
    setDrawnGeoJSON(null);
  }, [layer, map, drawnItems, setLayer, setDrawnGeoJSON]);

  return [drawnGeoJSON, resetDraw];
}

export function useEditParcelDraw({ parcelToEdit }) {
  const { map } = useContext(MapContext);
  const [drawnParcel, resetDraw] = useDrawEditPolygon(map, parcelToEdit);

  return {
    drawnParcel,
    resetDraw,
  };
}
