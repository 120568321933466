import { createSelector } from '@reduxjs/toolkit';
import { createAppSlice } from '.';
import {
  addArticle,
  addEquipmentsToArticle,
  deleteArticle as deleteApiArticle,
  getArticles,
  removeEquipmentsFromArticle,
  updateArticle as updateApiArticle,
} from '../api/api';
import { ARTICLE_STATUS, ArticleStatus } from '../constants/articleConstants';
import { getEquipmentsStoredById } from '../selectors/equipments';
import { objectMap } from '../utils';
import { ItemWithPermissions } from '../utils/permissions';
import { PartialWithRequired } from '../utils/types';
import { ClusterId } from './clusters';
import { Equipment, EquipmentId } from './equipments';
import { alerting, success } from './snacks';

export type ArticleId = number;
export type Article = ItemWithPermissions & {
  id: ArticleId;
  cluster_id: ClusterId;
  name: string;
  reference?: string;
  unit: string;
  typology: string;
  comment?: string;
  status: ArticleStatus;
  equipment_instances?: Equipment[];
  equipment_instance_ids?: EquipmentId[];
};

type State = {
  articlesByClusterId: Record<ClusterId, Article[]>;
};

const initialState: State = {
  articlesByClusterId: {},
};

const articlesSlice = createAppSlice({
  name: 'articles',
  initialState,
  reducers: (create) => ({
    fetchAllArticles: create.asyncThunk(
      async (_, { dispatch }) => {
        const articles = (await alerting(getArticles, { dispatch })) as Article[];
        return articles;
      },
      {
        fulfilled: (state, { payload }) => {
          state.articlesByClusterId = {};
          for (const article of payload) {
            state.articlesByClusterId[article.cluster_id] ??= [];
            state.articlesByClusterId[article.cluster_id]!.push(article);
          }
        },
      },
    ),
    createArticle: create.asyncThunk(
      async (article: Partial<Article>, { dispatch }) => {
        const newArticle = (await alerting(() => addArticle(article), { dispatch })) as Article;
        success('Articles.create_snack', { dispatch });
        return newArticle;
      },
      {
        fulfilled: (state, { payload }) => {
          state.articlesByClusterId[payload.cluster_id] ??= [];
          state.articlesByClusterId[payload.cluster_id]!.push(payload);
        },
      },
    ),
    updateArticle: create.asyncThunk(
      async (
        payload: PartialWithRequired<Article, 'id'> & { previous_equipment_ids?: EquipmentId[] },
        { dispatch },
      ) => {
        let updatedArticle = (await alerting(() => updateApiArticle(payload), { dispatch })) as Article;

        const newEquipments = payload.equipment_instance_ids || [];
        const oldEquipments = payload.previous_equipment_ids || [];
        const equipmentIdsToAdd = newEquipments.filter((id) => !oldEquipments.includes(id));
        const equipmentIdsToRemove = oldEquipments.filter((id) => !newEquipments.includes(id));

        if (equipmentIdsToAdd.length) {
          updatedArticle = (await alerting(() => addEquipmentsToArticle(payload.id, equipmentIdsToAdd), {
            dispatch,
          })) as Article;
        }
        if (equipmentIdsToRemove.length) {
          updatedArticle = (await alerting(() => removeEquipmentsFromArticle(payload.id, equipmentIdsToRemove), {
            dispatch,
          })) as Article;
        }
        success('Articles.update_snack', { dispatch });
        return updatedArticle;
      },
      {
        fulfilled: (state, { payload }) => {
          state.articlesByClusterId[payload.cluster_id] = (state.articlesByClusterId[payload.cluster_id] || []).map(
            (article) => (article.id === payload.id ? payload : article),
          );
        },
      },
    ),
    archiveArticle: create.asyncThunk(
      async (params: { articleId: ArticleId; isCurrentlyArchived: boolean }, { dispatch }) => {
        await dispatch(
          updateArticle({
            id: params.articleId,
            status: params.isCurrentlyArchived ? ARTICLE_STATUS.AVAILABLE : ARTICLE_STATUS.ARCHIVED,
          }),
        );
        return params;
      },
    ),
    deleteArticle: create.asyncThunk(
      async (payload: { articleId: ArticleId; clusterId: ClusterId }, { dispatch }) => {
        await alerting(() => deleteApiArticle(payload.articleId), { dispatch });
        success('Articles.delete_snack', { dispatch });
        return payload;
      },
      {
        fulfilled: (state, { payload }) => {
          state.articlesByClusterId[payload.clusterId] ??= [];
          state.articlesByClusterId[payload.clusterId] = state.articlesByClusterId[payload.clusterId]!.filter(
            (article) => article.id !== payload.articleId,
          );
        },
      },
    ),
  }),
  selectors: {
    selectBareArticlesByClusterId: (state) => state.articlesByClusterId,
  },
});

export const selectArticlesByClusterId = createSelector(
  [articlesSlice.selectors.selectBareArticlesByClusterId, getEquipmentsStoredById],
  (articlesById, equipmentsById: Record<EquipmentId, Equipment>) => {
    return objectMap(articlesById, (clusterArticles) =>
      clusterArticles.map((article) => ({
        ...article,
        equipment_instances: (article.equipment_instance_ids || []).map((id) => equipmentsById[id]).filter(Boolean),
      })),
    );
  },
);

export const { fetchAllArticles, createArticle, updateArticle, archiveArticle, deleteArticle } = articlesSlice.actions;
export default articlesSlice.reducer;
