import { colors } from '@karnott/colors';
import { UNDEFINED_TAG_ID } from '../constants/tagConstants';
import { I18n } from '../i18n';
import { Tag } from '../slices/tags';
import { PartialWithRequired } from './types';

/**
 * @param   partialList List of partial tags, with at least an id
 * @param   allTags     Complete list of created tags available to the user
 *
 * @returns             The given list of partial tags, with unknown tags removed and other tags completed
 */
export function getCompleteTags(partialList: PartialWithRequired<Tag, 'id'>[] | undefined, allTags: Tag[]) {
  return (partialList || []).map((t1) => allTags.find((t2) => t1.id === t2.id)).filter(Boolean);
}

export const undefinedTagItem = {
  id: UNDEFINED_TAG_ID,
  label: I18n.t('TagSelector.undefined'),
  color: colors('black'),
};
